@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsiteSliderLeft {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .title {
    font-size: 44px;
    @include texts.font("bold");
  }

  .slider {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 54px;

    .left {
      width: 40%;
      display: flex;
      flex-direction: column;
      gap: 11px;

      .entry {
        border-radius: 21px;
        padding: 16px 17px 20px 20px;
        cursor: pointer;
        border: 1px solid var(--grey-sur);
        background: linear-gradient(126deg, #000, #ffffff14);

        &.selected, &:hover {
          background: var(--web-white-alpha);
        }

        .titles {
          display: flex;
          flex-direction: row;
          gap: 12px;

          .icon {
            width: 20px;
            height: 20px;
            filter: var(--icon-white);

            &.draft {
              @include icons.ico("black-draft");
            }

            &.section {
              @include icons.ico("black-section");
            }

            &.paragraph {
              @include icons.ico("black-paragraph");
            }

            &.trombone {
              @include icons.ico("black-trombone");
            }

            &.writePaper {
              @include icons.ico("black-shortcuts");
            }

            &.pensil {
              @include icons.ico("black-pensil");
            }

            &.clock {
              @include icons.ico("black-lineal-time");
            }

            &.home {
              @include icons.ico("black-lineal-home");
            }

            &.mail {
              @include icons.ico("black-lineal-mail");
            }

            &.mailAlias {
              @include icons.ico("black-lineal-mail-alias");
            }

            &.domains {
              @include icons.ico("black-lineal-domains");
            }
          }

          .title {
            margin-top: -2px;
            font-size: 21px;
            text-align: left;

            @include texts.font("bold");
          }
        }

        .description {
          margin: 10px 0 0 32px;
          font-size: 16px;
          @include texts.font("semi-bold");
        }
      }
    }

    .content {
      width: 60%;
      background: var(--blue-pastel);
      border-radius: 25px;
      padding: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      .picture {
        display: block;
        max-width: calc(100% - 60px);
        max-height: 400px;
        width: auto;
        height: auto;
        object-fit: contain;
        border-radius: 14px;
      }

      .hidden {
        display: none;
      }
    }
  }

  @media (max-width: 480px) {
    gap: 20px;

    .title {
      font-size: 25px;
      text-align: center;
    }

    .slider {
      flex-direction: column;
      gap: 20px;

      .left {
        width: 100%;
        gap: 8px;

        .entry {
          .titles {
            gap: 8px;

            .icon {
              width: 15px;
              height: 15px;
            }

            .title {
              margin-top: -2px;
              font-size: 15px;
            }
          }

          .description {
            margin: 6px 0 0 22px;
            font-size: 12px;
          }
        }
      }

      .content {
        width: calc(100% - 40px);
        padding: 20px;
      }
    }
  }
}