import {observable} from "@nx-js/observer-util";
import {IPublisherTransmissionModel} from "../../../models/publisher/transmission/IPublisherTransmissionModel";
import {Services} from "../../Services";

export class PublisherTransmissionService {
    public static transmissions: IPublisherTransmissionModel[] = observable([]);

    public static dispose(): void {
        this.transmissions = observable([]);
    }

    public static init(): void {

    }

    /**
     * store
     */
    public static storeAll(transmissions: IPublisherTransmissionModel[]): IPublisherTransmissionModel[] {
        for (let key in transmissions)
            transmissions[key] = this.store(transmissions[key]);

        return Services.storeAll(transmissions);
    }

    public static store(transmission: IPublisherTransmissionModel): IPublisherTransmissionModel {
        return Services.store("id", this.transmissions, transmission);
    }
}