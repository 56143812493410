import {
    IOfferProductTemplateModel
} from "../../../../../../../models/offer/product/templates/IOfferProductTemplateModel";
import {Resources} from "../../../../../../../resources/Resources";
import {OfferProductSolutionType} from "../../../../../../../models/offer/product/solution/OfferProductSolutionType";
import {IOfferContentsTable, IOfferContentsTableEntry, IOfferContentsTableIcon} from "./IOfferContentsTable";

export class OfferContents {

    static table(templates: IOfferProductTemplateModel[]): IOfferContentsTable[] {
        let table: IOfferContentsTable[] = [
            {
                name: Resources.t("words.seoOptimizedBlogArticles"),
                entries: [],
                description: Resources.t("words.generateArticlesDescription"),
                solutionType: OfferProductSolutionType.ARTICLES,
                type: "text"
            },
            {
                name: Resources.t("words.multiLanguageSites"),
                description: Resources.t("words.generateMultiLangContentDescription"),
                entries: [],
                solutionType: OfferProductSolutionType.COMMUNITY,
                type: "textBool",
                boolMin: 1
            },
            {
                name: Resources.t("words.competitorAnalysis"),
                description: Resources.t("words.analyzeCompetitorsDescription"),
                addToContents: true,
                entries: [],
                solutionType: OfferProductSolutionType.SEO_RANKING,
                type: "bool",
                boolMin: 1
            },
            {
                name: Resources.t("words.autopilot"),
                description: Resources.t("words.delegateContentCreationDescription"),
                addToContents: true,
                entries: [],
                solutionType: undefined,
                type: "bool"
            },
            {
                name: Resources.t("words.integrationWithYourSite"),
                description: Resources.t("words.connectYourSiteDescription"),
                addToContents: true,
                entries: [],
                solutionType: undefined,
                type: "bool",
                icons: [IOfferContentsTableIcon.PRESTASHOP, IOfferContentsTableIcon.SHOPIFY, IOfferContentsTableIcon.WORDPRESS, IOfferContentsTableIcon.WIX]
            },
            {
                name: Resources.t("words.onboardingHelp"),
                description: Resources.t("words.contactUsForAssistanceDescription"),
                addToContents: true,
                entries: [],
                solutionType: undefined,
                type: "bool"
            },
        ];

        templates.forEach(product => {
            let bundle = product.bundles[0];

            table.forEach(tableLine => {
                let solution = bundle.solutions.find(value => value.type == tableLine.solutionType);
                let entry: IOfferContentsTableEntry = {templateId: product.id};
                switch (tableLine.type) {
                    case "text":
                        if (solution) {
                            entry.value = `<span>${solution.quantityInitial}</span> ${Resources.t(`words.offerSolution${product.type}${product.productType}${solution.type}`)}`
                        }
                        break;
                    case "textBool":
                        if (solution && solution.quantityInitial > tableLine.boolMin) {
                            entry.value = `<span>${solution.quantityInitial}</span> ${Resources.t(`words.offerSolution${product.type}${product.productType}${solution.type}`)}`
                        }
                        break;
                    case "bool":
                        if (solution) {
                            if (solution.quantityInitial > tableLine.boolMin)
                                entry.value = true;
                        } else
                            entry.value = true;
                        break;
                }

                tableLine.entries.push(entry);
            })
        })

        return table;
    }

    static hiddenTypes(): OfferProductSolutionType[] {
        return [OfferProductSolutionType.SEO_RANKING, OfferProductSolutionType.WRITING_STYLE, OfferProductSolutionType.SEO_CLUSTER];
    }

    static popularTemplates(): string[] {
        return ["406081e6341740e097141d18ed839e0e"];
    }

    static contents(baseContents: string[], template: IOfferProductTemplateModel): string[] {
        let table = this.table([template]);
        table.forEach(tableLine => {
            if (tableLine.addToContents) {
                let value = tableLine.entries.find(value => value.templateId == template.id)?.value;
                if (!value || tableLine.type == "bool") {
                    value = tableLine.name;
                }

                baseContents.push(value);
            }
        });
        return baseContents;
    }
}