export enum ErrorCode {
    NO_RECIPIENT,
    NO_SITE_CHANEl,
    NO_SITE,
    RECIPIENT_FULL,
    MAIL_FORMAT,
    MAIL_RECEIVE,
    MAIL_DOMAIN,
    MAIL,
    EMAIL_EXIST,
    EMAIL_BLACKLIST,
    EMAIL_FULL,
    EMAIL_ALIAS_EXIST,
    EMAIL_ALIAS,
    URL_FORMAT,
    IMAGE_FORMAT,
    DOMAIN_FORMAT,
    DOMAIN_FULL,
    WINDOW_EXPIRED,
    SITE_CHANNEL_NO_FOUND,
    CHANEl_TYPE_NO_FOUND,
    CONTACT_NO_FOUND,
    SITE_CHANNEL_PERMISSION,
    NAME_EXIST,
    PERMISSION,
    NULLITY,
    SIZE_MAX,
    SIZE_MIN,
    VERIFICATION,
    EXCEPTION,
    CACHE,
    SURFACE_CONTROL,
    /*
    {"errorCode":"SURFACE_CONTROL","errorType":"FUNCTIONAL","subErrors":[{"field":"email","rejectedValue":"frrfrffrrfrfrf","message":"doit être une adresse électronique syntaxiquement correcte"},{"field":"password","rejectedValue":"uy","message":"la longueur doit être comprise entre 4 et 2147483647"},{"field":"name","rejectedValue":"  ","message":"ne doit pas être vide"}]}
     */
    TWO_FACTOR_TECHNICAL, //erreur technique
    TWO_FACTOR_REQUIRED,// ->  afficher la pop up
    TWO_FACTOR_TRANSACTION_NO_FOUND, // -> retourner en arrière
    TWO_FACTOR_EXPIRE_CODE, // -> retourner en arrière
    TWO_FACTOR_ATTEMPT, //nb d'essai dépassé -> retourner en arrière
    TWO_FACTOR_BAND_WIDTH_LIMIT, //limit debit dépassé -> demander a l'user de réessayer plus tard
    TWO_FACTOR_CODE_INCORRECT, //rester sur la pop up et affiche 1 msg d'erreur
    TWO_FACTOR_METHOD_UNAUTHORIZED, //technique -> retourner en arrière
    TWO_FACTOR_CREATE_INVALID_CODE,
    CAPTCHA_INCORRECT,
    HOST_INCORRECT,
    HOST_EXIST,
    K8S_DOMAIN_ERROR,
    PARTNER_NO_FOUND,
    INVALID_LANGUAGE,
    ROUTE_EXIST,
    DATE_RANGE,
    PUBLISHER_ATTACHMENT_COMBINATION,
    PUBLISHER_ATTACHMENT_COUNT,
    PUBLISHER_ATTACHMENT_LINK,
    PASSWORD,
    PARTICIPANTS_EMPTY,
    ATTACHMENT_MANDATORY,
    PUBLISHER_POST_EMPTY,
    NO_CONTACT,
    GPT_RETRY_LIMIT,
    PAYMENT_REQUIRED,
    BUNDLES_SIZE,
    BUNDLE_OPTION_SIZE,
    OFFER_ALREADY_EXISTS,
    SITE_COUNT_MAX,
    PROMOTION_CODE_INVALID,
    ACTIVE_OFFER_IS_FAILURE_TO_PAY,
    OFFER_PRODUCTS_EMPTY,
    OFFER_SAME_OFFER_ACTIVE,
    TASK_IN_PROGRESS,
    OFFER_ACTIVE_EXISTS,
    OFFER_CANCELLED_EXPIRED,
    SUSPENDED,
    PHONE_FORMAT,
    PARTNER_CONFING_INVALID,
    SHOPIFY_PERMISSIONS,
    COMMUNITY_AUTOPILOT_DAYS_MAX,
    TAX_COUNTRY,
    TAX_TYPE,
    TAX_VALUE_INVALID,
    LOCATION_INVALID,
    TOKEN_INVALID,
    PAYMENT_PROVIDER_CREATE_SUBSCRIPTION,
    OFFER_QUOTE_EXISTS,
    CREATE_ARTICLE_IA_FORM_EMPTY,
    MAX_LIMIT
}
