export class WebSocketCommunityEventName {

    public static COMMUNITY_JOIN = "community/join";
    public static COMMUNITY_LEAVE = "community/leave";
    public static COMMUNITY_UPDATE = "community/update";
    public static COMMUNITY_WRITING_STYLE_UPDATE = "community/writingStyle/update";
    public static COMMUNITY_SEO_CLUSTER_KEYWORDS_CREATE = "community/seo/cluster/keywords/create";

    public static COMMUNITY_ARTICLE_JOIN = "community/article/join";
    public static COMMUNITY_ARTICLE_LEAVE = "community/article/leave";

    public static COMMUNITY_CONTENT_SORT = "community/content/sort";

    public static COMMUNITY_CATEGORY_CREATE = "community/category/create";
    public static COMMUNITY_CATEGORY_DELETE = "community/category/delete";
    public static COMMUNITY_CATEGORY_UPDATE = "community/category/update";

    public static COMMUNITY_ARTICLE_CREATE = "community/article/create";
    public static COMMUNITY_ARTICLE_DELETE = "community/article/delete";
    public static COMMUNITY_ARTICLE_UPDATE = "community/article/update";
    public static COMMUNITY_ARTICLE_PROPOSAL_CREATE = "community/article/proposal/create";
    public static COMMUNITY_ARTICLE_SECTION_CREATE = "community/article/section/create";
    public static COMMUNITY_ARTICLE_GENERATE = "community/article/generate";
    public static COMMUNITY_ARTICLE_GENERATE_START = "community/article/generate/start";

    public static GPT_SEGMENT = "gpt/segment";

}