/**
 * Vérifie si le type est une gif
 */
export function fileIsGif(type: string): boolean {
    if (type != undefined) {
        type = type.toLowerCase();
    }
    switch (type) {
        case "image/gif":
        case "gif":
            return true;
    }
    return false;
}