@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsiteColumnsSteps {
  display: flex;
  flex-direction: column;
  gap: 37px;

  .title {
    margin: 0 20%;
    text-align: center;
    background: var(--grad-blue-button);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 44px;

    @include texts.font("bold");
  }

  .columns {
    display: flex;
    flex-direction: row;
    position: relative;

    .column {
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .details {
        width: calc(100% - 134px);
        height: calc(100% - 73px);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background: var(--grey-sur);
        border-radius: 18px;
        padding: 33px 40px 40px 40px;
        margin: 0 27px;
        gap: 20px;

        .icon {
          width: 90px;
          height: 90px;
        }

        .computer {
          @include icons.ico("illus-computer");
        }

        .pensil {
          @include icons.ico("illus-pensil");
        }

        .collaboration {
          @include icons.ico("3d-collaboration");
        }

        .letter {
          @include icons.ico("illus-letter");
        }

        .name {
          font-size: 28px;

          @include texts.font("bold");
        }

        .description {
          font-size: 14px;

          @include texts.font("medium");

          strong {
            font-weight: unset;
          }
        }
      }

      .banner {
        margin-top: 5px;
        width: calc(100% + 1px);
        position: relative;
        z-index: 1
      }

      .number {
        margin-top: -80px;
        position: relative;
        background: var(--white);
        height: 100px;
        width: 100px;
        padding: 12px 0;
        border-radius: 50%;
        z-index: 2;
        text-align: center;
        box-shadow: 0 0 32.85px var(--black-alpha-middle);

        .caracter {
          font-size: 40px;
          margin-top: 15px;

          @include texts.font("bold");
        }
      }
    }
  }

  @media (max-width: 750px) {
    gap: 20px;

    .title {
      margin: 0;
      font-size: 25px;
    }

    .columns {
      flex-direction: column;
      gap: 20px;

      .column {
        width: 100%;

        .details {
          margin: 0;
          width: calc(100% - 80px);
        }

        .banner {
          display: none;
        }

        .number {
          margin-top: -30px;
          height: 50px;
          width: 50px;
          padding: 0;

          .caracter {
            font-size: 25px;
            margin-top: 11px;
          }
        }
      }
    }
  }
}