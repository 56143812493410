import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./contact-ban-entry.scss";
import {IContactBanModel} from "../../../../../../../models/contact/ban/IContactBanModel";
import {AvatarComponent} from "../../../avatar/AvatarComponent";
import {
    PromptConfirmationComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/prompt/confirmation/PromptConfirmationComponent";
import {ContactBanService} from "../../../../../../../services/contact/ban/ContactBanService";
import {Resources} from "../../../../../../../resources/Resources";
import {
    dateFormat
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/DateFormat";
import {
    dateTinyString
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/DateTinyString";

export class ContactBanEntryComponent extends Component {
    public ban: IContactBanModel;
    public deleteButton: Component;

    constructor(ban: IContactBanModel) {
        super();
        this.ban = ban;
        //language=HTML
        this.template = `
            <div class="${s.componentContactBanEntry}">
                <div class="${s.head}">
                    <div class="${s.delete}">
                        <div class="${s.icon}"></div>
                    </div>
                    <div class="${s.avatarContainer}">
                        ${this.draw(new AvatarComponent(ban.account.avatar).setActive(ban.account))}
                    </div>
                    <div class="${s.contactName}">
                        ${this.reactive(() => this.ban.account.name)}
                    </div>
                    <div style="${Date.now() > ban.endTime ? `` : `display:none;`}" class="${s.badge}">
                        Expiré
                    </div>
                </div>
                <div class="${s.comment}">
                    ${this.reactive(() => this.ban.comment)}
                </div>
                <div class="${s.dates}">
                    <div class="${s.date}">
                        Début le ${dateFormat(new Date(ban.startTime), true)} pour
                        <u>${dateTinyString((ban.endTime - ban.startTime) / 1000)}</u>
                    </div>
                    <div class="${s.date}">
                        Fin le ${dateFormat(new Date(ban.endTime), true)}
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        this.deleteButton = this.el(s.delete);
        this.deleteButton.onClick(() => {
            let confirmation = new PromptConfirmationComponent(Resources.t('words.deleteSanctionConfirmationText'));
            confirmation.onContinue = async () => {
                confirmation.setLoading();
                let request = await ContactBanService.delete(this.ban.id, this);
                confirmation.close();
                if (request) {
                    this.onDelete();
                }

            }
            this.render(confirmation);
        });
        super.commit();
    }

    onDelete() {

    }
}