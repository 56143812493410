import {
    VisualClickTooltipWhiteComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {ContactBansComponent} from "../ContactBansComponent";
import {IContactModel} from "../../../../../../../models/contact/IContactModel";

export class ContactBansTooltipComponent extends VisualClickTooltipWhiteComponent {

    public banComponent: ContactBansComponent;

    constructor(component: Component, contact: IContactModel) {
        super(component, 323, 250);
        this.mobileStyle(true);
        this.banComponent = new ContactBansComponent(contact);
        //language=HTML
        this.template = this.draw(this.banComponent);
    }

    commit() {
        this.banComponent.requireDispose = () => this.dispose();
        this.setStyle(`height:323px;overflow:hidden;`);
        this.swapable(() => this.dispose());
        super.commit();
    }
}