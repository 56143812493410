import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {IContactBanModel} from "../../../models/contact/ban/IContactBanModel";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {AccountService} from "../../account/AccountService";
import {ProductType} from "../../../models/product/ProductType";

export class ContactBanService {
    public static bans: IContactBanModel[] = observable([]);

    public static dispose(): void {
        this.bans = observable([]);
    }

    public static init(): void {

    }

    public static async findByContact(contactId: string, component?: INetworkComponent): Promise<IContactBanModel[]> {
        let request = await Network.get(ProductType.PANEL, `/contact/bans/${contactId}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    public static async create(contactId: string, comment: string, endTime: number, component?: INetworkComponent): Promise<IContactBanModel> {
        let request = await Network.post(ProductType.PANEL, "/contact/bans",
            {
                contactId: contactId,
                comment: comment,
                endTime: endTime
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async delete(id: string, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.delete(ProductType.PANEL, `/contact/bans/${id}`, component);
        if (request.status == HttpStatus.OK) {
            return true;
        }

        return undefined;
    }


    /**
     * store
     */

    public static storeAll(bans: IContactBanModel[]): IContactBanModel[] {
        for (let key in bans)
            bans[key] = this.store(bans[key]);

        return Services.storeAll(bans);
    }

    public static store(ban: IContactBanModel): IContactBanModel {
        ban.account = AccountService.store(ban.account);
        ban = Services.store("id", this.bans, ban);
        return ban;
    }
}