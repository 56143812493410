@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentDatePickerTooltip {
  div[entryDate] {
    height: 26px;
  }

  .buttons {
    @include positioning.clear;

    .button {
      margin-top: 5px;
      padding: 6px 15px;
      border-radius: 10px;
      font-size: 15px;
      float: right;
      background: var(--grey-sur);
      cursor: pointer;
      margin-left: 10px;

      @include positioning.flex-globally;
      @include texts.font("semi-bold");

      &.validButton, &.cancelButton {
        visibility: visible;
      }

      &:hover {
        background: var(--grey-sur-hover);
      }
    }
  }

}