@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/alt";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentChannelAvatar {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  overflow: hidden;

  @include positioning.flex-globally;

  .channelIcon {
    height: 67%;
    width: 67%;
    position: relative;
  }
}