/**
 * Vérifie si le type est une image
 */
export function fileIsImage(type: string): boolean {
    if (type != undefined) {
        type = type.toLowerCase();
    }
    switch (type) {
        case "image/png":
        case "image/jpeg":
        case "image/bmp":
        case "image/webp":
        case "image/svg+xml":
        case "png":
        case "jpeg":
        case "jpg":
        case "ico":
        case "svg":
        case "bmp":
        case "webp":
            return true;
    }
    return false;
}