import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./country-icon.scss";
import {Network} from "../../../../../network/Network";

export class CountryIconComponent extends Component {
    constructor(code: string) {
        super();

        if (code) {
            code = code.toUpperCase();

            //language=HTML
            this.template = `
                <div style="background: url('${Network.vendor + "countries/flags/" + code + ".svg"}'); background-size: contain;"
                     class="${s.componentCountryIcon}">
                </div>
            `;
        } else {
            //language=HTML
            this.template = `
                <div class="${s.componentCountryIcon}">
                    <div class="${s.empty}"></div>
                </div>
            `;
        }
    }
}