import {IAvatarModel} from "../../../../../../models/avatar/IAvatarModel";
import * as s from "../avatar.scss";
import {FileService} from "../../../../../../services/file/FileService";
import {AvatarType} from "../../../../../../models/avatar/AvatarType";

export class AvatarTemplate {
    public static staticTemplate(avatar: IAvatarModel) {
        //language=HTML
        return `
            <div ${(avatar.color != undefined) ? `style="background-image:${avatar.color.color}"` : ``}
                 class="${s.componentAvatar}">
                ${this.imageTemplate(avatar)}
            </div>
        `;
    }

    public static imageTemplate(avatar: IAvatarModel): string {
        if (avatar.url != undefined) {
            return this.urlTemplate(avatar);
        }

        //language=HTML
        return `
            <div class="${s.imageContainer}">
                ${avatar.file != undefined 
                        ? `<img class="${s.avatar}" src="${avatar.file.link ?? FileService.link(avatar.file)}" alt="avatar"/>` 
                        : this.iconDivTemplate(avatar)}
            </div>
        `;
    }

    public static iconDivTemplate(avatar: IAvatarModel): string {
        return `<div class="${s.icon} ${this.iconTemplate(avatar)}"></div>`;
    }

    public static urlTemplate(avatar: IAvatarModel): string {
        //language=HTML
        return `
            <div class="${s.imageContainer}">
                <img width="100%" height="unset" class="${s.avatar}" src="${avatar.url}" alt="avatar"/>
            </div>
        `;
    }

    public static iconTemplate(avatar: IAvatarModel): string {
        switch (avatar.type) {
            case AvatarType.ENTITY:
                return s.entity;
            case AvatarType.ROLE:
                return s.role;
            case AvatarType.SITE:
                return s.site;
            case AvatarType.SITE_CHANNEL:
                return s.siteChannel;
            case AvatarType.BOT:
                return s.bot;
            case AvatarType.MAIL:
                return s.group;
            case AvatarType.HELP_CENTER:
                return s.helpCenter;
        }

        return s.entity;
    }
}