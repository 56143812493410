import {Network} from "../../../network/Network";
import {AccountSessionService} from "../../../services/account/session/AccountSessionService";
import {SiteService} from "../../../services/site/SiteService";
import {SiteChannelService} from "../../../services/site/channel/SiteChannelService";
import {AccountService} from "../../../services/account/AccountService";
import {InboxConversationService} from "../../../services/inbox/conversation/InboxConversationService";
import {
    InboxConversationParticipantService
} from "../../../services/inbox/conversation/participant/InboxConversationParticipantService";
import {ChannelService} from "../../../services/channel/ChannelService";
import {CategoryService} from "../../../services/category/CategoryService";
import {FilterService} from "../../../services/filter/FilterService";
import {ContactService} from "../../../services/contact/ContactService";
import {
    InboxConversationMessageService
} from "../../../services/inbox/conversation/message/InboxConversationMessageService";
import {
    InboxConversationMessageAttachmentService
} from "../../../services/inbox/conversation/message/attachment/InboxConversationMessageAttachmentService";
import {AvatarService} from "../../../services/avatar/AvatarService";
import {AvatarColorService} from "../../../services/avatar/color/AvatarColorService";
import {FileService} from "../../../services/file/FileService";
import {PreviewService} from "../../../services/preview/PreviewService";
import {EntityService} from "../../../services/entity/EntityService";
import {InvitationService} from "../../../services/invitation/InvitationService";
import {
    SedestralInterface
} from "../../../sedestral-interface-modules/sedestral-interface-component/interface/SedestralInterface";
import {
    EmptyNetworkComponent
} from "../../../sedestral-interface-modules/sedestral-interface-component-global/empty/network/EmptyNetworkComponent";
import {NotificationService} from "../../../services/notification/NotificationService";
import {WebPushService} from "../../../services/webpush/WebPushService";
import {NoteService} from "../../../services/note/NoteService";
import {ContactSessionService} from "../../../services/contact/session/ContactSessionService";
import {PreferencesService} from "../../../services/preferences/PreferencesService";
import {
    InboxConversationStatisticsService
} from "../../../services/inbox/conversation/statistics/InboxConversationStatisticsService";
import {StatisticsService} from "../../../services/statistics/StatisticsService";
import {
    InboxConversationMessageStatisticsService
} from "../../../services/inbox/conversation/message/statistics/InboxConversationMessageStatisticsService";
import {AccountPreferencesService} from "../../../services/account/preferences/AccountPreferencesService";
import {InboxShortcutService} from "../../../services/inbox/shortcut/InboxShortcutService";
import {
    InboxShortcutAttachmentService
} from "../../../services/inbox/shortcut/attachment/InboxShortcutAttachmentService";
import {RoleService} from "../../../services/roles/RoleService";
import {RoleAccountService} from "../../../services/roles/account/RoleAccountService";
import {PermissionsService} from "../../../services/permissions/PermissionsService";
import {firebaseConfig} from "../../../config";
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {IS_NATIVE} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/SedestralUtils";
import {
    NotificationCountAccountService
} from "../../../services/notification/count/account/NotificationCountAccountService";
import {ContactChannelService} from "../../../services/contact/channel/ContactChannelService";
import {ContactBanService} from "../../../services/contact/ban/ContactBanService";
import {SiteColumnsContactService} from "../../../services/site/columns/contact/SiteColumnsContactService";
import {CollaborationEditorService} from "../../../services/collaboration/editor/CollaborationEditorService";
import {AccountPasswordLostService} from "../../../services/account/password/lost/AccountPasswordLostService";
import {AccountComposerService} from "../../../services/account/composers/AccountComposerService";
import {SedestralSsr} from "../../../sedestral-interface-modules/sedestral-interface-component/ssr/SedestralSsr";
import {PublisherService} from "../../../services/publisher/PublisherService";
import {InboxService} from "../../../services/inbox/InboxService";
import {GPTService} from "../../../services/gpt/GPTService";
import {ProductType} from "../../../models/product/ProductType";
import {CommunityService} from "../../../services/community/CommunityService";
import {OfferBlockedModalComponent} from "../components/components/offer/blocked/modal/OfferBlockedModalComponent";
import {AccountLogService} from "../../../services/account/log/AccountLogService";
import {TrackingLogService} from "../../../services/tracking/TrackingLogService";

export class PanelNetwork {

    static pushInit: boolean;

    static async init() {
        /**
         * override
         */
        Network.onToken = async (): Promise<boolean> => {
            return SedestralSsr.hasSsr() ? true : await AccountSessionService.token();
        }

        Network.onLogin = async (): Promise<boolean> => {
            Network.logged = true;

            let connected = await Network.startWebSocket(ProductType.PANEL);
            if (connected) {
                await AccountSessionService.onLogin();
                this.initServices();
                this.initEvents();
                this.initPush();
                return true;
            }

            return false;
        }

        Network.onLogout = async (): Promise<boolean> => {
            Network.logged = false;
            AccountSessionService.onLogout();
            this.disposeServices();
            await Network.disconnect(ProductType.PANEL, true);
            return true;
        }

        Network.onError = (message) => {
            if (Network.router.static?.components.notifications) {
                Network.router.static?.components.notifications.notify(message, undefined, 8000);
            }
        }

        Network.onPaymentRequired = (currentQuantity, limitQuantity, solutionType, productType) => {
            let modal = new OfferBlockedModalComponent(currentQuantity, limitQuantity, solutionType, productType);
            modal.screenRender();
        }

        /**
         * on error
         */

        Network.lostFunctions = [];
        Network.onNetworkLost(() => {
            SedestralInterface.body.render(new EmptyNetworkComponent());
        });

        Network.lostSolvedFunctions = [];
        Network.onNetworkLostSolved(() => window.location.reload());
    }

    static initServices() {
        EntityService.init();
        SiteService.init();
        SiteChannelService.init();

        RoleService.init();
        RoleAccountService.init();

        AccountPasswordLostService.init();
        AccountPreferencesService.init();
        AccountComposerService.init();
        AccountSessionService.init();
        AccountService.init();
        AccountLogService.init();
        TrackingLogService.init();

        CollaborationEditorService.init();

        InboxConversationService.init();
        InboxConversationStatisticsService.init();
        InboxConversationMessageStatisticsService.init();
        InboxConversationParticipantService.init();

        ChannelService.init();

        InvitationService.init();

        CategoryService.init();

        InboxShortcutService.init();
        InboxShortcutAttachmentService.init();

        FilterService.init();

        ContactService.init();
        ContactSessionService.init();
        ContactChannelService.init();
        ContactBanService.init();
        SiteColumnsContactService.init();

        InboxService.init();
        InboxConversationMessageService.init();
        InboxConversationMessageAttachmentService.init();

        AvatarService.init();
        AvatarColorService.init();

        FileService.init();

        PreviewService.init();


        NotificationCountAccountService.init();
        NotificationService.init();

        WebPushService.init();

        NoteService.init();

        PreferencesService.init();

        StatisticsService.init();

        PermissionsService.init();

        PublisherService.init();

        GPTService.init();

        CommunityService.init();
    }

    static disposeServices() {
        SiteService.dispose();
        SiteChannelService.dispose();

        AccountPasswordLostService.dispose();
        AccountPreferencesService.dispose();
        AccountComposerService.dispose();
        AccountSessionService.dispose();
        AccountService.dispose();
        AccountLogService.dispose();
        TrackingLogService.dispose();

        InboxConversationService.dispose();
        InboxConversationStatisticsService.dispose();
        InboxConversationMessageStatisticsService.dispose();
        InboxConversationParticipantService.dispose();

        ChannelService.dispose();

        InvitationService.dispose();

        CategoryService.dispose();

        InboxShortcutService.dispose();
        InboxShortcutAttachmentService.dispose();

        FilterService.dispose();

        ContactService.dispose();
        ContactSessionService.dispose();
        ContactChannelService.dispose();
        ContactBanService.dispose();

        InboxConversationMessageService.dispose();
        InboxConversationMessageAttachmentService.dispose();

        AvatarService.dispose();
        AvatarColorService.dispose();

        FileService.dispose();

        PreviewService.dispose();

        EntityService.dispose();

        NotificationCountAccountService.dispose();
        NotificationService.dispose();

        WebPushService.dispose();

        NoteService.dispose();

        PreferencesService.dispose();

        StatisticsService.dispose();

        PermissionsService.dispose();

        PublisherService.dispose();

        GPTService.dispose();

        CommunityService.dispose();
    }

    static initPush() {
        if (!IS_NATIVE) {
            try {
                if (!this.pushInit) {
                    let firebaseApp = initializeApp(firebaseConfig.config);
                    let messaging = getMessaging(firebaseApp);
                    getToken(messaging, {vapidKey: firebaseConfig.vapidKey}).then((currentToken) => {
                        if (currentToken) {
                            if (currentToken != EntityService.activeWebPushToken) {
                                EntityService.activeWebPushToken = currentToken;
                                WebPushService.create(currentToken, false);
                            }

                        } else {
                            //TODO notify error
                        }
                    }).catch((err) => {
                        //TODO notify error
                    });
                    onMessage(messaging, (payload) => {
                        console.log('Message received. ', payload);
                        // ...
                    });
                    this.pushInit = true;
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    static initEvents() {
        SiteService.onReload = () => {
            location.reload();
        }
    }
}