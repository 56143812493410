import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-footer.scss";
import {
    CapsuleProductsComponent
} from "../../../../panel/components/components/capsule/products/CapsuleProductsComponent";
import {Network} from "../../../../../network/Network";
import {Resources} from "../../../../../resources/Resources";
import {
    LanguageWebsitePickerComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/language/website/LanguageWebsitePickerComponent";

export class WebsiteFooterComponent extends Component {

    public languagePicker: LanguageWebsitePickerComponent;

    constructor() {
        super();
        this.languagePicker = new LanguageWebsitePickerComponent(Resources.language);

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteFooter}">
                <div class="${s.brand}">
                    <div style="z-index: 4;" itemscope itemtype="https://schema.org/Organization">
                    <a itemprop="url" href="${process.env.APP_URL + "/" + Resources.language + "/home"}">
                        <meta itemprop="brand" content="Sedestral">
                        <div class="${s.caseLogo}">
                            <div itemprop="logo" class="${s.logoContainer}">
                                <img itemprop="logo" class="${s.logo}"
                                     src="${Network.vendor}images/logo-sedestral.svg"
                                     alt="${Resources.t("words.logo")}">
                            </div>
                            <h2 itemprop="name" class="${s.title}">
                                Sedestral
                            </h2>
                        </div>
                    </a>
                </div>
                </div>
                <div itemscope itemtype="https://schema.org/SiteNavigationElement" class="${s.columns}">
                    <div class="${s.column}">
                        <nav class="${s.title}">
                            ${Resources.t("words.tools")}
                            <ul class="${s.entries}">
                                <li itemprop="name" class="${s.entry}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('ai')}">
                                        ${Resources.t("words.sedestralAI")}
                                    </a>
                                </li>
                                <li itemprop="name" class="${s.entry}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('analysis')}">
                                        ${Resources.t("words.seoAnalytics")}
                                    </a> 
                                </li>
                                <li itemprop="name" class="${s.entry}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('autopilot')}">
                                        ${Resources.t("words.autopilot")}
                                    </a>
                                </li>
                                <li itemprop="name" class="${s.entry}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('style')}">
                                        ${Resources.t("words.styleCloningTitle")}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                     <div class="${s.column}">
                        <nav class="${s.title}">
                            ${Resources.t("words.integrations")}
                            <ul class="${s.entries}">
                                <li itemprop="name" class="${s.entry}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('prestashop')}">
                                        ${Resources.t("words.prestaShop")}
                                    </a>
                                </li>
                                <li itemprop="name" class="${s.entry}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('shopify')}">
                                        ${Resources.t("words.shopify")}
                                    </a>
                                </li>
                                <li itemprop="name" class="${s.entry}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('wordpress')}">
                                        ${Resources.t("words.wordpress")}
                                    </a>
                                </li>
                                <li itemprop="name" class="${s.entry}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('wix')}">
                                        ${Resources.t("words.wix")}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="${s.column}">
                        <nav class="${s.title}">
                            ${Resources.t("words.resources")}
                            <ul class="${s.entries}">
                                <li itemprop="name" class="${s.entry}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('pricing')}">
                                        ${Resources.t("words.pricing")}
                                    </a>
                                </li>
                                <li itemprop="name" class="${s.entry}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('schedule')}">
                                        ${Resources.t("words.scheduleTitle")}
                                    </a>
                                </li>
                                <li itemprop="name" class="${s.entry}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('cgu')}">
                                        ${Resources.t("words.conditionsOfUse")}
                                    </a>
                                </li>
                                <li itemprop="name" class="${s.entry}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('cgv')}">
                                        ${Resources.t("words.generalSalesConditions")}
                                    </a>
                                </li>
                                <li itemprop="name" class="${s.entry}">
                                    <a itemprop="url" href="${Network.router.pages.getUrlWithPath('policy')}">
                                        ${Resources.t("words.privacyPolicyName")}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="${s.column}">
                        <nav class="${s.title}">
                            ${Resources.t("words.account")}
                            <ul class="${s.entries}">
                                <li itemprop="name" class="${s.entry}">
                                    <a itemprop="url" href="${Network.router.pages.getPanelUrl('login')}" target="_blank">
                                        ${Resources.t("words.myAccount")}
                                    </a>
                                </li>
                                <li itemprop="name" class="${s.entry}">
                                    <a itemprop="url" href="${Network.router.pages.getPanelUrl("signup")}" target="_blank">
                                        ${Resources.t("words.signUp")}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <nav class="${s.title}">
                            ${Resources.t("words.language")}
                            <div class="${s.language}">
                                ${this.draw(this.languagePicker)}
                            </div>
                        </nav>
                    </div>
                </div>
                <p class="${s.copyright}">
                    Copyright © ${new Date().getFullYear()} Sedestral. ${Resources.t("words.allRightsReserved")}
                </p>
            </div>
        `;
    }

    commit() {
        this.languagePicker.onSelect = language => Network.router.pages.setLanguage(language);
        super.commit();
    }
}