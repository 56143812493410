@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";

.globalCheckboxSliding {
  width: calc(100% + 8px);
  height: 100%;
  position: relative;

  .checkboxEntry {
    height: 100%;
    border-radius: 6px;
    width: calc(50% - 2px);
    cursor: pointer;
    top: 0;
    position: relative;
    float: left;

    @include positioning.flex-globally;

    &.selector {
      border-color: transparent !important;
      position: absolute !important;
      left: 0;
      background: var(--grey-sur);
    }

    .icon {
      height: 16px;
      width: 16px;
    }
  }
}