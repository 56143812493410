import * as s from "../website-content.scss";
import {Resources} from "../../../../../../../resources/Resources";
import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {NetworkVendor} from "../../../../../../../network/NetworkVendor";
import {WebsiteBlackPresentationComponent} from "../../../black/presentation/WebsiteBlackPresentationComponent";
import {WebsiteBlackStepsComponent} from "../../../black/steps/WebsiteBlackStepsComponent";
import {WebsiteBlackTextComponent} from "../../../black/text/WebsiteBlackTextComponent";
import {WebsiteBlackFlowComponent} from "../../../black/flow/WebsiteBlackFlowComponent";
import {WebsiteBlackPlatformComponent} from "../../../black/platform/WebsiteBlackPlatformComponent";

export class WebsiteContentHomeComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackPresentationComponent({
                            startText: Resources.t("words.beginFree"),
                            title: Resources.t("words.mostPerformantContent"),
                            description: Resources.t("words.sedestralSystemDescription"),
                            people: true,
                            center: true
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackStepsComponent({
                            title: Resources.t("words.simplifiedSeoSteps"),
                            icon: "seo",
                            contents: [
                                {
                                    name: Resources.t("words.analysisAndResearch"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-a.mp4"),
                                    duration: 5700
                                },
                                {
                                    name: Resources.t("words.writeOrGenerate"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-b.mp4"),
                                    duration: 22000
                                },
                                {
                                    name: Resources.t("words.verifyAndPublish"),
                                    type: "img",
                                    path: NetworkVendor.image("blog-c.webp"),
                                    duration: 6000
                                },
                            ]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackTextComponent({
                            text: Resources.t("words.dailyOptimizationUsers"),
                            size: "sizeNormal",
                            shadow: "colorBlue"
                        }))}
                    </div>
                </div>
                <div class="${s.white}">
                    <div class="${s.content}">
                        <div class="${s.container}">
                            ${this.draw(new WebsiteBlackFlowComponent({
                                title: Resources.t("words.createAndOptimize"),
                                bigTitle: Resources.t("words.createEngagingContent"),
                                image: NetworkVendor.image("blog-c.webp"),
                                texts: {
                                    canCollapse: false,
                                    entries: [
                                        {
                                            title: Resources.t("words.autoOptimizeExistingContent"),
                                            description: Resources.t("words.autoOptimizeDescription"),
                                            icon: "aiStars",
                                            iconColor: "colorPurple"
                                        },
                                        {
                                            title: Resources.t("words.createEngagingSeoContent"),
                                            description: Resources.t("words.createEngagingDescription"),
                                            icon: "aiWrite",
                                            iconColor: "colorPurple"
                                        }
                                    ]
                                }
                            }))}
                        </div>
                    </div>
                </div>
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackPlatformComponent({
                            title: Resources.t("words.ourPlatform"),
                            description: Resources.t("words.ourPlatformDescription"),
                            grid: {
                                lines: [{
                                    entries: [{
                                        title: Resources.t("words.seoScore"),
                                        description: Resources.t("words.seoScoreDescription"),
                                        type: "icon",
                                        icon: "optimize",
                                        ai: true
                                    }, {
                                        title: Resources.t("words.sedestralAi"),
                                        description: Resources.t("words.sedestralAiDescription"),
                                        type: "image",
                                        image: NetworkVendor.image("blog-d.webp"),
                                        ai: true,
                                        size: "sizeLarge",
                                        color: "colorBlue"
                                    }]
                                }, {
                                    entries: [{
                                        title: Resources.t("words.workInAnyLanguage"),
                                        description: Resources.t("words.workInAnyLanguageDescription"),
                                        type: "icon",
                                        icon: "languages",
                                        size: "sizeMid",
                                        color: "colorYellow",
                                        shadowPosition: "shadowBottomCenter"
                                    }, {
                                        title: Resources.t("words.deepCompetitorAnalysis"),
                                        description: Resources.t("words.deepCompetitorDescription"),
                                        type: "icon",
                                        icon: "chartPurple",
                                        size: "sizeMid",
                                        color: "colorPurple",
                                        shadowPosition: "shadowLeftCenter"
                                    }]
                                }, {
                                    entries: [{
                                        title: Resources.t("words.automaticPiloting"),
                                        description: Resources.t("words.automaticPilotingDescription"),
                                        type: "icon",
                                        icon: "autopilot",
                                        size: "sizeMidColumn",
                                        color: "colorBlue",
                                        shadowPosition: "shadowMid"
                                    }, {
                                        title: Resources.t("words.nativeIntegration"),
                                        description: Resources.t("words.nativeIntegrationDescription"),
                                        type: "icon",
                                        icon: "funnel",
                                        size: "sizeMidColumn",
                                        color: "colorPurple",
                                        shadowPosition: "shadowMid"
                                    }]
                                }]
                            }
                        }))}
                    </div>
                </div>
            </div>
        `;
    }
}
