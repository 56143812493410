/**
 * retourne le lundi et le dimanche de la semaine de la date
 */
export function dateGetWeek(date: Date) {
    let mondayDate = new Date(date);
    let mondayDiff = mondayDate.getDate() - mondayDate.getDay() + (mondayDate.getDay() == 0 ? -6 : 1); // adjust when day is sunday

    let monday = new Date(mondayDate.setDate(mondayDiff));
    monday.setHours(0);
    monday.setMinutes(0);
    monday.setSeconds(0);
    monday.setMilliseconds(0);

    let sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);
    sunday.setHours(23);
    sunday.setMinutes(59);
    sunday.setSeconds(59);
    sunday.setMilliseconds(999);

    return {monday: monday, sunday: sunday};
}