import {observable} from "@nx-js/observer-util";
import {ICollaborationEditorModel} from "../../../models/collaboration/editor/ICollaborationEditorModel";
import {Network} from "../../../network/Network";
import {WebSocketPanelEventName} from "../../../network/socket/names/WebSocketPanelEventName";
import {Services} from "../../Services";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {AccountService} from "../../account/AccountService";
import {ICollaborationEditorViewerModel} from "../../../models/collaboration/editor/ICollaborationEditorViewerModel";
import {ICollaborationEditorJoinModel} from "../../../models/collaboration/editor/ICollaborationEditorJoinModel";
import {PreviewService} from "../../preview/PreviewService";
import {FileService} from "../../file/FileService";
import {
    ICollaborationEditorAttachmentsOutgoingModel
} from "../../../models/collaboration/editor/attachments/outgoing/ICollaborationEditorAttachmentsOutgoingModel";
import {
    ICollaborationEditorAttachmentsModel
} from "../../../models/collaboration/editor/attachments/ICollaborationEditorAttachmentsModel";
import {
    ICollaborationEditorMetadataModel
} from "../../../models/collaboration/editor/ICollaborationEditorMetadataModel";
import * as Y from "yjs";
import {WebsocketProvider} from "sedestral-y-websocket";
import {config} from "../../../config";
import {ICollaborationJoin} from "../../../models/collaboration/ICollaborationJoin";
import {ProductType} from "../../../models/product/ProductType";
import {ProductName} from "../../../models/product/ProductName";

export class CollaborationEditorService {
    public static editors: ICollaborationEditorModel[] = observable([]);
    public static viewers: ICollaborationEditorViewerModel[] = observable([]);
    public static attachmentsChangesEvents: {
        editorId: string,
        func: ((viewerId: string, attachments: ICollaborationEditorAttachmentsModel) => void)
    }[] = [];
    public static viewerJoinEvents: {
        editorId: string,
        func: ((viewer: ICollaborationEditorViewerModel) => void)
    }[] = [];
    public static viewerLeaveEvents: {
        editorId: string,
        func: ((viewer: ICollaborationEditorViewerModel) => void)
    }[] = [];
    public static metadataEvents: {
        editorId: string,
        func: ((data: ICollaborationEditorMetadataModel) => void)
    }[] = [];
    public static products: ProductType[] = [];

    public static dispose(): void {
        this.editors = observable([]);
    }

    public static init(): void {
        Services.beforeInit(this);

        this.products = [ProductType.PANEL, ProductType.COMMUNITY];
        this.products.forEach(productType => {
            Services.on(this, productType, WebSocketPanelEventName.COLLABORATION_EDITOR_SAVE_METADATA, (data) => {
                if (data != undefined) {
                    this.metadataEvents.forEach(value => {
                        if (value.editorId == data.editorId) {
                            value.func(data);
                        }
                    });
                }
            });

            Services.on(this, productType, WebSocketPanelEventName.COLLABORATION_EDITOR_SAVE_ATTACHMENTS, (data) => {
                let attachments = this.storeAttachments(data);
                let editor = this.editors.filter(value => value.id == data.editorId)[0];
                if (editor) {
                    editor.attachments = attachments;
                }

                this.attachmentsChangesEvents.forEach(value => {
                    if (value.editorId == data.editorId) {
                        value.func(data.viewerId, attachments);
                    }
                });
            });

            Services.on(this, productType, WebSocketPanelEventName.COLLABORATION_EDITOR_VIEWER_JOIN, (viewer) => {
                viewer = this.storeViewer(viewer);
                let editor = this.editors.filter(v => v.id == viewer.editorId)[0];
                if (editor != undefined) {
                    editor.viewers = [...editor.viewers, viewer];
                    this.viewerJoinEvents.forEach(events => {
                        if (events.editorId == viewer.editorId) {
                            events.func(viewer);
                        }
                    });
                }
            });

            Services.on(this, productType, WebSocketPanelEventName.COLLABORATION_EDITOR_VIEWER_LEAVE, (viewer) => {
                viewer = this.storeViewer(viewer);
                let editor = this.editors.filter(v => v.id == viewer.editorId)[0];
                if (editor != undefined) {
                    editor.viewers.forEach(v => {
                        if (v.id == viewer.id) {
                            editor.viewers.splice(editor.viewers.indexOf(v), 1);
                        }
                    });

                    this.viewerLeaveEvents.forEach(events => {
                        if (events.editorId == viewer.editorId) {
                            events.func(viewer);
                        }
                    });
                }
            });
        });
    }

    /**
     * action
     */

    public static onMetadata(editorId: string, v: (metadata: ICollaborationEditorMetadataModel) => void, component: INetworkComponent) {
        let i = {editorId: editorId, func: v};
        this.metadataEvents.push(i);
        component.onRemove(() => this.metadataEvents.splice(this.metadataEvents.indexOf(i), 1));
    }

    public static onAttachmentsChanges(editorId: string, v: (viewerId: string, attachments: ICollaborationEditorAttachmentsModel) => void, component: INetworkComponent) {
        let i = {editorId: editorId, func: v};
        this.attachmentsChangesEvents.push(i);
        component.onRemove(() => this.attachmentsChangesEvents.splice(this.attachmentsChangesEvents.indexOf(i), 1));
    }

    public static onViewerJoin(editorId: string, v: (viewer: ICollaborationEditorViewerModel) => void, component: INetworkComponent) {
        let i = {editorId: editorId, func: v};
        this.viewerJoinEvents.push(i);
        component.onRemove(() => this.viewerJoinEvents.splice(this.viewerJoinEvents.indexOf(i), 1));
    }

    public static onViewerLeave(editorId: string, v: (viewer: ICollaborationEditorViewerModel) => void, component: INetworkComponent) {
        let i = {editorId: editorId, func: v};
        this.viewerLeaveEvents.push(i);
        component.onRemove(() => this.viewerLeaveEvents.splice(this.viewerLeaveEvents.indexOf(i), 1));
    }

    /**
     * saving
     */

    public static async savingMetadata(productType: ProductType, p: ICollaborationEditorMetadataModel) {
        await Network.emitSync(productType, WebSocketPanelEventName.COLLABORATION_EDITOR_SAVE_METADATA, p);
    }

    public static async savingAttachments(productType: ProductType, p: ICollaborationEditorAttachmentsOutgoingModel) {
        await Network.emitSync(productType, WebSocketPanelEventName.COLLABORATION_EDITOR_SAVE_ATTACHMENTS, p);
    }

    /**
     * websocket doc
     */

    public static collaborationJoin(component: INetworkComponent, productType: ProductType, editorId: string, privateId: string, connect: (collaboration: ICollaborationJoin) => void, error: () => void): void {
        let yDoc = new Y.Doc();
        let productName = ProductName.toString(productType);
        let provider = new WebsocketProvider(`${config.editorCollaborationUrl}/?productType=${productName}&private=${privateId}&editor=${editorId}&`, editorId, yDoc);

        component.onRemove(() => provider.disconnect());
        provider.on("status", (data: { status: 'disconnected' | 'connecting' | 'connected' }) => {
            if (data.status == "connected") {
                connect({yDoc: yDoc, yText: yDoc.getText(editorId), provider: provider})
            } else {
                provider.disconnect();
                yDoc.destroy();
                error();
            }
        });

        provider.on('connection-error', (data) => {
            if (!component.isNull()) {
                provider.disconnect();
                yDoc.destroy();
                error();
            }
        });
    }


    /**
     * websocket handshaking
     */
    public static async join(productType: ProductType, data: {
        editorId: string,
        savingType?: number,
        savingObjectId?: string
    }): Promise<ICollaborationEditorJoinModel | number> {
        if (data != null) {
            let join = await Network.join(productType, data, WebSocketPanelEventName.COLLABORATION_EDITOR_JOIN);
            if (typeof join != "number") {
                join.editor = this.store(join.editor);
                join.viewer = this.storeViewer(join.viewer);
            }

            return join;
        }

        return undefined;
    }

    public static leave(productType: ProductType, editorId: string, viewerId: string) {
        Network.emit(productType, WebSocketPanelEventName.COLLABORATION_EDITOR_LEAVE, {editorId: editorId, viewerId: viewerId});
    }

    /**
     * store
     */

    public static storeAll(editors: ICollaborationEditorModel[]): ICollaborationEditorModel[] {
        for (let key in editors)
            editors[key] = this.store(editors[key]);

        return Services.storeAll(editors);
    }

    public static store(editor: ICollaborationEditorModel): ICollaborationEditorModel {
        editor.viewers = this.storeViewersAll(editor.viewers);
        editor.attachments = this.storeAttachments(editor.attachments);

        editor = Services.store("id", this.editors, editor);
        return editor;
    }

    public static storeAttachments(attachments: ICollaborationEditorAttachmentsModel): ICollaborationEditorAttachmentsModel {
        attachments.previews.forEach(value => {
            value.preview = PreviewService.store(value.preview);
            value.account = AccountService.store(value.account);
        });

        attachments.files.forEach(value => {
            value.file = FileService.store(value.file);
            value.account = AccountService.store(value.account);

            if (value.cropping) {
                Object.keys(value.cropping).forEach((key) => {
                    let crop = value.cropping[key];
                    if (crop) {
                        crop.content = FileService.store(crop.content);
                    }
                });
            }
        });

        return attachments;
    }

    public static storeViewersAll(viewers: ICollaborationEditorViewerModel[]): ICollaborationEditorViewerModel[] {
        for (let key in viewers)
            viewers[key] = this.storeViewer(viewers[key]);

        return Services.storeAll(viewers);
    }

    public static storeViewer(viewer: ICollaborationEditorViewerModel): ICollaborationEditorViewerModel {
        viewer.account = AccountService.store(viewer.account);
        viewer = Services.store("id", this.viewers, viewer);
        return viewer;
    }
}