import {observable} from "@nx-js/observer-util";
import {Services} from "../../../Services";
import {IOfferProductTemplateModel} from "../../../../models/offer/product/templates/IOfferProductTemplateModel";

export class OfferProductTemplateService {
    public static templates: IOfferProductTemplateModel[] = observable([]);

    public static dispose(): void {
        this.templates = observable([]);
    }

    public static init(): void {

    }

    /**
     * store
     */
    public static storeAll(templates: IOfferProductTemplateModel[]): IOfferProductTemplateModel[] {
        for (let key in templates)
            templates[key] = this.store(templates[key]);

        return Services.storeAll(templates);
    }

    public static store(template: IOfferProductTemplateModel): IOfferProductTemplateModel {
        template = Services.store("id", this.templates, template);
        return template;
    }
}