import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {IInboxShortcutModel} from "../../../models/inbox/shortcut/IInboxShortcutModel";
import {InboxShortcutAttachmentService} from "./attachment/InboxShortcutAttachmentService";
import {IFileModel} from "../../../models/file/IFileModel";
import {IPreviewModel} from "../../../models/preview/IPreviewModel";
import {WebSocketPanelEventName} from "../../../network/socket/names/WebSocketPanelEventName";
import {ProductType} from "../../../models/product/ProductType";

export class InboxShortcutService {
    public static shortcuts: IInboxShortcutModel[] = observable([]);
    public static loaded: boolean;

    public static init() {
        Services.beforeInit(this);
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.INBOX_SHORTCUT_NEW, (data) => {
            this.store(data);
        });
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.INBOX_SHORTCUT_DELETE, (data) => {
            this.unStore(data);
        });
    }

    public static dispose() {
        this.shortcuts = observable([]);
    }

    /**
     * http
     */

    public static async findMind(component?: INetworkComponent): Promise<IInboxShortcutModel[]> {
        if (this.loaded) {
            return this.shortcuts;
        }

        let request = await Network.get(ProductType.PANEL, `/shortcuts/mind`, component);
        if (request.status == HttpStatus.OK) {
            this.loaded = true;
            return this.storeAll(request.data);
        }

        return [];
    }

    public static async create(name: string, deltas: string, html: string, isPrivate: boolean, files: IFileModel[], previews: IPreviewModel[], component?: INetworkComponent): Promise<IInboxShortcutModel> {
        let filesIds = files.map(value => value.id);
        let previewsUrls = previews.map(value => value.baseUrl);

        let request = await Network.post(ProductType.PANEL, "/shortcuts",
            {
                name: name,
                deltas: deltas,
                html: html,
                isPrivate: isPrivate,
                filesIds: filesIds,
                previewsUrls: previewsUrls
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async update(shortcutId: string, name: string, deltas: string, html: string, isPrivate: boolean, files: IFileModel[], previews: IPreviewModel[], component?: INetworkComponent): Promise<IInboxShortcutModel> {
        let filesIds = files.map(value => value.id);
        let previewsUrls = previews.map(value => value.baseUrl);

        let request = await Network.post(ProductType.PANEL, "/shortcuts/update",
            {
                shortcutId: shortcutId,
                name: name,
                deltas: deltas,
                html: html,
                isPrivate: isPrivate,
                filesIds: filesIds,
                previewsUrls: previewsUrls
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async delete(shortcutId: string, component?: INetworkComponent): Promise<void> {
        let request = await Network.delete(ProductType.PANEL, `/shortcuts/${shortcutId}`, component);
        if (request.status == HttpStatus.OK) {
            let shortcut = this.shortcuts.filter(role => role.id == shortcutId)[0];
            this.unStore(shortcut);
        }
    }

    public static async use(shortcut: IInboxShortcutModel, component?: INetworkComponent): Promise<void> {
        let request = await Network.post(ProductType.PANEL, "/shortcuts/use",
            {
                shortcutId: shortcut.id,
            }, component);

        shortcut.uses++;
    }

    /**
     * get
     */
    public static getAll(): IInboxShortcutModel[] {
        return this.shortcuts;
    }

    public static storeAll(shortcuts: IInboxShortcutModel[]): IInboxShortcutModel[] {
        for (let key in shortcuts)
            shortcuts[key] = this.store(shortcuts[key]);

        return Services.storeAll(shortcuts);
    }

    /**
     * store
     */
    public static store(shortcut: IInboxShortcutModel): IInboxShortcutModel {
        if (shortcut.attachmentsCount > 0) {
            shortcut.attachments = InboxShortcutAttachmentService.storeAll(shortcut.attachments);
        }

        return Services.store("id", this.shortcuts, shortcut);
    }

    public static unStore(shortcut: IInboxShortcutModel): void {
        Services.unStore("id", this.shortcuts, shortcut);
    }
}