import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./role-none.scss";
import {Resources} from "../../../../../../resources/Resources";

export class RoleNoneComponent extends Component {
    constructor(text?: string) {
        super();
        //language=HTML
        this.template = `
            <div roleNone class="${s.componentRoleNone}">
                <div class="${s.colors} ${s.top}">
                    <div class="${s.color} ${s.c1}"></div>
                    <div class="${s.color} ${s.c2}"></div>
                    <div class="${s.color} ${s.c3}"></div>
                    <div class="${s.color} ${s.c4}"></div>
                    <div class="${s.color} ${s.c5}"></div>
                    <div class="${s.color} ${s.c6}"></div>
                </div>

                <div class="${s.container}">
                    <div class="${s.icon}"></div>
                    <div class="${s.text}">
                        ${text == undefined ? Resources.t("words.noPermission") : text}
                    </div>
                </div>
                <div class="${s.colors}">
                    <div class="${s.color} ${s.c1}"></div>
                    <div class="${s.color} ${s.c2}"></div>
                    <div class="${s.color} ${s.c3}"></div>
                    <div class="${s.color} ${s.c4}"></div>
                    <div class="${s.color} ${s.c5}"></div>
                    <div class="${s.color} ${s.c6}"></div>
                </div>
            </div>
        `;
    }
}