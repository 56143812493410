import {Network} from "../../network/Network";
import {WebSocketPanelEventName} from "../../network/socket/names/WebSocketPanelEventName";
import {observable} from "@nx-js/observer-util";
import {Services} from "../Services";
import {AccountService} from "../account/AccountService";
import {IAccountModel} from "../../models/account/IAccountModel";
import {IInboxModel} from "../../models/inbox/IInboxModel";
import {ProductType} from "../../models/product/ProductType";
import {arrayRemove} from "../../sedestral-interface-modules/sedestral-interface-component/utilities/ArrayRemove";

export class InboxService {
    public static activeAccounts: IAccountModel[] = observable([]);

    public static dispose(): void {
        this.activeAccounts = observable([]);
    }

    public static init(): void {
        Services.beforeInit(this);
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.INBOX_JOIN, (data) => {
            let inbox: IInboxModel = data;
            AccountService.storeAll(inbox.entities).forEach(value => this.activeAccounts.push(value));
        });
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.INBOX_JOIN_ACCOUNT, (data) => {
            let account = AccountService.store(data);

            if (!this.activeAccounts.includes(account)) {
                this.activeAccounts.push(account);
            }
        });
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.INBOX_LEAVE_ACCOUNT, (data) => {
            let account = AccountService.store(data);
            arrayRemove(this.activeAccounts, account);
        });
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.INBOX_UPDATE_COUNTS, (data) => this.onCountsChange(data));
    }


    /**
     * websocket
     */

    public static join(id: string) {
        Network.emit(ProductType.PANEL, WebSocketPanelEventName.INBOX_JOIN, id);
    }

    public static leave(id: string) {
        this.activeAccounts.splice(0, this.activeAccounts.length);
        Network.emit(ProductType.PANEL, WebSocketPanelEventName.INBOX_LEAVE, id);
    }

    /**
     * override
     */

    public static onCountsChange(counts: any[]) {

    }
}