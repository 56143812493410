@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/animations";

.globalLoaderObjectOffer {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .top {
    margin-top: -10px;
    margin-bottom: 25px;
    width: 50%;
    margin-left: 25%;
  }

  .bottom {
    position: relative;
    overflow: hidden;
    height: calc(100% - 115px);
  }
}