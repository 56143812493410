import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./loader-object-offer.scss";
import {LoaderObjectBlockComponent} from "../block/LoaderObjectBlockComponent";
import {LoaderObjectListComponent} from "../list/LoaderObjectListComponent";

export class LoaderObjectOfferComponent extends Component {
    constructor(shine?: boolean) {
        super();

        //language=HTML
        this.template = `
            <div class="${s.globalLoaderObjectOffer}">
                <div class="${s.top}">
                    ${this.draw(new LoaderObjectListComponent(shine, 1, 40, 40))}
                </div>
                <div class="${s.bottom}">
                    ${this.draw(new LoaderObjectBlockComponent(shine))}
                </div>
            </div>
        `;
    }
}