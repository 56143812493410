import {INotificationAccountModel} from "../../../../models/notification/count/account/INotificationAccountModel";
import {observable} from "@nx-js/observer-util";
import {Services} from "../../../Services";
import {WebSocketPanelEventName} from "../../../../network/socket/names/WebSocketPanelEventName";
import {ProductType} from "../../../../models/product/ProductType";

export class NotificationCountAccountService {

    public static notifications: INotificationAccountModel[] = observable([]);

    public static init() {
        Services.beforeInit(this);
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.NOTIFICATION_ACCOUNT_UPDATE, (notifications) => {
            notifications = this.store(notifications);
        });
    }

    public static virtual(): INotificationAccountModel {
        return {
            entityId: "x",
            inboxCount: 0,
            total: 0,
            siteId: "x"
        }
    }

    public static dispose() {
        this.notifications = observable([]);
    }

    public static storeAll(notifications: INotificationAccountModel[]): INotificationAccountModel[] {
        for (let key in notifications)
            notifications[key] = this.store(notifications[key]);

        return Services.storeAll(notifications);
    }

    public static store(notification: INotificationAccountModel): INotificationAccountModel {
        return Services.store("entityId", this.notifications, notification);
    }


}