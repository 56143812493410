@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentContactBanCreator {
  .head {
    margin-bottom: 10px;

    @include positioning.clear;

    .avatarContainer {
      height: 24px;
      width: 24px;
      border-radius: 100%;
      float: left;
      position: relative;
    }

    .name {
      font-size: 14px;
      float: left;
      margin-top: 3px;
      margin-left: 5px;

      @include texts.font("semi-bold");
    }

    .close {
      float: right;
      height: 26px;
      width: 26px;
      border-radius: 100%;

      @include buttons.round;
      @include positioning.flex-globally;

      .icon {
        height: 12px;
        width: 12px;
        @include icons.ico("black-close");
      }
    }
  }

  .textarea {
    border: 2px solid var(--grey-sur);
    width: 100%;
    padding: 8px;
    border-radius: 14px;
    margin-bottom: 12px;
    resize: none;
    min-height: 100px;
    @include texts.font("medium");

    &::placeholder {
      color: var(--grey-text);
    }


    &:focus {
      outline: none;
    }
  }

  .time {
    font-size: 13px;
    margin-bottom: 10px;
    @include texts.font("semi-bold");

    .ago {
      float: right;
      background: var(--grey-sur);
      padding: 3px 10px;
      border-radius: 10px;
      margin-top: -4px;
    }
  }

  .dateContainer {
    cursor: pointer;

    &:hover {
      .date {
        background: var(--grey-sur);
        border-color: transparent;
      }
    }

    .date {
      border: 2px solid var(--grey-sur);
      border-radius: 10px;
      padding: 8px;
      pointer-events: none;
    }
  }

  .buttonsContainer {
    margin-top: 12px;

    div[btnc], div[btns] {
      font-size: 13px;
      padding: 8px 15px;
      border-radius: 25px;
    }
  }

  @media screen and (max-width: 480px) {
    padding-bottom: 10px !important;

    .name {
      font-size: 16px !important;
      margin-top: 2px !important;
    }

    .date {
      font-size: 14px !important;
    }

    .time {
      font-size: 15px !important;
    }

    .textarea {
      font-size: 14px;
    }

  }
}