@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentContactState {
  .names {
    display: flex;
    align-items: center;

    .icon {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      margin-right: 8px;

      .unlisted {
        height: 100%;
        width: 100%;

        @include icons.ico("3d-contact-unlist");
      }
    }

    .text {
      font-size: 12px;

      @include texts.font("semi-bold");
    }
  }

  .button {
    background: var(--grey-sur);
    margin-left: auto;
    padding: 6px 14px;
    width: fit-content;
    font-size: 12px;
    border-radius: 20px;
    margin-top: 8px;
    cursor: pointer;
    position: relative;


    @include texts.font("semi-bold");

    &:hover {
      background: var(--grey-sur-hover);
    }

    &.loading {
      color: transparent;
    }

    svg {
      width: 14px;

      circle {
        stroke-width: 5px;
      }
    }
  }
}