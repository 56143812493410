import {RoleService} from "../roles/RoleService";
import {RolePermissionsType} from "../../models/roles/types/RolePermissionsType";
import {EntityService} from "../entity/EntityService";
import {FilterType} from "../../models/filter/types/FilterType";
import {CategoryType} from "../../models/category/CategoryType";
import {IInboxShortcutModel} from "../../models/inbox/shortcut/IInboxShortcutModel";
import {NoteType} from "../../models/note/NoteType";
import {INoteModel} from "../../models/note/INoteModel";
import {IAccountModel} from "../../models/account/IAccountModel";
import {IInboxConversationMessageModel} from "../../models/inbox/conversation/message/IInboxConversationMessageModel";
import {EntityType} from "../../models/entity/types/EntityType";
import {ComType} from "../../models/enums/ComType";
import {ICategoryModel} from "../../models/category/ICategoryModel";
import {IPublisherPostModel} from "../../models/publisher/post/IPublisherPostModel";
import {ComState} from "../../models/enums/ComState";
import {PublisherPostService} from "../publisher/posts/PublisherPostService";
import {PublisherTransmittedStatus} from "../../models/publisher/PublisherTransmittedStatus";
import {ComTransmittedStatus} from "../../models/enums/ComTransmittedStatus";
import {ISiteChannelModel} from "../../models/site/channel/ISiteChannelModel";
import {ProductType} from "../../models/product/ProductType";

export class PermissionsService {
    static init() {

    }

    static dispose() {

    }

    /**
     * contact
     */


    static admin(): boolean {
        return RoleService.can(RolePermissionsType.ADMINISTRATOR);
    }

    static contactEditData(): boolean {
        return RoleService.can(RolePermissionsType.CONTACT_ADMINISTRATOR, RolePermissionsType.CONTACT_EDIT_DATA);
    }

    static contactPrivateData(): boolean {
        return RoleService.can(RolePermissionsType.CONTACT_ADMINISTRATOR, RolePermissionsType.CONTACT_PRIVATE_DATA);
    }

    static contactBan(): boolean {
        return RoleService.can(RolePermissionsType.CONTACT_ADMINISTRATOR, RolePermissionsType.CONTACT_BAN);
    }

    static contactActives(): boolean {
        return RoleService.can(RolePermissionsType.CONTACT_ADMINISTRATOR, RolePermissionsType.CONTACT_ACTIVES);
    }

    static canGeneral(): boolean {
        return PermissionsService.canProduct(ProductType.INBOX) || PermissionsService.canProduct(ProductType.PUBLISHER);
    }

    static canSubscription(): boolean {
        return RoleService.can(RolePermissionsType.SUBSCRIPTION_ADMINISTRATOR);
    }

    /**
     * roles
     */
    static rolesManage(): boolean {
        return RoleService.can(RolePermissionsType.ROLES_MANAGE);
    }

    /**
     * accounts
     */
    static accountsManage(): boolean {
        return RoleService.can(RolePermissionsType.ACCOUNT_MANAGE);
    }

    /**
     * invitations
     */
    static invitationsManage(): boolean {
        return RoleService.can(RolePermissionsType.INVITATIONS_MANAGE);
    }

    /**
     * siteChannels
     */
    static siteChannelsManage(): boolean {
        return RoleService.can(RolePermissionsType.SITE_CHANNEL_MANAGE, RolePermissionsType.SITE_CHANNEL_LIVECHAT_MANAGE);
    }

    /**
     * mails
     */

    static mailsManage(): boolean {
        return this.siteChannelsManage();
    }


    /**
     * shortcuts
     */
    static shortcutsPublic(): boolean {
        return RoleService.can(RolePermissionsType.INBOX_ADMINISTRATOR, RolePermissionsType.INBOX_SHORTCUTS_PUBLIC);
    }

    static shortcutsLoad(): boolean {
        return RoleService.can(RolePermissionsType.INBOX, RolePermissionsType.INBOX_ADMINISTRATOR, RolePermissionsType.INBOX_SHORTCUTS_PUBLIC);
    }

    static shortcutManage(shortcut: IInboxShortcutModel): boolean {
        if (shortcut.accountId == EntityService.activeEntity.id) {
            return true;
        }

        return RoleService.can(RolePermissionsType.INBOX_ADMINISTRATOR);
    }

    static noteManage(note: INoteModel): boolean {
        if (note.type == NoteType.CONTACT) {
            if (RoleService.can(RolePermissionsType.CONTACT_ADMINISTRATOR)) {
                return true;
            }
        }

        return note.author.id == EntityService.activeEntity.id;
    }

    /**
     * notes
     */
    static notesManage(type: NoteType): boolean {
        if (type == NoteType.CONTACT) {
            return RoleService.can(RolePermissionsType.CONTACT_ADMINISTRATOR, RolePermissionsType.CONTACT_NOTES_MANAGE);
        }

        return false;
    }

    /**
     * filters
     */
    static filtersPublic(filterType: FilterType): boolean {
        if (filterType == FilterType.CONVERSATION) {
            return RoleService.can(RolePermissionsType.INBOX_ADMINISTRATOR, RolePermissionsType.INBOX_FILTERS_PUBLIC);
        }

        return true;
    }

    /**
     * categories
     */
    static categories(type: CategoryType): boolean {
        if (type == CategoryType.CONVERSATION) {
            return this.inboxLoad();
        }

        return false;
    }

    static categoriesPublic(type: CategoryType): boolean {
        if (type == CategoryType.CONVERSATION) {
            return RoleService.can(RolePermissionsType.INBOX_CATEGORIES_PUBLIC, RolePermissionsType.INBOX_ADMINISTRATOR);
        }

        return false;
    }

    static categoryLoad(category: ICategoryModel) {
        if (!this.categories(category.type)) {
            return false;
        }

        if (category.personal) {
            return category.accountId == EntityService.activeEntity.id;
        }

        return true;
    }

    static categoryUpdate(category: ICategoryModel) {
        if (!this.categories(category.type)) {
            return false;
        }

        if (!category.personal) {
            if (category.type == CategoryType.CONVERSATION) {
                if (RoleService.can(RolePermissionsType.INBOX_ADMINISTRATOR)) {
                    return true;
                }

                if (category.accountId != EntityService.activeEntity.id) {
                    return false;
                }
            }
        }

        return true;
    }

    /**
     * site
     */
    static siteManage(): boolean {
        return RoleService.can(RolePermissionsType.SITE_MANAGE);
    }

    /**
     * inbox
     */
    static inboxLoad(): boolean {
        return this.inboxLoadEntity(EntityService.activeEntity as IAccountModel);
    }

    static inboxLoadEntity(account: IAccountModel): boolean {
        return RoleService.canAccess(account, RolePermissionsType.INBOX, RolePermissionsType.INBOX_ADMINISTRATOR);
    }

    static inboxAssignment(): boolean {
        return RoleService.can(RolePermissionsType.INBOX_ASSIGNMENT, RolePermissionsType.INBOX_ADMINISTRATOR);
    }

    static inboxFiles(): boolean {
        return RoleService.can(RolePermissionsType.INBOX_ADMINISTRATOR, RolePermissionsType.INBOX_FILES);
    }

    /**
     * messages
     */
    static messageUpdate(message: IInboxConversationMessageModel, isDeleted: boolean): boolean {
        if (!isDeleted && message.comType == ComType.EMAIL) {
            return false;
        }

        if (message.senderType == EntityType.CONTACT && !isDeleted) {
            return false;
        }

        if (EntityService.activeEntity.type != EntityType.ACCOUNT) {
            return false;
        }

        if (RoleService.can(RolePermissionsType.INBOX_EDIT_ALL, RolePermissionsType.INBOX_ADMINISTRATOR)) {
            return true;
        }

        if (EntityService.activeEntity.type != message.senderType && EntityService.activeEntity.id != message.senderId) {
            return false;
        }

        return false;
    }

    static blogWriting(): boolean {
        return RoleService.can(
            RolePermissionsType.BLOG_WRITING,
            RolePermissionsType.BLOG_ADMINISTRATOR,
            RolePermissionsType.BLOG_PUBLISH
        );
    }

    static blogLoad(): boolean {
        return RoleService.can(
            RolePermissionsType.BLOG_READING,
            RolePermissionsType.BLOG_WRITING,
            RolePermissionsType.BLOG_ADMINISTRATOR,
            RolePermissionsType.BLOG_PUBLISH
        );
    }

    static helpcenterWriting(): boolean {
        return RoleService.can(
            RolePermissionsType.HELPCENTER_WRITING,
            RolePermissionsType.HELPCENTER_ADMINISTRATOR,
            RolePermissionsType.HELPCENTER_PUBLISH
        );
    }

    static helpcenterLoad(): boolean {
        return RoleService.can(
            RolePermissionsType.HELPCENTER_READING,
            RolePermissionsType.HELPCENTER_WRITING,
            RolePermissionsType.HELPCENTER_ADMINISTRATOR,
            RolePermissionsType.HELPCENTER_PUBLISH
        );
    }

    /**
     * statistics
     */
    static statisticsInbox(): boolean {
        return RoleService.can(
            RolePermissionsType.STATISTICS_INBOX,
            RolePermissionsType.STATISTICS_INBOX_MEMBERS,
            RolePermissionsType.STATISTICS_INBOX_CONVERSATION,
            RolePermissionsType.STATISTICS_INBOX_MESSAGES,
            RolePermissionsType.STATISTICS_ADMINISTRATOR,
            RolePermissionsType.INBOX_ADMINISTRATOR
        );
    }

    static statisticsInboxMessages(): boolean {
        return RoleService.can(
            RolePermissionsType.STATISTICS_INBOX,
            RolePermissionsType.STATISTICS_INBOX_MESSAGES,
            RolePermissionsType.STATISTICS_ADMINISTRATOR,
            RolePermissionsType.INBOX_ADMINISTRATOR
        );
    }

    static statisticsInboxConversations(): boolean {
        return RoleService.can(
            RolePermissionsType.STATISTICS_INBOX,
            RolePermissionsType.STATISTICS_INBOX_CONVERSATION,
            RolePermissionsType.STATISTICS_ADMINISTRATOR,
            RolePermissionsType.INBOX_ADMINISTRATOR
        );
    }

    static statisticsInboxMembers(): boolean {
        return RoleService.can(
            RolePermissionsType.STATISTICS_INBOX,
            RolePermissionsType.STATISTICS_INBOX_MEMBERS,
            RolePermissionsType.STATISTICS_ADMINISTRATOR,
            RolePermissionsType.INBOX_ADMINISTRATOR
        );
    }

    /**
     * publisher post
     */

    static publisherLoad(): boolean {
        return RoleService.can(
            RolePermissionsType.PUBLISHER,
            RolePermissionsType.PUBLISHER_ADMINISTRATOR
        );
    }

    static publisherPostDelete(post: IPublisherPostModel) {
        if (post.transmittedStatus == PublisherTransmittedStatus.IMPORTATION) {
            return false;
        }

        if (post.state == ComState.DELETED) {
            return false;
        }

        if (post.transmittedStatus == PublisherTransmittedStatus.NONE || post.transmittedStatus == PublisherTransmittedStatus.ERROR) {
            return true;
        }

        if (post.sitesChannels.every(siteChannel => this.isCanDeleteSiteChannel(post, siteChannel) || this.isCanDeleteNotPublish(post, siteChannel))) {
            return true;
        }

        return PublisherPostService.getMergeProperties(post.sitesChannels, post.type).canDelete;
    }

    static isCanDeleteSiteChannel(publisherPost: IPublisherPostModel, siteChannel: ISiteChannelModel): boolean {
        const channelPublisherPropertiesMap = siteChannel.channel.publisherProperties[publisherPost.type];
        return channelPublisherPropertiesMap?.canDelete ?? false;
    }

    static isCanDeleteNotPublish(post: IPublisherPostModel, siteChannel: ISiteChannelModel): boolean {
        const publisherTransmission = post.transmissions.find(transmission => transmission.siteChannelId === siteChannel.id);
        if (!publisherTransmission) {
            return false;
        }

        const {remoteId, transmittedStatus} = publisherTransmission;
        return remoteId === null && (transmittedStatus === ComTransmittedStatus.ERROR || transmittedStatus === ComTransmittedStatus.ERROR_RETRY);
    }

    static publisherPostEdit(post: IPublisherPostModel) {
        if (post.transmittedStatus == PublisherTransmittedStatus.IMPORTATION) {
            return false;
        }
        return PublisherPostService.getMergeProperties(post.sitesChannels, post.type).canUpdate;
    }

    /**
     * product
     */

    static canProduct(type: ProductType): boolean {
        if (!EntityService.activeSite) {
            return false;
        }
        return EntityService.activeSite.products.includes(type);
    }

    /**
     * utilities
     */
    static d(permission: boolean): string {
        return permission ? `` : `display:none;`;
    }

    static o(permission: boolean): string {
        return permission ? `` : `opacity:0.3;pointer-events:none;`;
    }

}