/**
 * Retourne le mois suivant selon l'année et le mois donné
 */
export function dateNextMonth(year: number, month: number): object {
    if (month == 11) {
        year++;
        month = 0;
    } else {
        month++;
    }
    return {year: year, month: month};
}