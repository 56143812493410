import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-black-flow.scss";
import {NetworkVendor} from "../../../../../../network/NetworkVendor";
import {IWebsiteBlackFlow, IWebsiteBlackFlowEntry} from "./types/IWebsiteBlackFlow";
import {isMobile} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/IsMobile";

export class WebsiteBlackFlowComponent extends Component {

    public flow: IWebsiteBlackFlow;

    public textsContainer: Component;
    public imageContainer: Component;
    public image: Component;
    public entriesComponents: Component[];

    constructor(flow: IWebsiteBlackFlow) {
        super();
        this.flow = flow;
        this.entriesComponents = [];

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteBlackFlow}">
                <div class="${s.title}">
                    ${flow.title}
                </div>
                <div class="${s.bigTitle}">
                    ${flow.bigTitle}
                </div>
                <div class="${s.contents}">
                    <div class="${s.imageContainer}">
                        <div class="${s.imgShadow}"></div>
                        <img class="${s.image}" src="${flow.image}"/>
                    </div>
                    <div class="${s.texts} ${this.flow.texts.canCollapse ? s.textCollapse : ``}"></div>
                </div>
            </div>
        `;
    }

    commit() {
        this.textsContainer = this.el(s.texts);
        this.imageContainer = this.el(s.imageContainer);
        this.image = this.el(s.image);

        this.flow.texts.entries.forEach(entry => this.renderEntry(entry));

        if (this.flow.texts.canCollapse)
            this.entriesComponents[0].removeClass(s.collapsed);

        if (!isMobile()) {
            this.image.getHTMLElement().onload = () => {
                let imageWidth = this.image.getWidth();
                this.imageContainer.isBelowScroll((isVisible) => {
                    if (isVisible) {
                        this.imageContainer.translate({translateX: 0, delay: 750});
                        this.timeOut(() => this.textsContainer.translate({opacity: 1, delay: 1000}), 300);
                    } else {
                        this.imageContainer.translate({translateX: imageWidth / 2, delay: 1000})
                        this.textsContainer.translate({opacity: 0, delay: 1000});
                    }
                }, true);
            }
        }

        super.commit();
    }

    renderEntry(entry: IWebsiteBlackFlowEntry) {
        //language=HTML
        let entryComponent = this.textsContainer.append(`
            <div class="${s.entry} ${this.flow.texts.canCollapse ? s.canCollapse : ``}">
                <div class="${s.nameContainer}">
                    <div class="${s.name}">
                        <div class="${s.icon} ${s[entry.icon]} ${s[entry.iconColor]}"></div>
                        ${entry.title}
                    </div>
                    <div class="${s.arrow}"></div>
                </div>
                <div class="${s.description}">
                    ${entry.description}
                </div>
            </div>
        `);

        this.entriesComponents.push(entryComponent);

        if (this.flow.texts.canCollapse) {
            entryComponent.addClass(s.collapsed);
            entryComponent.onClick(() => {
                this.entriesComponents.forEach(value => value.addClass(s.collapsed));
                entryComponent.removeClass(s.collapsed);
            });
        }
    }

}