import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./offer.scss";
import {OfferContentComponent} from "./content/OfferContentComponent";
import {OfferSolutionComponent} from "./solution/OfferSolutionComponent";
import {OfferPriceComponent} from "./price/OfferPriceComponent";
import {ProductType} from "../../../../../models/product/ProductType";
import {
    LoaderObjectOfferComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/objects/offer/LoaderObjectOfferComponent";
import {OfferService} from "../../../../../services/offer/OfferService";
import {OfferPeriodComponent} from "./period/OfferPeriodComponent";
import {IOfferProductTemplatesModel} from "../../../../../models/offer/product/templates/IOfferProductTemplatesModel";
import {OfferPeriodType} from "../../../../../models/offer/product/OfferPeriodType";
import {IOfferProductTemplateModel} from "../../../../../models/offer/product/templates/IOfferProductTemplateModel";
import {Resources} from "../../../../../resources/Resources";
import {IOfferPeriodTypeModel} from "../../../../../models/offer/IOfferPeriodTypeModel";
import {OfferProductLevel} from "../../../../../models/offer/product/OfferProductLevel";
import {IOfferDetailsModel} from "../../../../../models/offer/IOfferDetailsModel";
import {OfferState} from "../../../../../models/offer/OfferState";
import {OfferQuoteComponent} from "./quote/OfferQuoteComponent";
import {OfferQuoteConfirmedComponent} from "./quote/OfferQuoteConfirmedComponent";
import {IOfferProductModel} from "../../../../../models/offer/product/IOfferProductModel";
import {OfferQuoteScheduledComponent} from "./quote/OfferQuoteScheduledComponent";
import {Network} from "../../../../../network/Network";
import {OfferContents} from "./content/types/OfferContents";
import {OfferGridComponent} from "./grid/OfferGridComponent";
import {
    SedestralInterface
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/SedestralInterface";

export class OfferComponent extends Component {

    public details?: IOfferDetailsModel;
    public productType: ProductType;
    public website?: boolean;

    public activeOffer: IOfferDetailsModel;
    public activeOfferProduct: IOfferProductModel;

    public periodContainer: Component;
    public advantageContainer: Component;
    public solutionsContainer: Component;
    public solutionsSection: Component;
    public templatesContainer: Component;
    public contentContainer: Component;
    public priceContainer: Component;

    public gridComponent: OfferGridComponent;
    public solutionsComponents: OfferSolutionComponent[] = [];

    public templates: IOfferProductTemplatesModel;
    public selectedTemplate: IOfferProductTemplateModel;
    public period: IOfferPeriodTypeModel;

    constructor(productType: ProductType, details?: IOfferDetailsModel, website?: boolean) {
        super();
        this.details = details;
        this.productType = productType;
        this.website = website;

        //language=HTML
        this.template = `
            <div class="${s.componentOffer} ${this.website ? s.website : s.modal}">
                <div class="${s.loader}">
                    ${this.draw(new LoaderObjectOfferComponent(true))}
                </div>
            </div>`;
    }

    commit() {
        super.commit();
        super.scrollable();

        this.renderAll(this.details);
    }

    async renderAll(details?: IOfferDetailsModel) {
        if (this.website) {
            await this.renderAllTemplates();
        } else {
            if (details) {
                await this.renderQuote(details);
            } else {
                details = await OfferService.quote(this)
                if (details) {
                    await this.renderQuote(details);
                } else {
                    this.activeOffer = await OfferService.active(false, this);
                    this.activeOfferProduct = this.activeOffer?.offer.products[this.productType];

                    await this.renderAllTemplates();
                }
            }
        }

        super.scrollable();
    }

    async renderAllTemplates() {
        this.templates = await OfferService.findAll(this);
        this.clearAll();

        this.renderTop();
        this.renderTopPeriod();

        this.renderTemplatesContainer();
        this.renderSolutionsContainer();
        this.renderContentContainer();
        this.renderPriceContainer();

        this.renderBottomAll();
    }

    renderBottomAll() {
        this.solutionsContainer.clearAll();

        let solutions = this.el(s.solutions);
        solutions.displayHide();

        if (this.selectedTemplate.level == OfferProductLevel.PREMIUM) {
            solutions.displayShow();
            this.renderSolutions();
        }

        this.renderContent();
        this.renderPrice();
    }

    renderTop() {
        this.clearAll();
        //language=HTML
        this.append(`
            <div class="${s.top}">
                <div class="${s.brand}">
                    ${Resources.t(`words.offerDescription${this.productType}`)}
                </div>
                <div class="${s.period}"></div>
                <div class="${s.promo}">
                </div>
            </div>
        `);

        this.periodContainer = this.el(s.period);
        this.advantageContainer = this.el(s.promo);
    }

    renderTopPeriod() {
        this.period = this.templates.periods[0];
        if (this.activeOffer) {
            this.period = this.templates.periods.find(value => value.periodType == this.activeOffer.offer.periodType);
        }

        this.periodContainer.clearAll();

        let periodPicker = new OfferPeriodComponent(this.templates.periods);
        this.periodContainer.render(periodPicker);

        periodPicker.select(this.period.periodType + "", true);
        periodPicker.onSelect = (value) => {
            this.period = this.templates.periods.find(period => period.periodType == value as unknown as number);
            this.renderTopPeriodAdvantage(this.period.periodType);
            this.setPeriod(this.period);

            this.renderPrice();
        };
        this.renderTopPeriodAdvantage(this.period.periodType);
    }

    renderTopPeriodAdvantage(periodType: OfferPeriodType) {
        let period = this.templates.periods.find(value => value.periodType == periodType);

        this.advantageContainer.setText(Resources.r("words.offerAdvantage", {advantage: period.advantagePercentage * 100}));

        if (periodType != OfferPeriodType.MONTHLY) {
            this.advantageContainer.show();
        } else {
            this.advantageContainer.hide();
        }
    }

    renderTemplatesContainer() {
        //language=HTML
        this.templatesContainer = this.append(`
            <div class="${s.templates}"></div>`);

        let templates = this.templates.templates[this.productType]
            .filter(value => value.level == OfferProductLevel.PREMIUM)
            .sort((a, b) => a.order < b.order ? 1 : -1)
            .reverse();

        this.selectedTemplate = templates.find(value => value.level == OfferProductLevel.PREMIUM);
        if (this.activeOffer) {
            this.selectedTemplate = this.activeOfferProduct.template;
        }

        this.gridComponent = new OfferGridComponent(templates, this.period, this.selectedTemplate.id);
        this.gridComponent.onPick = (template) => {
            this.selectedTemplate = template;
            this.renderBottomAll();

            this.solutionsSection.scrollTo(SedestralInterface.html, -80);
        }
        this.templatesContainer.render(this.gridComponent);
    }

    renderSolutionsContainer() {
        //language=HTML
        this.solutionsSection = this.append(`
            <div class="${s.solutions}">
                <div class="${s.title}">
                    ${Resources.t("words.offerSolutions")}
                </div>
                <div class="${s.solutionsContainer}"></div>
            </div>
        `);

        this.solutionsContainer = this.el(s.solutionsContainer);
    }

    renderSolutions() {
        let bundle = this.selectedTemplate.bundles[0];

        this.solutionsComponents = [];

        bundle.solutions
            .forEach(solution => {
                let solutionComponent = new OfferSolutionComponent(bundle, solution);
                solutionComponent.onSolutionChange = (count) => {
                    this.solutionsComponents.forEach(value => value.setGranularity(count));

                    this.renderContent();
                    this.renderPrice();
                }

                //language=HTML
                if (!OfferContents.hiddenTypes().includes(solution.type)) {
                    this.solutionsContainer.append(`
                        <div class="${s.solution}">
                            ${this.solutionsContainer.draw(solutionComponent)}
                        </div>
                    `);
                }

                this.solutionsComponents.push(solutionComponent);

                if (this.activeOfferProduct) {
                    let activeBundle = this.activeOfferProduct.bundles
                        .find(value => value.solutions.find(value1 => value1.type == solution.type) != undefined);

                    if (activeBundle && (!bundle.maxOption || activeBundle.quantityOption <= bundle.maxOption)) {
                        if (this.activeOfferProduct.template.type == this.selectedTemplate.type)
                            solutionComponent.setGranularity(activeBundle.quantityOption);
                    }
                }
            });
    }

    renderContentContainer() {
        this.contentContainer = this.append(`<div class="${s.content}"></div>`);
    }

    renderContent() {
        let bundle = this.selectedTemplate.bundles[0];

        let contents = bundle.solutions
            .filter(solution => !OfferContents.hiddenTypes().includes(solution.type))
            .map(solution => {
                let solutionComponent = this.solutionsComponents.find(value => value.solution == solution);
                let quantity = solution.quantityInitial;
                if (solutionComponent) {
                    quantity += solutionComponent.solutionCount() * solution.granularity;
                }

                return `${quantity} ${Resources.t(`words.offerSolution${this.selectedTemplate.type}${this.selectedTemplate.productType}${solution.type}`)}`;
            });

        this.contentContainer.clearAll();
        this.contentContainer.render(new OfferContentComponent(OfferContents.contents(contents, this.selectedTemplate), Resources.t("words.offerContentRecap")));
    }

    renderPriceContainer() {
        this.priceContainer = this.append(`<div class="${s.price}"></div>`);
    }

    renderPrice() {
        if (this.selectedTemplate.level == OfferProductLevel.FREE) {
            this.priceContainer.hide();
        } else {
            this.priceContainer.show();

            let bundle = this.selectedTemplate.bundles[0];
            let price = bundle.priceInitial;

            if (this.solutionsComponents.length > 0) {
                price += (this.solutionsComponents[0].solutionCount()) * bundle.priceOption;
            }

            this.priceContainer.clearAll();

            let priceComponent = new OfferPriceComponent({
                period: this.period,
                price: price,
                text: this.activeOffer ? Resources.t("words.offerEdit") : Resources.t("words.getStarted")
            });
            priceComponent.onGetStarted = async () => {
                if (this.website) {
                    Network.router.pages.redirect(Network.router.pages.getPanelUrl('signup'), true);
                } else {
                    priceComponent.setLoading();

                    let product = {
                        templateId: this.selectedTemplate.id,
                        bundlesQuantity: [this.solutionsComponents.length > 0 ? this.solutionsComponents[0].solutionCount() : 0]
                    }

                    let quote = await OfferService.quoteSubscription({
                        paymentPeriod: this.period.periodType,
                        products: [product]
                    }, priceComponent);

                    if (quote) {
                        await this.renderQuote(quote);
                    }

                    priceComponent.remLoading();
                }
            }

            this.priceContainer.render(priceComponent);
        }
    }

    async renderQuote(quote: IOfferDetailsModel) {
        if (quote.offer.state == OfferState.PAYMENT && quote.url) {
            location.href = quote.url;
            return;
        }

        if (quote.error) {
            Network.router.static.components.notifications.notify(Resources.t("words.errorMessage"), undefined, 3000, "error");
        }

        this.clearAll();

        let quoteComponent: OfferQuoteComponent;
        switch (quote.offer.state) {
            case OfferState.CONFIRMED:
                quoteComponent = new OfferQuoteComponent(this.productType, quote);
                break;
            case OfferState.PAYMENT:
            case OfferState.FAILURE_TO_PAY:
            case OfferState.SUSPENDED:
                quoteComponent = new OfferQuoteComponent(this.productType, quote);
                quoteComponent = new OfferQuoteConfirmedComponent(this.productType, quote);
                break;
            case OfferState.SCHEDULED:
                quoteComponent = new OfferQuoteScheduledComponent(this.productType, quote);
                break;
        }

        quoteComponent.onStep = (details) => this.renderAll(details);
        quoteComponent.onCloseModal = () => this.onCloseModal();

        this.render(quoteComponent);
    }

    setPeriod(period: IOfferPeriodTypeModel) {
        this.gridComponent.setPeriod(period);
    }

    onCloseModal() {

    }
}