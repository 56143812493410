import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "../website-content.scss";
import * as i from "./../../columns/steps/website-columns-steps.scss";
import {WebsiteProductComponent} from "../../product/WebsiteProductComponent";
import {WebsitePresentationVerticalComponent} from "../../presentation/vertical/WebsitePresentationVerticalComponent";
import {WebsiteColumnsDoubleComponent} from "../../columns/double/WebsiteColumnsDoubleComponent";
import {WebsiteColumnsStepsComponent} from "../../columns/steps/WebsiteColumnsStepsComponent";
import {WebsiteSliderLeftComponent} from "../../slider/left/WebsiteSliderLeftComponent";
import {ProductType} from "../../../../../../../models/product/ProductType";
import {Network} from "../../../../../../../network/Network";
import {
    WebsiteIssuesComponent
} from "../../issues/WebsiteIssuesComponent";
import {Resources} from "../../../../../../../resources/Resources";

export class WebsiteContentLivechatComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteProductComponent({
                            title: Resources.t("words.websiteLivechat"),
                            text: Resources.t("words.installSolution"),
                            productType: ProductType.LIVECHAT,
                            url: `${Network.vendor}images/capture-livechat.webp`
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteColumnsDoubleComponent({
                            color: "black",
                            title: Resources.t("words.relationship"),
                            columns: [{
                                icon: "customerService",
                                name: "Support",
                                text: Resources.t("words.helpCustomer"),
                            }, {
                                icon: "transaction",
                                name: "Ventes",
                                text: Resources.t("words.quickContact")
                            }]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteColumnsStepsComponent({
                            title: Resources.t("words.startNow"),
                            grid: [{
                                name: Resources.t("words.download"),
                                description: Resources.t("words.codeCopy"),
                                icon: i.computer
                            }, {
                                name: Resources.t("words.customize"),
                                description: Resources.t("words.colorCustomize"),
                                icon: i.pensil
                            }, {
                                name: Resources.t("words.start"),
                                description: Resources.t("words.startReply"),
                                icon: i.letter
                            }
                            ]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("words.customerChatbox"),
                            description: Resources.t("words.fullyCustomizableLivechat"),
                            image: `${Network.vendor}images/` + "illus-livechats.webp",
                            color: "multi",
                            size: "tall"
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteSliderLeftComponent(Resources.t("words.features"), [{
                            icon: "trombone",
                            title: Resources.t("words.files"),
                            desc: Resources.t("words.shareFiles"),
                            image: `${Network.vendor}images/` + "content-files.webp",
                            width: "223.81",
                            height: "399.98"
                        }, {
                            icon: "writePaper",
                            title: Resources.t("words.answerQuickly"),
                            desc: Resources.t("words.predefinedTexts"),
                            image: `${Network.vendor}images/` + "content-answerQuickly.webp",
                            width: "547.11",
                            height: "399.99"
                        }, {
                            icon: "pensil",
                            title: Resources.t("words.collectInformations"),
                            desc: Resources.t("words.customersForm"),
                            image: `${Network.vendor}images/` + "content-form.webp",
                            width: "223.2",
                            height: "399.98"
                        }, {
                            icon: "clock",
                            title: Resources.t("words.activity"),
                            desc: Resources.t("words.activityHours"),
                            image: `${Network.vendor}images/` + "content-informationGathering.webp",
                            width: "311.04",
                            height: "399.99"
                        }]))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("words.analyzeYourPerformance"),
                            description: "",
                            image: `${Network.vendor}images/` + "capture-poll.webp",
                            color: "purple",
                            size: "tall",
                            shadow: "shadow",
                            border: "border"
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("words.livechatMore"),
                            description: "",
                            image: `${Network.vendor}images/` + "capture-mail-connected.webp",
                            color: "black",
                            size: "tall"
                        }))}
                    </div>
                        <!--<div class="${s.container}">
                        $ {this.draw(new WebsiteMobileComponent())}
                    </div>-->
                    <div class="${s.container}">
                        ${this.draw(new WebsiteIssuesComponent({
                            questions: Resources.t("words.questions.livechat")
                        }))}
                    </div>
                </div>
        `;
    }
}