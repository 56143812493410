@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/effects";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentSites {
  height: 100%;
  width: 100%;
  padding-top: 10px;

  div[site] {
    width: calc(100% - 7px);
  }

  div[scrolltrack] {
    right: -12px !important;
    top: 10px;
    height: calc(100% - 20px);
  }
}