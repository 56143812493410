@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.pageNotfound {
  padding: 20px;
  height: calc(100% - 40px);
  position: absolute;
  width: calc(100% - 40px);

  .textSide {
    height: 100%;
    width: 50%;
    float: left;
    max-width: 420px;

    .text {
      float: left;

      .found {
        font-size: 200px;

        @include texts.font("bold");
      }

      .title {
        font-size: 47px;
        margin-bottom: 10px;

        @include texts.font("bold");
      }

      .description {
        font-size: 18px;
      }
    }
  }

  .illustration {
    float: right;
    width: 50%;
    height: 100%;
    background: linear-gradient(to top, rgb(255 225 116), rgb(241 229 186));
    border-radius: 15px;

    @include positioning.flex-globally;

    .icon {
      height: 340px;
      width: 340px;

      @include icons.ico("lineal-parachute");
    }
  }
}