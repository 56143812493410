import {Network} from "../../network/Network";
import {NotificationBadger} from "../../models/notification/types/NotificationBadger";
import {observable, observe} from "@nx-js/observer-util";
import {EntityService} from "../entity/EntityService";
import {INotificationModel} from "../../models/notification/INotificationModel";
import {Services} from "../Services";
import {InboxConversationService} from "../inbox/conversation/InboxConversationService";
import {INetworkComponent} from "../../network/types/INetworkComponent";
import {HttpStatus} from "../../network/status/HttpStatus";
import {ProductType} from "../../models/product/ProductType";
import {soundPlay} from "../../sedestral-interface-modules/sedestral-interface-component/utilities/SoundPlay";

export class NotificationService {

    public static notifications: INotificationModel[] = observable([]);
    private static badger: NotificationBadger;
    private static lastSoundTime: number;
    private static browserNotifications: number;

    public static init() {
        this.lastSoundTime = 0;
        this.browserNotifications = 0;

        this.initBrowserNotifications();
    }

    public static dispose() {
        this.notifications = observable([]);
    }


    /**
     * browser
     */

    public static initBrowserNotifications() {
        observe(() => {
            if (EntityService.activeNotifications.total != this.browserNotifications) {
                this.notifyBrowserTab(EntityService.activeNotifications.total);
            }
        });
    }

    public static notifyBrowserTab(count: number) {
        if (this.badger == undefined) {
            this.badger = new NotificationBadger();
        }

        this.browserNotifications = count;
        this.badger.value = this.browserNotifications;
    }

    /**
     * sounds
     */

    public static notifyMessage() {
        if (this.canPlaySound()) {
            soundPlay(Network.vendor + "/sounds/new_message.mp3");
        }
    }

    public static canPlaySound(): boolean {
        let result = ((Date.now() - this.lastSoundTime) / 1000) > 1;
        if (result) {
            this.lastSoundTime = Date.now();
        }

        return result;
    }

    /**
     * http
     */

    public static async findAll(component?: INetworkComponent): Promise<INotificationModel[]> {
        let request = await Network.get(ProductType.PANEL, `/notifications`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return undefined;
    }

    /**
     * store
     */


    public static storeAll(notifications: INotificationModel[]): INotificationModel[] {
        for (let key in notifications)
            notifications[key] = this.store(notifications[key]);

        return Services.storeAll(notifications);
    }

    public static store(notification: INotificationModel): INotificationModel {
        notification.id = "" + notification.type + notification.productType + notification.time + notification.unReads + notification.conversation;

        if (notification.conversation != undefined) {
            notification.conversation = InboxConversationService.store(notification.conversation);
        }

        return Services.store("id", this.notifications, notification);
    }
}