import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./date-interval-picker.scss";
import {DatePickerComponent} from "../../picker/DatePickerComponent";
import {DateIntervalPickerPurposes} from "./types/DateIntervalPickerPurposes";
import {IDateIntervalPickerPurpose} from "./types/IDateIntervalPickerPurpose";
import {Resources} from "../../../../../resources/Resources";
import {dateToCompare} from "../../../../sedestral-interface-component/utilities/DateToCompare";

export class DateIntervalPickerComponent extends Component {

    public purposesContainer: Component;
    public purposesComponents: Component[];

    public picker: DatePickerComponent;

    public comparator: boolean;
    public firstComparatorDate?: Date;
    public secondComparatorDate?: Date;

    public purpose: IDateIntervalPickerPurpose;

    constructor(firstDate: Date, secondDate: Date, firstComparatorDate?: Date, secondComparatorDate?: Date) {
        super();
        this.picker = new DatePickerComponent({interval: true, firstDate: secondDate, secondDate: firstDate});
        this.firstComparatorDate = firstComparatorDate;
        this.secondComparatorDate = secondComparatorDate;
        this.comparator = this.firstComparatorDate != undefined;

        //language=HTML
        this.template = `
            <div class="${s.globalDateIntervalPicker}">
                <div class="${s.topSide}">
                    <div class="${s.purposes}"></div>
                    <div class="${s.pickerContainer}">
                        ${this.draw(this.picker)}
                    </div>
                </div>
                <div class="${s.buttons}">
                    <div class="${s.button} ${s.validButton}">
                        ${Resources.t("words.validate")}
                    </div>
                    <div class="${s.button} ${s.cancelButton}">
                        ${Resources.t("words.cancel")}
                    </div>
                </div>
            </div>
        `;

    }

    commit() {
        this.purposesContainer = this.el(s.purposes);
        this.renderPurposes();

        this.el(s.validButton).onClick(() => {
            if (this.picker.calendarComponent.firstDate != undefined && this.picker.calendarComponent.secondDate != undefined) {
                this.executePick(this.picker.calendarComponent.firstDate, this.picker.calendarComponent.secondDate);
            }
        });

        this.el(s.cancelButton).onClick(() => this.onCancel());

        this.picker.onPick = () => this.updateAllPurposes();
        super.commit();
    }

    /**
     * purposes
     */

    renderPurposes() {
        this.purposesComponents = [];
        this.purposesContainer.clearAll();
        let purposes = this.comparator ? DateIntervalPickerPurposes.comparatorPurposes : DateIntervalPickerPurposes.basePurposes;
        purposes.forEach(purpose => {
            if (purpose.show) {
                //language=HTML
                let component = this.purposesContainer.append(`
                    <div data-value="${purpose.value}" class="${s.purposeEntry}">
                        ${purpose.name}
                    </div>
                `);
                component['purpose'] = purpose;
                component.onClick(() => {
                    let value = purpose.value;
                    if (this.comparator) {
                        let comparators = DateIntervalPickerPurposes.comparatorCalc(value, this.firstComparatorDate, this.secondComparatorDate);
                        this.picker.calendarComponent.selectInterval(comparators[0], comparators[1]);
                    } else {
                        let comparators = DateIntervalPickerPurposes.baseCalc(value);
                        this.picker.calendarComponent.selectInterval(comparators[0], comparators[1]);
                    }

                    this.purpose = purpose;
                    this.executePick(this.picker.calendarComponent.firstDate, this.picker.calendarComponent.secondDate, purpose);
                });
                this.purposesComponents.push(component);
            }
        });
    }

    updateAllPurposes() {
        this.purposesComponents.forEach(purposeEntry => this.updatePurpose(purposeEntry));
    }

    updatePurpose(purposeComponent: Component) {
        purposeComponent.removeClass(s.selected);
        if (this.purpose != undefined && purposeComponent['purpose'].value == this.purpose.value) {
            purposeComponent.addClass(s.selected);
        }
    }

    executePick(firstDate: Date, secondDate: Date, purpose?: IDateIntervalPickerPurpose) {
        if (firstDate != undefined) {
            firstDate = dateToCompare(new Date(firstDate));
        }

        if (secondDate != undefined) {
            secondDate = dateToCompare(new Date(secondDate), true);
        }

        this.onPick(firstDate, secondDate, purpose);
    }

    /**
     * to override
     */
    onPick(firstDate: Date, secondDate: Date, purpose?: IDateIntervalPickerPurpose) {

    }

    onCancel() {

    }
}