import {IGPTMessageModel} from "../../models/gpt/IGPTMessageModel";
import {WebSocketPanelEventName} from "../../network/socket/names/WebSocketPanelEventName";
import {Network} from "../../network/Network";
import {Services} from "../Services";
import {INetworkComponent} from "../../network/types/INetworkComponent";
import {ProductType} from "../../models/product/ProductType";
import {HttpStatus} from "../../network/status/HttpStatus";
import {IGPTRequestModel} from "../../models/gpt/IGPTRequestModel";
import {Resources} from "../../resources/Resources";
import {ErrorCode} from "../../network/status/error/ErrorCode";

export class GPTService {
    public static segmentEvents: Map<INetworkComponent, (message: IGPTMessageModel) => void> = new Map();

    public static dispose(): void {
        this.segmentEvents.clear();
    }

    public static init(): void {
        Services.beforeInit(this);

        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.GPT_SEGMENT, (message) => {
            this.segmentEvent(message);
        });
    }

    public static segmentEvent(message: IGPTMessageModel) {
        this.segmentEvents.forEach((func, component) => func(message));
    }

    public static onSegment(func: (message: IGPTMessageModel) => void, component: INetworkComponent) {
        this.segmentEvents.set(component, func);
        component.onRemove(() => this.segmentEvents.delete(component));
    }

    public static async request(body: IGPTRequestModel, component?: INetworkComponent): Promise<boolean> {
        Services.handleErrors(component, [
            {errorCode: ErrorCode.NO_CONTACT, message: Resources.t("words.convNoContact"), displayValue: true},
        ]);

        let request = await Network.postJson(ProductType.PANEL, "/gpt/request", body, component);
        return request.status == HttpStatus.OK;
    }
}