@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentWebsiteContentStatistics {
  .reportsContainer {
    display: flex;
    flex-direction: column;
    gap: 100px;
    background: var(--white);
    border-radius: 20px;
    padding: 55px 60px;
    color: var(--black);

    .reports {
      display: flex;
      flex-direction: row-reverse;

      .left {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 30px;

        .title {
          width: 95%;
          font-size: 44px;
          @include texts.font("bold");
        }

        .subtitle {
          width: 80%;
          font-size: 20px;
          @include texts.font("semi-bold");
        }
      }

      .right {
        width: calc(50% - 60px);
        background: var(--web-blue);
        border-radius: 20px;
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        .image {
          width: calc(100% - 75px);
          height: auto;
          border-radius: 20px;
          background: var(--white);
        }
      }
    }

    .container {
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    .reportsContainer {
      gap: 60px;
      padding: 20px 30px;

      .reports {
        flex-direction: column-reverse;
        gap: 30px;

        .left {
          flex-direction: column;
          width: 100%;
          gap: 20px;
          text-align: center;

          .title {
            width: 100%;
            font-size: 25px;
          }

          .subtitle {
            width: 100%;
            font-size: 16px;
          }

          .line {
            display: none;
          }
        }

        .right {
          width: calc(100% - 40px);
          padding: 20px;
        }
      }
    }
  }
}