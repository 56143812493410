@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentLanguageAll {
  width: 100%;

  .countryIcon {
    height: 20px;
    width: 20px;
    margin-right: 6px;
  }
}