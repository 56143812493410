@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/effects";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/outline";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentContactPreview {
  width: 100%;
  @include texts.font("medium");

  .profile {
    position: relative;
    width: 100%;
    margin-top: 3px;

    @include positioning.clear;

    .profileImage {
      height: 40px;
      width: 40px;
      border-radius: 100%;
      top: 2px;
      position: relative;
      float: left;
    }

    .texts {
      margin-left: 10px;
      position: relative;
      float: left;
      width: calc(100% - 52px);

      .name {
        font-size: 17px;
        margin-bottom: 2px;
        position: relative;
        border: unset;
        cursor: text !important;
        height: 22px;


        @include texts.ellipsis(1);
        @include texts.font("bold");

        &[disabled] {
          background: unset !important;
          color: var(--black) !important;
        }
      }

      .identifier {
        font-size: 12px;
        position: relative;
        color: var(--grey-text);
        cursor: text !important;
        border: unset;
        background: unset;
      }

      .name, .identifier {
        padding: 2px;
        border-radius: 5px;

        &.active {
          border-radius: 5px !important;
          @include outline.outline(2);
        }

        &.editable {
          &:hover {
            border-radius: 0;
            box-shadow: 0 2px 0 0 var(--grey-sur);
          }
        }
      }
    }
  }

  .menu {
    left: 0;
    width: 100%;
    border-radius: 9px;
    position: relative;
    margin-top: 8px;
    min-height: 10px;

    @include positioning.clear;

    .buttons {
      position: relative;
      float: right;

      @include positioning.clear;

      .button {
        height: 24px;
        position: relative;
        float: left;
        border-radius: 20px;
        padding: 2px 12px;
        margin-left: 6px;
        cursor: pointer;
        border: 1px solid var(--grey-sur);

        @include positioning.flex-globally;

        &:hover {
          border-color: transparent;
          background: var(--grey-sur);
        }

        &.bans {
          .icon {
            @include icons.ico("black-block");
          }
        }

        &.maximize {
          .icon {
            @include icons.ico("black-maximize");
          }
        }

        &.user {
          .icon {
            @include icons.ico("black-user-add");
          }
        }

        .text {
          margin-right: 6px;
          font-size: 12px;

          @include texts.font("semi-bold");
        }

        .icon {
          height: 10px;
          width: 10px;
        }
      }
    }
  }

  .state {
    margin-top: 10px;
    padding: 12px;
    border-radius: 14px;
    border: 1px solid var(--grey-sur);
    display: none;
  }

  @media screen and (max-width: 480px) {
    padding-bottom: 20px;

    .maximize {
      display: none !important;
    }

    .profile {
      margin-bottom: 15px;

      .texts {
        .name {
          font-size: 19px !important;
        }

        .identifier {
          font-size: 14px !important;
        }
      }
    }

    .menu {
      margin-bottom: 20px;

      .channel {
        .name {
          font-size: 15px !important;
          margin-top: -2px !important;
        }
      }

      .round {
        height: 30px !important;
        width: 30px !important;

        .icon {
          height: 18px !important;
          width: 18px !important;
        }
      }
    }

  }
}