import {IChannelPublisherProperties} from "../../models/channel/properties/IChannelPublisherProperties";

export class ChannelPropertiesService {

    public static mergeInboxProperties(propertiesList: IChannelPublisherProperties[]): IChannelPublisherProperties {
        const canDelete = propertiesList.every(prop => prop.canDelete);
        const canUpdate = propertiesList.every(prop => prop.canUpdate);
        const maxLengthMessage = Math.min(...propertiesList.map(prop => prop.maxLengthMessage));
        const maxLengthVideo = Math.min(...propertiesList.map(prop => prop.maxLengthVideo));
        const maxLengthPhoto = Math.min(...propertiesList.map(prop => prop.maxLengthPhoto));

        const minRatios = propertiesList.map(prop => prop.minRatioPhoto).filter(ratio => ratio !== 0);
        const minRatioPhoto = minRatios.length > 0 ? Math.max(...minRatios) : 0;

        const maxRatios = propertiesList.map(prop => prop.maxRatioPhoto).filter(ratio => ratio !== 0);
        const maxRatioPhoto = maxRatios.length > 0 ? Math.min(...maxRatios) : 0;

        const maxGif = Math.min(...propertiesList.map(prop => prop.maxGif));
        const minDurationVideo = Math.max(...propertiesList.map(prop => prop.minDurationVideo));
        const maxDurationVideo = Math.min(...propertiesList.map(prop => prop.maxDurationVideo));
        const maxSizeVideo = Math.min(...propertiesList.map(prop => prop.maxSizeVideo));
        const maxSizePhoto = Math.min(...propertiesList.map(prop => prop.maxSizePhoto));
        const link = propertiesList.every(prop => prop.link);
        const attachmentsCombination = propertiesList.every(prop => prop.attachmentsCombination);
        const maxLengthAttachmentsCombination = Math.min(...propertiesList.map(prop => prop.maxLengthAttachmentsCombination));
        const attachmentMandatory = propertiesList.some(prop => prop.attachmentMandatory);

        let extensionsPhoto = [], extensionsVideo = [];
        if (propertiesList[0]) {
            extensionsPhoto = [...propertiesList[0].extensionsPhoto];
            propertiesList.forEach(prop => {
                extensionsPhoto = extensionsPhoto.filter(x => prop.extensionsPhoto.includes(x));
            });

            extensionsVideo = [...propertiesList[0].extensionsVideo];
            propertiesList.forEach(prop => {
                extensionsVideo = extensionsVideo.filter(x => prop.extensionsVideo.includes(x));
            });
        }


        return {
            extensionsDocument: [], maxLengthDocument: 0, maxSizeDocument: 0,
            canDelete,
            canUpdate,
            maxLengthMessage,
            maxLengthVideo,
            maxLengthPhoto,
            minRatioPhoto,
            maxRatioPhoto,
            minDurationVideo,
            maxDurationVideo,
            maxSizeVideo,
            maxSizePhoto,
            extensionsPhoto,
            extensionsVideo,
            link,
            attachmentsCombination,
            maxLengthAttachmentsCombination,
            attachmentMandatory,
            maxGif
        };
    }
}