@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsiteBlackSteps {
  border-radius: 50px;
  border: 1px solid var(--web-border-white);
  background: var(--web-background-steps);
  padding: 50px;
  position: relative;

  .bottomShadow {
    position: absolute;
    width: 150px;
    height: 150px;
    background: var(--web-purple);
    filter: blur(95px);
    right: 0;
    bottom: 0;
  }

  .top {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    position: relative;

    .topShadow {
      position: absolute;
      width: 100px;
      height: 100px;
      background: var(--web-purple);
      filter: blur(95px);
      left: 0;
      top: 0;
    }

    .title {
      display: flex;
      gap: 15px;
      align-items: center;
      position: relative;

      .icon {
        height: 22px;
        width: 22px;
        filter: var(--icon-purple-black-web);

        &.seo {
          @include icons.ico("black-lineal-target");
        }
      }

      .value {
        font-size: 14px;
        color: var(--white);
      }
    }

    .buttons {
      display: flex;
      margin-left: auto;
      gap: 10px;

      .button {
        border-radius: 11px;
        background: var(--web-white-alpha);
        position: relative;
        padding: 10px 20px;
        overflow: hidden;
        cursor: pointer;
        transition: 0.1s;

        &:hover {
          background: var(--web-white-alpha-hover);
        }

        .progress {
          background: var(--web-blue);
          width: 0%;
          height: 100%;
          top: 0;
          position: absolute;
          left: 0;
        }

        .name {
          position: relative;
          font-size: 14px;
        }
      }
    }
  }

  .content {
    position: relative;
    border-radius: 14px;
    overflow: hidden;

    .image {
      width: 100%;
      border-radius: 12px;
    }
  }


  @media screen and (max-width: 930px) {
    padding: 15px;
    border-radius: 18px;

    .top {
      width: 100%;
      overflow-y: auto;
      gap: 15px;

      .topShadow {
        display: none;
      }

      .title {
        .icon {
          display: none;
        }

        .value {
          white-space: nowrap;
        }
      }

      .buttons {
        .button {
          white-space: nowrap;
        }
      }
    }
  }
}