@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsiteContentInbox {
  .separation {
    width: 158px;
    height: 40px;
    @include icons.ico("colored-separation");
  }

  .collaboration {
    display: flex;
    flex-direction: column;
    gap: 85px;
    align-items: center;

    .container {
      width: 100%;
    }
  }
}