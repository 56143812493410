import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-product.scss";
import {WebsiteBrandComponent} from "../brand/WebsiteBrandComponent";
import {WebsiteImageSkewedComponent} from "../image/skewed/WebsiteImageSkewedComponent";
import {ProductType} from "../../../../../../models/product/ProductType";
import {ProductName} from "../../../../../../models/product/ProductName";
import {WebsiteVideoComponent} from "../video/WebsiteVideoComponent";

export class WebsiteProductComponent extends Component {

    public productType: ProductType;
    public pUrl: string;
    public imageContainer: Component;

    constructor(p: {
        title: string,
        titleClass?: string,
        text: string,
        productType?: ProductType,
        url: string,
        promo?: string,
        productName?: string,
        buttonText?: string,
        buttonUrl?: string,
        buttonIcon?: string,
        roundIcon?: string
    }) {
        super();

        this.productType = p.productType;
        this.pUrl = p.url;

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteProduct}">
                <div class="${s.brandContainer}">
                    ${this.draw(new WebsiteBrandComponent(p.productType, p.productName))}
                </div>
                <div class="${s.imageContainer}"></div>
                <div class="${s.cardTexts}">
                    ${p.roundIcon ? `<div class="${s.round} ${s[p.roundIcon]}"><div class="${s.icon}"></div></div>` : ``}
                    <h2 class="${s.title} ${s[p.titleClass ? p.titleClass : ProductName.toString(this.productType)]}">
                        ${p.title}
                    </h2>
                    <p class="${s.text}">
                        ${p.text}
                    </p>
                    ${p.promo ? `<div class="${s.promo}">${p.promo}</div>` : ``}
                    ${p.buttonText ?
                            `<a target="_blank" href="${p.buttonUrl}">
                                <div class="${s.button}">
                                    <div class="${s.shadow}"></div>
                                    <div class="${s.content}">
                                        ${p.buttonText}
                                        ${p.buttonIcon ? `<div class="${s.icon} ${s[p.buttonIcon]}"></div>` : `<div class="${s.icon}"></div>`}
                                    </div>
                                </div>
                            </a>` : ``}
                </div>
            </div>
        `;
    }

    commit() {
        this.imageContainer = this.el(s.imageContainer);

        if (this.pUrl.endsWith(".mp4")) {
            //language=HTML
            this.imageContainer.render(new WebsiteVideoComponent(this.pUrl));
        } else {
            this.imageContainer.render(new WebsiteImageSkewedComponent({
                link: this.pUrl,
                shadow: "important",
                width: `920`,
                height: `517.5`
            }));
        }

        super.commit();
    }

}