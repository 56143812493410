import * as s from "./website-head-submenu-content.scss";
import {Network} from "../../../../../../../network/Network";
import {Resources} from "../../../../../../../resources/Resources";
import {WebsiteHeadSubmenuContentComponent} from "./WebsiteHeadSubmenuContentComponent";
import {
    isMobile
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/IsMobile";

export class WebsiteHeadSubmenuContentProductsComponent extends WebsiteHeadSubmenuContentComponent {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteHeadSubmenuContent}">
                <div class="${s.column} ${s.open}">
                    <div class="${s.title}">
                        ${Resources.t("words.tools")}
                        <div class="${s.arrow}"></div>
                    </div>
                    <div class="${s.cases}">
                        <a style="display: none;" itemprop="url" href="${Network.router.pages.getUrlWithPath('ai')}">
                            <div class="${s.case} ${s.inbox}">
                                <div class="${s.capsuleContainer} ${s.grey}">
                                    <div class="${s.icon} ${s.seo}"></div>
                                </div>
                               <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("words.contentStrategy")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("words.contentStrategyDescription")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('ai')}">
                            <div class="${s.case} ${s.inbox}">
                                <div class="${s.capsuleContainer} ${s.grey}">
                                    <div class="${s.icon} ${s.ai}"></div>
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("words.sedestralAI")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("words.sedestralAIDescription")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('analysis')}">
                            <div class="${s.case} ${s.inbox}">
                                <div class="${s.capsuleContainer} ${s.grey}">
                                    <div class="${s.icon} ${s.statistics}"></div>
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("words.seoAnalytics")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("words.seoAnalyticsDescription")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('autopilot')}">
                            <div class="${s.case} ${s.inbox}">
                                <div class="${s.capsuleContainer} ${s.grey}">
                                    <div class="${s.icon} ${s.autopilot}"></div>
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("words.autopilot")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("words.autopilotDescription")}
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="${s.column}">
                    <div class="${s.title}">
                        ${Resources.t("words.integrations")}
                        <div class="${s.arrow}"></div>
                    </div>
                    <div class="${s.cases}">
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('prestashop')}">
                            <div class="${s.case} ${s.inbox}">
                                <div class="${s.capsuleContainer} ${s.grey}">
                                    <div class="${s.icon} ${s.prestashop}"></div>
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("words.prestaShop")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("words.prestaShopDescription")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('shopify')}">
                            <div class="${s.case} ${s.inbox}">
                                <div class="${s.capsuleContainer} ${s.grey}">
                                    <div class="${s.icon} ${s.shopify}"></div>
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("words.shopify")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("words.shopifyDescription")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('wordpress')}">
                            <div class="${s.case} ${s.inbox}">
                                <div class="${s.capsuleContainer} ${s.grey}">
                                    <div class="${s.icon} ${s.wordpress}"></div>
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("words.wordpress")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("words.wordpressDescription")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('wix')}">
                            <div class="${s.case} ${s.inbox}">
                                <div class="${s.capsuleContainer} ${s.grey}">
                                    <div class="${s.icon} ${s.wix}"></div>
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("words.wix")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("words.wixDescription")}
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        if (isMobile()) {
            this.els(s.column).forEach(value => {
                value.onClick(() => {
                    this.els(s.column).forEach(value => this.closeColumn(value));
                    this.openColumn(value);
                })
            });
        }

        super.commit();
    }

}