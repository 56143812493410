.componentSiteTax {
  .componentSiteTaxIcon {
    top: 0 !important;
    margin-right: 5px;
  }
}

.componentSiteTaxIcon {
  height: 16px;
  width: 16px;
  position: relative;
  display: inline-block;
  top: 2.5px;
}