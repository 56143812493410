import {INetworkComponent} from "../../network/types/INetworkComponent";
import {Network} from "../../network/Network";
import {HttpStatus} from "../../network/status/HttpStatus";
import {IWebPushModel} from "../../models/webpush/IWebPushModel";
import {ProductType} from "../../models/product/ProductType";

export class WebPushService {
    public static init(): void {

    }

    public static dispose(): void {

    }

    /**
     * http
     */

    public static async create(currentToken: string, isMobile: boolean, component?: INetworkComponent): Promise<void> {
        let request = await Network.post(ProductType.PANEL, "/webpush", {
            token: currentToken,
            isMobile: isMobile
        }, component);
        if (request.status == HttpStatus.OK) {
            let webPush: IWebPushModel = request.data;
        }
    }
}