/**
 * Fichier vers base64 pour l'afficher
 */

export function fileToBase64(file: File, callback: (data: any, e?: any) => void) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
        callback(e.target.result, e);
    };
}