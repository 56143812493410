import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-review.scss";
import {IWebsiteReview} from "../types/IWebsiteReview";
import {Resources} from "../../../../../../../resources/Resources";

export class WebsiteReviewComponent extends Component {
    constructor(reviewsCount: number, review: IWebsiteReview) {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteReview}" itemscope itemtype="https://schema.org/Review">
                <div class="${s.stars}" itemprop="reviewRating" itemscope itemtype="https://schema.org/Rating">
                    <meta itemprop="ratingValue" content="${review.stars}"/>
                    <meta itemprop="bestRating" content="5"/>
                    <meta itemprop="worstRating" content="1"/>
                    <div>
                        <div class="${s.star}"></div>
                        <div class="${s.star}"></div>
                        <div class="${s.star}"></div>
                        <div class="${s.star}"></div>
                        <div class="${s.star}"></div>
                    </div>
                    <div class="${s.provider} ${s[review.platformIcon]}">
                        <span class="${s.by}">${Resources.t("words.publishedOn")}</span>
                        <span itemprop="publisher">${review.platform}</span>
                        <div class="${s.icon}"></div>
                    </div>
                    <div class="${s.content}" itemprop="reviewBody">
                        ${review.content}
                    </div>
                </div>
                <div class="${s.author}" itemprop="author" itemscope itemtype="https://schema.org/Person">
                    <div class="${s.image}">
                        <img src="${review.avatarUrl}" alt="${review.avatarName}" itemprop="image"/>
                    </div>
                    <div>
                        <div class="${s.name}" itemprop="name">${review.avatarName}</div>
                        <div class="${s.description}">
                            ${review.avatarDescription}
                        </div>
                    </div>
                </div>
                <meta itemprop="datePublished" content="${review.datePublished}"/>
                <div itemprop="itemReviewed" itemscope itemtype="https://schema.org/Product">
                    <meta itemprop="name" content="${review.productName}"/>
                    <div itemprop="aggregateRating" itemscope itemtype="https://schema.org/AggregateRating">
                        <meta itemprop="ratingValue" content="${review.stars}"/>
                        <meta itemprop="reviewCount" content="${reviewsCount}"/>
                        <meta itemprop="bestRating" content="5"/>
                        <meta itemprop="worstRating" content="1">
                    </div>
                </div>
            </div>
        `;


    }
}