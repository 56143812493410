import {Component} from "../../sedestral-interface-component/interface/component/Component";
import * as s from "./steps.scss";

export class StepsComponent extends Component {

    public height: number;
    public headContainer: Component;

    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.visualSteps}"></div>
        `;
    }

    commit() {

        this.initBase();
        this.initButtons();

        this.onCommit(() => {
            this.height = this.getFullHeight();
            this.setStyle(`height:${this.height}px;`);
        });

        super.commit();
    }

    /**
     * logic
     */
    initHead(name: string) {
        //language=HTML
        this.headContainer = this.append(`
            <div class="${s.head}">
                <div class="${s.arrow}">
                    <div class="${s.icon}"></div>
                </div>
                <div class="${s.name}">
                    ${name}
                </div>
            </div>
        `);
        this.headContainer.el(s.arrow).onClick(() => this.resetBase());
    }

    initButton(btnClass: string, func: () => Component, name: string) {
        this.el(btnClass).onClick(async () => {
            await this.translate({translateX: -(this.getWidth() / 2), opacity: 0, delay: 150});
            this.setStyle(`transform:translateX(${(this.getWidth() / 2)}px);transition-duration: 0s;`);
            this.clearAll();
            this.initHead(name);
            let component = func();
            this.render(component);
            this.translate({
                translateX: 0,
                height: (component.getHeight() + this.headContainer.getHeight() + 30),
                opacity: 1,
                delay: 150
            });
        }, true);
    }

    /**
     * override
     */
    initBase() {

    }

    initButtons() {

    }

    /**
     * reset
     */

    async resetBase() {
        await this.translate({translateX: (this.getWidth() / 2), height: this.height, opacity: 0, delay: 150});
        this.setStyle(`transform:translateX(${-(this.getWidth() / 2)}px);transition-duration: 0s;`);
        this.clearAll();
        this.initBase();
        this.translate({
            translateX: 0,
            opacity: 1,
            delay: 150
        });

        this.initButtons();
    }
}