import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import {EmptyBasicComponent} from "../../../empty/EmptyBasicComponent";
import * as s from "./date-calendar-picker.scss";
import {Resources} from "../../../../../resources/Resources";
import {DateCalendarAbstractComponent} from "../DateCalendarAbstractComponent";
import {dateToCompare} from "../../../../sedestral-interface-component/utilities/DateToCompare";
import {dateDayEquals} from "../../../../sedestral-interface-component/utilities/DateDayEquals";

export class DateCalendarPickerComponent extends DateCalendarAbstractComponent {

    public namesContainer: Component;
    public daysContainer: Component;
    public monthContainer: Component;

    public firstDate: Date;
    public secondDate: Date;

    public isInterval?: boolean;
    public daysEntries: Component[];

    constructor(firstDate: Date, secondDate: Date) {
        super();
        this.currentDate = firstDate == undefined ? secondDate : firstDate;
        this.firstDate = firstDate == undefined ? secondDate : firstDate;
        this.secondDate = secondDate;

        this.isInterval = firstDate != undefined;
        this.daysEntries = [];

        this.template = `
            <div class="${s.globalDateCalendarPicker}">
                <div class="${s.buttons}">
                    <div class="${s.previous}">
                        <div class="${s.icon}"></div>
                    </div>
                    <div class="${s.month}"></div>
                    <div class="${s.next}">
                        <div class="${s.icon}"></div>
                    </div>
                </div>
                <div class="${s.names}"></div>
                <div class="${s.days}"></div>
            </div>
        `;
    }

    commit() {
        super.commit();

        this.bind();
        this.init();
    }

    bind() {
        this.el(s.next).onClick(() => {
            this.currentDate = this.abstractNextMonth();
            this.update();
        });
        this.el(s.previous).onClick(() => {
            this.currentDate = this.abstractPreviousMonth();
            this.update();
        });
    }

    init() {
        this.namesContainer = this.el(s.names);
        this.daysContainer = this.el(s.days);
        this.monthContainer = this.el(s.month);

        this.renderNames();
        this.update();
        this.executeSelect(this.firstDate, this.secondDate);
    }

    renderNames() {
        let days = this.abstractNames();
        days.forEach(day => {
            //language=HTML
            this.namesContainer.append(`
                <div class="${s.entryTitle}">
                    ${day[0]}
                </div>
            `);
        });
    }

    update() {
        if (this.monthContainer != undefined) {
            this.renderMonth();
        }
        if (this.daysContainer != undefined) {
            this.renderDays();
        }
        if (this.isInterval) {
            this.renderInterval();
        }
    }

    renderDays() {
        this.daysContainer.clear();
        this.daysEntries = [];

        let days = this.abstractMonthDays();
        days.forEach(day => {
            this.renderDay(day.day, day.month, day.year, day.grey);
        });
    }

    renderDay(day: number, month: number, year: number, previous: boolean) {
        let template = `
            <div entryDate class="${s.entryDate}">
                <div class="${s.round}">
                    ${day}
                </div>
            </div>
        `;
        let entry = this.daysContainer.append(template);
        this.daysEntries.push(entry);

        entry['AllowedDate'] = new Date(year, month, day);
        if (previous) {
            entry.addClass(s.Previous);
        }

        if (!this.isInterval && dateDayEquals(entry['AllowedDate'], this.firstDate)) {
            entry.addClass(s.Selected);
        }

        if (this.isInterval) {
            entry.onHover(() => {
                if (this.firstDate != undefined && this.secondDate == undefined) {
                    this.daysEntries.forEach(e => {
                        let eDate = e['AllowedDate'];
                        e.removeClass(s.Interval);
                        if (eDate > this.firstDate && eDate < entry['AllowedDate']) {
                            e.addClass(s.Interval);
                        }
                    });
                }
            });
        }

        entry.onClick(() => this.selectDay(entry));
    }

    renderMonth() {
        this.monthContainer.setHtml(this.abstractMonthName());
    }

    renderEmpty() {
        this.daysContainer.clear();
        this.daysContainer.render(new EmptyBasicComponent("😨", Resources.t("words.invalidDate")));
    }

    renderInterval() {
        this.daysEntries.forEach(e => {
            let eDate = e['AllowedDate'];
            e.removeClass(s.Interval);
            e.removeClass(s.IntervalSelected);
            e.removeClass(s.Selected);
            e.removeClass(s.IntervalSameDay);

            if (this.firstDate != undefined && this.secondDate != undefined) {
                if (eDate > this.firstDate && eDate < this.secondDate) {
                    e.addClass(s.Interval);
                }
            }

            if (this.firstDate != undefined && dateDayEquals(this.firstDate, eDate)) {
                e.addClass(s.IntervalSelected);
            }

            if (this.secondDate != undefined && dateDayEquals(this.secondDate, eDate)) {
                e.addClass(s.IntervalSelected);
            }

            if (this.firstDate != undefined && this.secondDate != undefined && dateDayEquals(this.firstDate, this.secondDate) && dateDayEquals(this.firstDate, eDate)) {
                e.addClass(s.IntervalSameDay);
            }
        });
    }

    /**
     * select
     */

    selectDay(entry: Component) {
        if (this.isInterval) {
            let date = entry['AllowedDate'];

            if (this.firstDate != undefined && dateDayEquals(date, this.firstDate) && this.secondDate != undefined) {
                this.secondDate = undefined;
                this.firstDate = undefined;
                this.executeSelect(new Date(), new Date());
            } else if (this.firstDate == undefined) {
                this.firstDate = date;
            } else {
                if (this.secondDate != undefined && dateDayEquals(date, this.secondDate)) {
                    this.secondDate = undefined;
                } else if (date < this.firstDate) {
                    this.firstDate = date;
                } else {
                    this.secondDate = date;
                }
            }

            this.renderInterval();
            this.executeSelect(this.firstDate, this.secondDate);
        } else {
            this.daysEntries.forEach(e => e.removeClass(s.Selected));
            entry.addClass(s.Selected);
            this.firstDate = entry['AllowedDate'];
            this.executeSelect(this.firstDate);
        }
    }

    selectInterval(firstDate: Date, secondDate?: Date) {
        let hasUpdatedSecondDate = secondDate && !dateDayEquals(secondDate, this.secondDate)
        let hasUpdatedFistDate = !dateDayEquals(firstDate, this.firstDate)

        this.firstDate = firstDate;
        this.secondDate = secondDate;

        this.executeSelect(this.firstDate, this.secondDate);

        if (hasUpdatedFistDate || hasUpdatedSecondDate) {
            this.currentDate = hasUpdatedSecondDate ? secondDate : firstDate;
        }

        this.update();
    }

    focusOnDate(isSecondDate: boolean) {
        this.currentDate = isSecondDate ? this.secondDate : this.firstDate;
        this.update();
    }

    executeSelect(firstDate: Date, secondDate?: Date) {
        if (firstDate != undefined) {
            firstDate = dateToCompare(firstDate);
        }
        if (secondDate != undefined) {
            secondDate = dateToCompare(secondDate, true);
        }

        this.onSelect(firstDate, secondDate);
    }

    /**
     * to override
     */

    onSelect(firstDate: Date, secondDate?: Date) {
    }
}