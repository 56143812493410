import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {AccountTooltipComponent} from "../account/tooltip/AccountTooltipComponent";
import {IAvatarModel} from "../../../../../models/avatar/IAvatarModel";
import {ChannelAvatarComponent} from "../channel/avatar/ChannelAvatarComponent";
import {ContactTooltipComponent} from "../contact/tooltip/ContactTooltipComponent";
import {IAvatarActiveModel} from "../../../../../models/avatar/IAvatarActiveModel";
import {IChannelModel} from "../../../../../models/channel/IChannelModel";
import {IAvatarTooltip} from "./types/IAvatarTooltip";
import * as s from "./avatar.scss";
import {AvatarService} from "../../../../../services/avatar/AvatarService";
import {AvatarTemplate} from "./types/AvatarTemplate";
import {AssignableType} from "../../../../../models/assignment/AssignableType";
import {CapsuleComponent} from "../capsule/CapsuleComponent";
import {ProductType} from "../../../../../models/product/ProductType";
import {config} from "../../../../../config";
import {ProductName} from "../../../../../models/product/ProductName";
import {isMobile} from "../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/IsMobile";

export class AvatarComponent extends Component {
    public tooltipComponent: AccountTooltipComponent | ContactTooltipComponent;
    public tooltipSettings: IAvatarTooltip;

    public avatar: IAvatarModel;
    public activeModel: IAvatarActiveModel;
    public nameActiveModel: IAvatarActiveModel;
    public channel: IChannelModel;

    constructor(avatar: IAvatarModel, tooltipSettings?: IAvatarTooltip) {
        super();
        this.tooltipSettings = tooltipSettings;
        this.avatar = avatar;


        //language=HTML
        this.template = `
            <div class="${s.componentAvatar}"></div>
        `;
    }

    commit() {
        super.commit();

        if (this.tooltipSettings != undefined) {
            let exec = () => {
                if (this.tooltipComponent == undefined) {
                    if (this.tooltipSettings.contentId != "virtualId") {
                        if (this.tooltipSettings.componentClass.name == ContactTooltipComponent.name && config.product == ProductName.toString(ProductType.LIVECHAT)) {
                            return;
                        }

                        this.tooltipComponent = new this.tooltipSettings.componentClass(this, this.tooltipSettings.contentId);
                        this.tooltipComponent.create();
                        this.tooltipComponent.onDispose = () => this.tooltipComponent = undefined;
                    }
                }
            };

            if (isMobile()) {
                this.onClick(() => exec());
            } else {
                this.onHover(() => exec(), 300);
            }
        }
        this.onReactiveObserve(() => this.init());
    }

    init() {
        this.removeAttribute("style");
        if (this.avatar) {
            if (this.avatar && this.avatar.color != undefined) {
                this.setStyle(`background-image:${this.avatar.color.color};background:${this.avatar.color.color}`);
            }


            let template = AvatarTemplate.imageTemplate(this.avatar);

            if (this.nameActiveModel) {
                if (!this.avatar.file && this.avatar.color && this.nameActiveModel.name) {
                    /*
                    template = `${this.draw(new TextResizeableComponent(this.nameActiveModel.name.split(" ").map(value => {
                        return value[0];
                    }).join("").toUpperCase(), "white"))}`;

                     */
                }
            }

            if (this.activeModel) {
                template += this.activeModel.active != undefined ? `<div class="${s.pastille} ${AvatarService.isActive(this.activeModel) ? s.active : s.inactive}"></div>` : ``;
            }

            this.setHtml(template);

            let avatarContainer = this.el(s.avatar);
            if (!avatarContainer.isNull()) {
                avatarContainer.addListener("error", () => {
                    let template = AvatarTemplate.iconDivTemplate(this.avatar);
                    this.setHtml(template);
                });
            }

            if (this.channel != undefined) {
                //language=HTML
                this.append(`
                    <div class="${s.channelContainer}">
                        ${this.draw(new ChannelAvatarComponent(this.channel))}
                    </div>
                `);
            }
        }
    }

    /**
     * active
     */

    setActive(activeModel: IAvatarActiveModel): AvatarComponent {
        if (activeModel != undefined) {
            this.activeModel = activeModel;
            this.nameActiveModel = activeModel;
        }
        return this;
    }

    /**
     * name
     */

    setName(activeModel: IAvatarActiveModel): AvatarComponent {
        this.nameActiveModel = activeModel;

        return this;
    }

    /**
     * channel
     */

    setChannel(channel: IChannelModel): AvatarComponent {
        this.channel = channel;
        return this;
    }

    setType(assignableType: AssignableType) {
        //language=HTML
        let typeContainer = this.append(`
            <div class="${s.typeContainer}"></div>`);

        if (assignableType == AssignableType.CONTACT) {
            typeContainer.render(new CapsuleComponent(ProductType.CONTACTS));
        } else if (assignableType == AssignableType.MAIL) {
            typeContainer.render(new CapsuleComponent(ProductType.MAILS));
        } else if (assignableType == AssignableType.ACCOUNT) {
            typeContainer.append(`<div class="${s.pastilleType}">A</div>`)
        }
    }
}