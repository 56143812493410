import {Component} from "../../sedestral-interface-component/interface/component/Component";
import * as s from "./progressbar.scss";
import {IProgressBar} from "./types/IProgressBar";
import {SedestralMachine} from "../../sedestral-interface-component/machine/SedestralMachine";

export class ProgressBarComponent extends Component {

    public barComponent: Component;
    public p: IProgressBar;

    constructor(p: IProgressBar) {
        super();
        this.p = p;

        //language=HTML
        this.template = `
            <div data-tooltip="${p.tooltip}" class="${s.globalProgressBar} ${p.white ? s.white : ``}">
                <div class="${s.bar}"></div>
                ${p.showValue ? `<div class="${s.percent}">50%</div>` : ``}
            </div>
        `;
    }

    commit() {
        this.barComponent = this.el(s.bar);

        if (this.p.tooltip) {
            super.commitTooltips();
        }
        let width = Math.round(this.p.value * 100 / this.p.maxValue);

        this.barComponent.setStyle(`width:0%;`);
        SedestralMachine.requestFrame()(() => {
            this.barComponent.setStyle(`width:${width}%;transition-duration: ${this.p.animationDelay / 1000}s;`);
        });


        super.commit();
    }
}