import {PanelNetwork} from "../../panel/network/PanelNetwork";
import {Network} from "../../../network/Network";
import {PreferencesService} from "../../../services/preferences/PreferencesService";

export class WebsiteNetwork extends PanelNetwork {

    static async init() {
        await PanelNetwork.init();

        Network.onToken = async (): Promise<boolean> => {
            return true;
        }

        Network.onLogout = async (): Promise<boolean> => {
            return true;
        }

        Network.onLogin = async (): Promise<boolean> => {
            return true;
        }

        Network.lostFunctions = [];
        Network.onNetworkLost(() => {

        });

        Network.lostSolvedFunctions = [];
        Network.onNetworkLostSolved(() => {

        });
    }

    static initServices() {
        PreferencesService.init();
    }
}