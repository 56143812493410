import {Resources} from "../../../resources/Resources";
import {PreferencesService} from "../../../services/preferences/PreferencesService";

/**
 * retourne le format standard d'une date et heure
 */
export function dateFormat(date: Date, hour?: boolean) {
    let options: any = {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
    };
    if (hour) {
        options['hour'] = "2-digit";
        options['minute'] = "2-digit";
        options['hour12'] = PreferencesService.hour12;
    }
    return new Intl.DateTimeFormat(Resources.language, options).format(date);
}

/**
 * retourne le format standard d'une heure
 */
export function dateHourFormat(date: Date) {
    let options: any = {};
    options['hour'] = "2-digit";
    options['minute'] = "2-digit";
    options['hour12'] = PreferencesService.hour12;
    return new Intl.DateTimeFormat(Resources.language, options).format(date);
}