import * as s from "./website-head-submenu-content.scss";
import {Network} from "../../../../../../../network/Network";
import {Resources} from "../../../../../../../resources/Resources";
import {WebsiteHeadSubmenuContentComponent} from "./WebsiteHeadSubmenuContentComponent";

export class WebsiteHeadSubmenuContentPricingComponent extends WebsiteHeadSubmenuContentComponent {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <a href="${Network.router.pages.getUrlWithPath('pricing')}" class="${s.componentWebsiteHeadSubmenuContent}">
                <div class="${s.column}">
                    <h6 class="${s.title}">
                        ${Resources.t("words.pricing")}
                        <span class="${s.arrow}"></span>
                    </h6>
                </div>
            </a>
        `;
    }
}