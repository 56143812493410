@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentContactBans {
  div[scrolltrack] {
    top: 50px;
    height: calc(100% - 110px);
    right: 5px;
  }

  .head {
    font-size: 17px;
    margin-bottom: 12px;

    @include texts.font("semi-bold");

    span {
      background: var(--red);
      color: var(--white);
      font-size: 8px;
      padding: 3px 6px;
      border-radius: 12px;
      top: -2px;
      position: relative;
      display: inline-block;
    }
  }

  .bans {
    height: calc(100% - 75px);
    width: 100%;

    .policeIcon {
      height: 80px;
      width: 80px;

      @include icons.ico("3d-police");
    }
  }

  .buttons {
    margin-top: 10px;

    @include positioning.clear;


    .button {
      background: var(--grey-sur);
      color: var(--black);
      padding: 8px 24px;
      border-radius: 25px;
      width: fit-content;
      float: right;
      font-size: 13px;
      cursor: pointer;

      @include texts.font("bold");

      &:hover {
        background: var(--grey-sur-hover);
      }
    }
  }


  @media screen and (max-width: 480px) {
    .button {
      font-size: 15px !important;
    }
  }
}