import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "../website-content.scss";
import * as r from "./website-content-inbox.scss";
import {WebsiteProductComponent} from "../../product/WebsiteProductComponent";
import {WebsitePresentationLightComponent} from "../../presentation/light/WebsitePresentationLightComponent";
import {WebsitePresentationVerticalComponent} from "../../presentation/vertical/WebsitePresentationVerticalComponent";
import {WebsiteColumnsThreeWhiteComponent} from "../../columns/three/white/WebsiteColumnsThreeWhiteComponent";
import {WebsitePresentationCapsuleComponent} from "../../presentation/capsule/WebsitePresentationCapsuleComponent";
import {ProductType} from "../../../../../../../models/product/ProductType";
import {Network} from "../../../../../../../network/Network";
import {
    WebsiteIssuesComponent
} from "../../issues/WebsiteIssuesComponent";
import {Resources} from "../../../../../../../resources/Resources";

export class WebsiteContentInboxComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent} ${r.componentWebsiteContentInbox}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteProductComponent({
                            title: Resources.t("words.optimize"),
                            text: Resources.t("words.completeSolution"),
                            productType: ProductType.INBOX,
                            url: `${Network.vendor}images/capture-inbox.webp`
                        }))}
                    </div>
                    <div class="${r.separation}"></div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationLightComponent({
                            title: Resources.t("words.sendAndReceive"),
                            subtitle: Resources.t("words.createMails"),
                            illus: "illus-mails-advanced",
                            size: "medium",
                            url: `${process.env.APP_URL}/mail`
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("words.liveChat"),
                            description: Resources.t("words.customizeAndInstall"),
                            image: `${Network.vendor}images/` +"illus-livechat-website.webp",
                            color: "blue",
                            size: "tall"
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("words.replyTo"),
                            description: Resources.t("words.connectNetworks"),
                            image: `${Network.vendor}images/` +"illus-social.svg",
                            color: "red",
                            size: "medium"
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("words.summarize"),
                            description: "",
                            image: `${Network.vendor}images/` +"illus-funnel.svg",
                            color: "black",
                            size: "medium"
                        }))}
                    </div>
                    <div class="${r.collaboration}">
                        <div class="${r.container}">
                            ${this.draw(new WebsitePresentationLightComponent({
                                title: Resources.t("words.defined"),
                                subtitle: Resources.t("words.limited"),
                                illus: "illus-perms",
                                size: "tall"
                            }))}
                        </div>
                        <div class="${s.container}">
                            ${this.draw(new WebsitePresentationVerticalComponent({
                                title: Resources.t("words.workTeam"),
                                description: Resources.t("words.invitedCollaborator"),
                                image: `${Network.vendor}images/` +"illus-content.webp",
                                color: "blue",
                                size: "tall"
                            }))}
                        </div>
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteColumnsThreeWhiteComponent({
                            title: Resources.t("words.personalizeInbox"),
                            columns: [{
                                name: Resources.t("words.createdInbox"),
                                picture: "illus-inbox.webp",
                                state: "normal"
                            }, {
                                name: Resources.t("words.organizeCategroies"),
                                picture: "illus-categories.webp",
                                state: "normal"
                            }, {
                                name: Resources.t("words.filtersTalks"),
                                picture: "illus-conversation.webp",
                                state: "normal"
                            }]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationCapsuleComponent())}
                    </div>
                        <!--<div class="${s.container}">
                        $ {this.draw(new WebsiteMobileComponent())}
                    </div>-->
                    <div class="${s.container}">
                        ${this.draw(new WebsiteIssuesComponent({
                            questions: Resources.t("words.questions.inbox")
                        }))}
                    </div>
                </div>
            </div>
        `;
    }
}