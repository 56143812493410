import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {IPublisherPostModel} from "../../../models/publisher/post/IPublisherPostModel";
import {AccountService} from "../../account/AccountService";
import {SiteChannelService} from "../../site/channel/SiteChannelService";
import {PublisherAttachmentService} from "../attachments/PublisherAttachmentService";
import {PublisherTransmissionService} from "../transmissions/PublisherTransmissionService";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {ProductType} from "../../../models/product/ProductType";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {PublisherPostType} from "../../../models/publisher/post/PublisherPostType";
import {
    ICollaborationEditorSavingPostMetadataModel
} from "../../../models/collaboration/editor/saving/post/ICollaborationEditorSavingPostMetadataModel";
import {WebSocketPanelEventName} from "../../../network/socket/names/WebSocketPanelEventName";
import {IChannelPublisherProperties} from "../../../models/channel/properties/IChannelPublisherProperties";
import {ChannelService} from "../../channel/ChannelService";
import {ChannelPropertiesService} from "../../channel/ChannelPropertiesService";
import {PublisherPostAddChannelType} from "../../../models/publisher/post/PublisherPostAddChannelType";
import {ISiteChannelModel} from "../../../models/site/channel/ISiteChannelModel";
import {
    ICollaborationEditorAttachmentsFileModel
} from "../../../models/collaboration/editor/attachments/ICollaborationEditorAttachmentsFileModel";
import {IPublisherCheckingAttachment} from "../../../models/publisher/types/IPublisherCheckingAttachment";
import {FileService} from "../../file/FileService";
import {Resources} from "../../../resources/Resources";
import {IPublisherError} from "../../../models/publisher/types/IPublisherError";
import {
    numberBytesToMO
} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/NumberBytesToMO";
import {fileIsImage} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/FileIsImage";
import {
    fileVideoData
} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/FileVideoData";
import {
    fileImageData
} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/FileImageData";
import {fileIsGif} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/FileIsGif";
import {fileIsFile} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/FileIsFile";
import {fileIsVideo} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/FileIsVideo";

export class PublisherPostService {
    public static posts: IPublisherPostModel[] = observable([]);
    public static postUpdateEvents: ((post: IPublisherPostModel) => void)[] = [];
    public static postDeleteEvents: ((postId: string) => void)[] = [];

    public static dispose(): void {
        this.posts = observable([]);
        this.postUpdateEvents = [];
        this.postDeleteEvents = [];
    }

    public static init(): void {
        Services.beforeInit(this);
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.PUBLISHER_POST_UPDATE, (post) => {
            post = this.store(post);
            this.postUpdateEvents.forEach(value => value(post));
        });
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.PUBLISHER_POST_UPDATES, (posts) => {
            posts.forEach((post: IPublisherPostModel) => {
                post = this.store(post);
                this.postUpdateEvents.forEach(value => value(post));
            });
        });
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.PUBLISHER_POST_DELETE, (postId) => {
            this.postDeleteEvents.forEach(value => value(postId));
        });
    }

    public static async findById(id: string, component?: INetworkComponent): Promise<IPublisherPostModel> {
        Services.handleErrors(component, [
            {status: HttpStatus.NOT_FOUND, message: "none"}
        ]);

        let request = await Network.get(ProductType.PANEL, `/publisher/post/${id}`, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async create(type: PublisherPostType, scheduledTime: number, component?: INetworkComponent): Promise<IPublisherPostModel> {
        let request = await Network.post(ProductType.PANEL, "/publisher/post/create", {
            type: type,
            scheduledTime: scheduledTime
        }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async publish(postId: string, delta: any, html: string, scheduleTime?: number, component?: INetworkComponent): Promise<IPublisherPostModel> {
        let request = await Network.postJson(ProductType.PANEL, "/publisher/post/publish", {
            postId: postId,
            delta: delta,
            html: html,
            scheduleTime: scheduleTime
        }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async publishCancel(postId: string, component?: INetworkComponent): Promise<IPublisherPostModel> {
        let request = await Network.post(ProductType.PANEL, "/publisher/post/publish/cancel", {
            postId: postId
        }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async publishRetry(postId: string, delta: any, html: string, scheduleTime?: number, component?: INetworkComponent): Promise<IPublisherPostModel> {
        let request = await Network.post(ProductType.PANEL, "/publisher/post/publish/retry", {
            postId: postId
        }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async delete(postId: string, component?: INetworkComponent): Promise<HttpStatus> {
        let request = await Network.delete(ProductType.PANEL, `/publisher/post/${postId}`, component);
        if (request.status == HttpStatus.OK) {
            this.store(request.data);
        }

        return request.status;
    }

    public static async editionMode(postId: string, canceledEditionMode: boolean, component?: INetworkComponent): Promise<IPublisherPostModel> {
        let request = await Network.postJson(ProductType.PANEL, "/publisher/post/editionMode", {
            postId: postId,
            canceledEditionMode: canceledEditionMode
        }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async calendar(dateStart: number, dateEnd: number, component?: INetworkComponent): Promise<IPublisherPostModel[]> {
        let request = await Network.get(ProductType.PANEL, `/publisher/post/calendar/${dateStart}/${dateEnd}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return undefined;
    }

    /**
     * store
     */

    public static storeMetadata(metadata: ICollaborationEditorSavingPostMetadataModel): ICollaborationEditorSavingPostMetadataModel {
        metadata.sitesChannels = SiteChannelService.storeAll(metadata.sitesChannels);
        metadata.participantsAccounts = AccountService.storeAll(metadata.participantsAccounts);

        return metadata;
    }

    public static storeAll(posts: IPublisherPostModel[]): IPublisherPostModel[] {
        for (let key in posts)
            posts[key] = this.store(posts[key], true);

        return Services.storeAll(posts);
    }

    public static store(post: IPublisherPostModel, all?: boolean): IPublisherPostModel {
        let storedPost = this.posts.find(value => value.id == post.id);

        post.participantsAccounts = AccountService.storeAll(post.participantsAccounts);
        post.activeAccounts = AccountService.storeAll(post.activeAccounts);
        if (post.sitesChannels) {
            post.sitesChannels = SiteChannelService.storeAll(post.sitesChannels);
        }
        if (post.attachments) {
            post.attachments = PublisherAttachmentService.storeAll(post.attachments);
        }


        if (post.transmissions) {
            post.transmissions = storedPost && all ? storedPost.transmissions : PublisherTransmissionService.storeAll(post.transmissions);
        }

        return Services.store("id", this.posts, post);
    }

    /**
     * checking
     */

    public static async toCheckingAttachment(id: string, url: string, type: string, size: number): Promise<IPublisherCheckingAttachment> {
        let attachment: IPublisherCheckingAttachment = {
            id: id,
            type: type,
            size: size
        };

        if (fileIsVideo(type)) {
            let data = await fileVideoData(url);
            attachment.duration = data.duration;
            attachment.ratio = data.ratio;
        }

        if (fileIsImage(type) || fileIsGif(type)) {
            let data = await fileImageData(url);
            attachment.ratio = data.ratio;
        }

        return attachment;
    }

    public static async attachmentsToCheckingAttachment(attachments: ICollaborationEditorAttachmentsFileModel[]): Promise<IPublisherCheckingAttachment[]> {
        let checkingAttachments = [];
        for (let attachment of attachments) {
            checkingAttachments.push(await this.toCheckingAttachment(attachment.file.id, FileService.link(attachment.file), attachment.file.extension, attachment.file.size));
        }

        return checkingAttachments;
    }

    public static checkErrors(post: IPublisherPostModel, attachments: IPublisherCheckingAttachment[], textLength: number): IPublisherError[] {
        let errors: IPublisherError[] = [];

        post.sitesChannels.forEach(siteChannel => {
            let properties = siteChannel.channel.publisherProperties[post.type];
            if (properties) {
                let texts = [];
                let attachmentsIds = [];

                if (attachments.length > 0) {
                    let types = new Set();

                    let gifCount = 0;
                    let videoCount = 0;
                    let photoCount = 0;
                    let documentCount = 0;

                    attachments.forEach(attachment => {
                        let type;

                        if (fileIsVideo(attachment.type)) {
                            if (properties.maxLengthVideo == 0) {
                                texts.push(Resources.t("words.publisherErrorVideo"));
                            } else {
                                type = "video";
                                videoCount++;

                                if (!properties.extensionsVideo.includes(attachment.type)) {
                                    texts.push(Resources.r("words.publisherErrorFileType", {types: properties.extensionsVideo.join(", ")}));
                                } else {
                                    if (videoCount > properties.maxLengthVideo) {
                                        texts.push(Resources.r("words.publisherErrorVideoLength", {videos: properties.maxLengthVideo}));
                                    }
                                    if (attachment.duration > properties.maxDurationVideo || attachment.duration < properties.minDurationVideo) {
                                        texts.push(Resources.r("words.publisherErrorVideoDuration", {
                                            min: properties.minDurationVideo,
                                            max: properties.maxDurationVideo
                                        }));
                                    }

                                    if (attachment.size > properties.maxSizeVideo) {
                                        texts.push(Resources.r("words.publisherErrorSize", {size: Math.round(numberBytesToMO(properties.maxSizeVideo))}));
                                    }
                                }
                            }
                        } else if (fileIsImage(attachment.type) || fileIsGif(attachment.type)) {
                            if (properties.maxLengthPhoto == 0) {
                                switch (post.type) {
                                    case PublisherPostType.REEL:
                                        texts.push(Resources.t("words.publisherErrorNoImageReel"));
                                        break;
                                    default:
                                        texts.push(Resources.t("words.publisherErrorNoImage"))
                                        break;
                                }
                            } else {
                                type = "photo";
                                photoCount++;

                                if (photoCount > properties.maxLengthPhoto) {
                                    texts.push(Resources.r("words.publisherErrorImageLength", {maxLengthPhoto: properties.maxLengthPhoto}));
                                } else {
                                    if (!properties.extensionsPhoto.includes(attachment.type)) {
                                        texts.push(Resources.r("words.publisherErrorFileType", {types: properties.extensionsPhoto.join(", ")}));
                                    } else {
                                        if (properties.maxRatioPhoto > 0 && (attachment.ratio < properties.minRatioPhoto || attachment.ratio > properties.maxRatioPhoto)) {
                                            texts.push(Resources.r("words.publisherErrorRatio", {ratio: `${properties.minRatioPhoto}:${properties.maxRatioPhoto}`}));
                                        }
                                        if (attachment.size > properties.maxSizePhoto) {
                                            texts.push(Resources.r("words.publisherErrorSize", {size: Math.round(numberBytesToMO(properties.maxSizePhoto))}));
                                        }
                                    }
                                }
                            }

                            if (fileIsGif(attachment.type)) {
                                if (properties.maxGif == 0) {
                                    texts.push(Resources.t("words.publisherErrorGif"));
                                } else {
                                    gifCount++;
                                    if (gifCount > properties.maxGif) {
                                        texts.push(Resources.r("words.publisherErrorGifLength", {maxGif: properties.maxGif}));
                                    }
                                }
                            }
                        } else if (fileIsFile(attachment.type)) {
                            if (properties.maxLengthDocument == 0) {
                                texts.push(Resources.t("words.publisherErrorFile"));
                            } else {
                                type = "file";
                                documentCount++;
                                if (!properties.extensionsDocument.includes(attachment.type)) {
                                    texts.push(Resources.r("words.publisherErrorFileType", {types: properties.extensionsDocument.join(", ")}));
                                } else {
                                    if (documentCount > properties.maxLengthDocument) {
                                        texts.push(Resources.r("words.publisherErrorDocumentLength", {documents: properties.maxLengthDocument}));
                                    }
                                    if (attachment.size > properties.maxSizeDocument) {
                                        texts.push(Resources.r("words.publisherErrorSize", {size: Math.round(numberBytesToMO(properties.maxSizeDocument))}));
                                    }
                                }
                            }
                        } else {
                            texts.push(Resources.r("words.publisherErrorFileType", {
                                types: [...properties.extensionsVideo.join(", "), ...properties.extensionsPhoto.join(", "), ...properties.extensionsDocument.join(", ")]
                            }));
                        }

                        if (texts.length > 0) {
                            attachmentsIds.push(attachment.id);
                        }

                        if (type) {
                            types.add(type);
                        }
                    });

                    if (properties.attachmentsCombination && types.size > properties.maxLengthAttachmentsCombination) {
                        texts.push(Resources.r("words.publisherErrorCombinationMany", {size: properties.maxLengthAttachmentsCombination}));
                    }

                    if (!properties.attachmentsCombination && types.size > 1) {
                        texts.push(Resources.t("words.publisherErrorCombination"));
                    }
                } else {
                    if (properties.attachmentMandatory) {
                        texts.push(Resources.t("words.publisherErrorAttachmentMandatory"));
                    } else if (textLength == 0 && attachments.length == 0) {
                        texts.push(Resources.t("words.publisherErrorEmpty"));
                    }
                }

                if (textLength > properties.maxLengthMessage) {
                    texts.push(Resources.r("words.publisherErrorText", {size: properties.maxLengthMessage}));
                }

                if (texts.length > 0) {
                    errors.push({siteChannel: siteChannel, texts: texts, attachmentIds: attachmentsIds});
                }
            }
        });

        return errors;
    }

    /**
     * get
     */
    public static getMergeProperties(siteChannels: ISiteChannelModel[], type: PublisherPostType): IChannelPublisherProperties {
        let uniqueChannelIds = new Set(siteChannels.map(siteChannel => siteChannel.channelId));
        let uniqueChannels = Array.from(uniqueChannelIds)
            .map(channelId => ChannelService.findById(channelId).publisherProperties[type])
            .filter(properties => properties !== undefined);

        return ChannelPropertiesService.mergeInboxProperties(uniqueChannels);
    }

    /**
     * events
     */

    public static onUpdatePost(func: ((post: IPublisherPostModel) => void), component: INetworkComponent) {
        this.postUpdateEvents.push(func);
        component.onRemove(() => this.postUpdateEvents.splice(this.postUpdateEvents.indexOf(func), 1));
    }

    public static onDeletePost(func: ((postId: string) => void), component: INetworkComponent) {
        this.postDeleteEvents.push(func);
        component.onRemove(() => this.postDeleteEvents.splice(this.postDeleteEvents.indexOf(func), 1));
    }
}