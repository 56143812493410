import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-presentation-capsule.scss";
import {Network} from "../../../../../../../network/Network";
import {Resources} from "../../../../../../../resources/Resources";
import {CapsuleComponent} from "../../../../../../panel/components/components/capsule/CapsuleComponent";
import {ProductType} from "../../../../../../../models/product/ProductType";

export class WebsitePresentationCapsuleComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsitePresentationCapsule}">
                <h2 class="${s.description}">
                    ${Resources.t("words.customizeInbox")}
                </h2>
                <div class="${s.icon}">
                    ${this.draw(new CapsuleComponent(ProductType.STATISTICS))}
                </div>
                <div class="${s.columns}">
                    <div class="${s.column}">
                        <img src="${Network.vendor}images/illus-number-conversation.webp"
                             alt="${Resources.t("words.statisticsNumberConversations")}"
                             height="248.54"
                             width="354.66"
                        />
                    </div>
                    <div class="${s.column}">
                        <img src="${Network.vendor}images/illus-delay-answer.webp"
                             alt="${Resources.t("words.statisticsResponseTime")}"
                             height="248.54"
                             width="354.66"
                        />
                    </div>
                    <div class="${s.column}">
                        <img src="${Network.vendor}images/illus-conversation-hours.webp"
                             alt="${Resources.t("words.statisticsHours")}"
                             height="248.54"
                             width="354.66"
                        />
                    </div>
                </div>
                <div class="${s.image}">
                    <img src="${Network.vendor}images/capture-collaboraters.webp"
                         alt="${Resources.t("words.classificationEmployees")}"
                         height="582.19"
                         width="1035"
                    />
                </div>
            </div>
        `;
    }
}