import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {IContactModel} from "../../../../../../models/contact/IContactModel";
import * as s from "./contact-bans.scss";
import {ContactBanCreatorTooltipComponent} from "./creator/tooltip/ContactBanCreatorTooltipComponent";
import {
    LoaderLightComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import {ContactBanService} from "../../../../../../services/contact/ban/ContactBanService";
import {ContactBanEntryComponent} from "./entry/ContactBanEntryComponent";
import {
    EmptyBasicComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/empty/EmptyBasicComponent";
import {IContactBanModel} from "../../../../../../models/contact/ban/IContactBanModel";
import {Resources} from "../../../../../../resources/Resources";

export class ContactBansComponent extends Component {

    public blockButton: Component;
    public contact: IContactModel;

    public bansContainer: Component;

    public bans: IContactBanModel[];

    constructor(contact: IContactModel) {
        super();
        this.contact = contact;
        //language=HTML
        this.template = `
            <div class="${s.componentContactBans}">
                <div class="${s.head}">
                    ${Resources.t('words.sanctionsTitle')}
                    <span style="${contact.banned ? `` : `display:none;`}">
                ${Resources.t('words.blockedText')}
            </span>
                </div>
                <div class="${s.bans}">
                    ${this.draw(new LoaderLightComponent())}
                </div>
                <div class="${s.buttons}">
                    <div class="${s.button}">
                        ${Resources.t('words.blockContactButtonText')}
                    </div>
                </div>
            </div>
        `;

    }

    commit() {
        this.bansContainer = this.el(s.bans);
        this.blockButton = this.el(s.button);

        let tooltip;
        this.blockButton.onClick(() => {
            if (tooltip != undefined && !tooltip.isNull()) {
                tooltip.dispose();
            } else {
                tooltip = new ContactBanCreatorTooltipComponent(this.blockButton, this.contact);
                tooltip.creator.onCreate = async (date, message) => {
                    tooltip.creator.buttonsComponent.setLoading();
                    let request = await ContactBanService.create(this.contact.id, message, date.getTime());
                    if (request != undefined) {
                        this.bans.unshift(request);
                        this.renderBans(this.bans);
                    }

                    tooltip.dispose();
                    this.requireDispose();
                    this.onStateChange();
                }

                tooltip.create();
            }
        });

        this.init();
        super.commit();
    }

    async init() {
        this.bans = await ContactBanService.findByContact(this.contact.id, this);
        this.renderBans(this.bans);
    }

    renderBans(bans: IContactBanModel[]) {
        this.bansContainer.clearAll();
        if (bans.length > 0) {
            this.bansContainer.scrollable();
            bans.forEach(ban => {
                let component = new ContactBanEntryComponent(ban);
                component.onDelete = () => {
                    this.bans.splice(this.bans.indexOf(ban), 1);
                    this.renderBans(this.bans);
                    if (this.bans.length == 0) {
                        this.requireDispose();
                    }

                    this.onStateChange();
                }

                this.bansContainer.render(component);
            });
        } else {
            this.bansContainer.render(new EmptyBasicComponent(`<div class="${s.policeIcon}"></div>`, Resources.t('words.noSanctionMessage')));

        }
    }

    /**
     * events
     */

    onStateChange() {

    }

    requireDispose() {

    }
}