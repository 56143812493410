import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {AvatarComponent} from "../AvatarComponent";
import * as s from "./avatar-prompt.scss";
import {
    VisualFilePickerComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/file/VisualFilePickerComponent";
import {IAvatarModel} from "../../../../../../models/avatar/IAvatarModel";
import {Network} from "../../../../../../network/Network";
import {Resources} from "../../../../../../resources/Resources";
import {
    fileToBase64
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/FileToBase64";

export class AvatarPromptComponent extends Component {

    public avatar: IAvatarModel;
    public noDelete: boolean;

    public avatarComponent: AvatarComponent;
    public avatarContainer: Component;

    public editButton: Component;
    public lastFile: File;

    constructor(avatar?: IAvatarModel, noDelete?: boolean) {
        super();
        this.noDelete = noDelete;
        this.avatar = avatar;
        //language=HTML
        this.template = `
            <div class="${s.componentAvatarPrompt}">
                <div class="${s.avatarContainer}"></div>
                <div class="${s.editButton}">
                    <div class="${s.icon}"></div>
                </div>
            </div>
        `;
    }

    commit() {
        this.editButton = this.el(s.editButton);
        this.avatarContainer = this.el(s.avatarContainer);

        let picker = new VisualFilePickerComponent({images: true, gifs: true, multiple: false});
        this.editButton.render(picker);

        picker.error = value => Network.router.static.components.notifications.notify(Resources.t('words.warning'), value, 8000);
        picker.onImageAndGifPick = file => {
            fileToBase64(file, (data) => {
                this.renderAvatar({
                    url: data
                });
            });

            this.lastFile = file;
            this.onPick(file);
        }

        if (!this.noDelete)
            this.renderDelete();
        this.renderAvatar(this.avatar);
        super.commit();
    }

    renderAvatar(avatar: IAvatarModel) {
        this.avatarComponent = new AvatarComponent(avatar == undefined ? {
            id: "",
            color: {color: "rgb(255 43 43)"},
            type: 1
        } : avatar);

        this.avatarContainer.clearAll();
        this.avatarContainer.render(this.avatarComponent);
    }

    renderDelete() {
        //language=HTML
        let button = this.append(`
            <div class="${s.editButton} ${s.delete}">
                <div class="${s.icon}"></div>
            </div>
        `);

        button.onClick(() => this.onDelete());
    }

    /**
     * class
     */

    classMini() {
        this.addClass(s.mini);
    }

    onDelete() {

    }

    onPick(file: File) {

    }
}