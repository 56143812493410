import {Resources} from "../resources/Resources";
import {Network} from "./Network";
import {IWebsiteReview} from "../products/website/components/components/content/reviews/types/IWebsiteReview";

export class NetworkVendor {

    public static images: { lang: string, name: string }[] = []

    public static image(name: string): string {
        let lang = Resources.language;

        if (this.images.find(value => {
            return value.lang == lang && value.name == name;
        })) {
            return `${Network.vendor}images/${lang}/${name}`;
        } else {
            return `${Network.vendor}images/en/${name}`;
        }
    }

    public static illus(name: string) {
        return `${Network.vendor}images/${name}`;
    }

    public static customer(name: string) {
        return `${Network.vendor}images/customers/${name}`;
    }

    public static reviews(type?: string): IWebsiteReview[] {
        return [{
            platform: "Shopify App Store",
            platformIcon: "shopify",
            content: Resources.t("words.shopifyReview1"),
            avatarUrl: NetworkVendor.customer("blog-gourmet.svg"),
            avatarName: "Le Coq Gourmet",
            avatarDescription: Resources.t("words.ecommerceSite"),
            stars: 5,
            datePublished: "2024-09-03",
            productName: "SmartBlog"
        }, {
            platform: "Google",
            platformIcon: "google",
            content: Resources.t("words.googleReview1"),
            avatarUrl: NetworkVendor.customer("blog-dylan.jpg"),
            avatarName: "Dylan DELIQUE",
            avatarDescription: Resources.t("words.realEstateAdvisor"),
            stars: 5,
            datePublished: "2024-06-17",
            productName: "SmartBlog"
        }];
    }
}