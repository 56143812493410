import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {IOfferProductModel} from "../../../models/offer/product/IOfferProductModel";
import {ProductType} from "../../../models/product/ProductType";

export class OfferProductService {
    public static products: IOfferProductModel[] = observable([]);

    public static dispose(): void {
        this.products = observable([]);
    }

    public static init(): void {

    }

    /**
     * store
     */

    public static storeAllObject(products?: { [K in ProductType]: IOfferProductModel }): { [K in ProductType]: IOfferProductModel } {
        if (products) {
            Object.keys(products).forEach(key => {
                products[key] = this.store(products[key]);
            });

            return products;
        }
    }

    public static storeAll(products: IOfferProductModel[]): IOfferProductModel[] {
        for (let key in products)
            products[key] = this.store(products[key]);

        return Services.storeAll(products);
    }

    public static store(product: IOfferProductModel): IOfferProductModel {
        product = Services.store("id", this.products, product);
        return product;
    }
}