import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {
    ProgressBarComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/progressbar/ProgressBarComponent";
import * as s from "./offer-blocked.scss";
import {OfferProductSolutionType} from "../../../../../../models/offer/product/solution/OfferProductSolutionType";
import {ProductType} from "../../../../../../models/product/ProductType";
import {OfferModalComponent} from "../modal/OfferModalComponent";
import {Resources} from "../../../../../../resources/Resources";

export class OfferBlockedComponent extends Component {
    constructor(currentQuantity: number, limitQuantity: number, solutionType: OfferProductSolutionType, productType: ProductType) {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentOfferBlocked}">
                <div class="${s.description}">
                    ${Resources.t("words.offerBlockedDesc")}
                </div>
                <div class="${s.progress}">
                    ${this.draw(new ProgressBarComponent({
                        animationDelay: 500,
                        maxValue: limitQuantity,
                        value: currentQuantity,
                        tooltip: `${currentQuantity}/${limitQuantity}`
                    }))}
                </div>
                <div class="${s.button}">
                    ${Resources.t("words.offerBlockedButton")}
                </div>
                <div class="${s.icon}"></div>
            </div>
        `;
    }

    commit() {
        this.el(s.button).onClick(() => {
            let offerModal = new OfferModalComponent(ProductType.BLOG);
            offerModal.screenRender();
        });

        super.commit();
    }

}