import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./statistics-card-simple.scss";
import {IStatisticCardSimple} from "./types/IStatisticCardSimple";

export class StatisticsCardSimpleComponent extends Component {
    constructor(statistics: IStatisticCardSimple) {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentStatisticsCardSimple}">
                <div class="${s.statistic}">
                    <div class="${s.text}">
                        ${statistics.firstReactive}&nbsp;${statistics.firstName}
                    </div>
                </div>
                <div style="border:none;" class="${s.statistic}">

                    <div class="${s.text}">
                        ${statistics.firstReactive}&nbsp;${statistics.secondName}
                    </div>
                </div>
            </div>
        `;
    }
}