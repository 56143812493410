@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";

.componentContactBanEntry {
  border: 2px solid var(--grey-sur);
  border-radius: 14px;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;

  &:hover {
    .head {
      .delete {
        visibility: visible;
      }
    }
  }

  .head {
    margin-bottom: 8px;

    @include positioning.clear;

    .delete {
      position: absolute;
      height: 22px;
      width: 22px;
      border-radius: 100%;
      right: 10px;
      background: var(--grey-sur);
      visibility: hidden;

      @include buttons.round;
      @include positioning.flex-globally;

      &:hover {
        background: var(--grey-sur-hover);
      }

      .icon {
        height: 12px;
        width: 12px;

        @include icons.ico("black-delete-light");
      }
    }

    .avatarContainer {
      height: 20px;
      width: 20px;
      border-radius: 100%;
      float: left;
      position: relative;
    }

    .contactName {
      float: left;
      font-size: 14px;
      margin-left: 5px;
      margin-top: 1px;

      @include texts.font("bold");
    }

    .badge {
      float: right;
      background: var(--red);
      color: var(--white);
      font-size: 8px;
      padding: 3px 6px;
      border-radius: 12px;
      margin-top: 1px;
    }
  }

  .comment {
    font-size: 13px;
    border-bottom: 1px solid var(--grey-sur);
    padding-bottom: 5px;
    margin-bottom: 8px;
  }

  .dates {
    .date {
      font-size: 12px;

      &:first-child {
        margin-bottom: 5px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .contactName {
      font-size: 16px !important;
      margin-top: 0 !important;;
    }

    .date {
      font-size: 14px !important;
    }
  }
}