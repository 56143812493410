import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-black-steps.scss";
import {IWebsiteBlackSteps, IWebsiteBlackStepsButton} from "./types/IWebsiteBlackSteps";

export class WebsiteBlackStepsComponent extends Component {

    public steps: IWebsiteBlackSteps;

    public buttonsContainer: Component;
    public contentContainer: Component;

    public contentTimeout: any;
    public contentProgressInterval: any;
    public contentButton: IWebsiteBlackStepsButton;
    public buttons: IWebsiteBlackStepsButton[];

    constructor(steps: IWebsiteBlackSteps) {
        super();
        this.steps = steps;
        this.buttons = [];

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteBlackSteps}">
                <div class="${s.bottomShadow}"></div>

                <div class="${s.top}">
                    <div class="${s.topShadow}"></div>
                    <div class="${s.title}">
                        <div class="${s.icon} ${s[steps.icon]}"></div>
                        <div class="${s.value}">
                            ${steps.title}
                        </div>
                    </div>
                    <div class="${s.buttons}"></div>
                </div>
                <div class="${s.content}"></div>
            </div>
        `;
    }

    commit() {
        this.buttonsContainer = this.el(s.buttons);
        this.contentContainer = this.el(s.content);

        this.steps.contents.forEach(content => {
            //language=HTML
            let component = this.buttonsContainer.append(`
                <div class="${s.button}">
                    <div class="${s.progress}"></div>
                    <div class="${s.name}">${content.name}</div>
                </div>
            `);

            let progress = component.el(s.progress);
            let button = {button: component, progress: progress, content: content};

            this.buttons.push(button);
            component.onClick(() => this.renderContent(button));
        });

        this.renderContent(this.buttons[0]);

        super.commit();
    }

    renderContent(button: IWebsiteBlackStepsButton) {
        if (this.contentButton) {
            this.contentButton.progress.setStyle(`width:0%;transition:0s;`);
            clearTimeout(this.contentProgressInterval);
        }

        this.contentContainer.clearAll();
        clearTimeout(this.contentTimeout);
        this.contentButton = button;

        switch (this.contentButton.content.type) {
            case "img":
                this.contentContainer.append(`<img class="${s.image}" src="${this.contentButton.content.path}"/>`);
                break;
            case "video":
                //language=HTML
                let video = this.contentContainer.append(`
                    <video autoplay loop muted playsinline style="width: 100%;">
                        <source src="${this.contentButton.content.path}" type="video/mp4"/>
                    </video>`);
                video.getHTMLElement().onloadedmetadata = () => {
                    this.contentContainer.setStyle(`height: ${this.contentContainer.getHeight()}px;`);
                    let contentSizeTimeout;
                    this.contentContainer.putListener(window, "resize", () => {
                        clearTimeout(contentSizeTimeout);
                        this.contentContainer.setStyle(`height: unset;`);
                        contentSizeTimeout = this.timeOut(() => {
                            this.contentContainer.setStyle(`height: ${this.contentContainer.getHeight()}px;`);
                        }, 1000);
                    });
                }
                break;
        }

        let width = 0;
        this.contentProgressInterval = this.interval(() => {
            width += 0.5;
            this.contentButton.progress.setStyle(`width:${width}%;transition:0s;`);
        }, button.content.duration / 200);


        this.contentTimeout = this.timeOut(() => {
            let currentIndex = this.buttons.indexOf(this.contentButton);
            let nextIndex = currentIndex + 1;

            if ((this.buttons.length - 1) >= nextIndex) {
                this.renderContent(this.buttons[nextIndex]);
            } else {
                this.renderContent(this.buttons[0]);
            }

        }, this.contentButton.content.duration);
    }

}