.componentWebsiteImageSkewed {
  width: 100%;
  height: auto;
  top: 0;
  object-fit: contain;
  border-radius: 15px;

  &.intermediate {
    box-shadow: 0 0 24.53px var(--black-alpha-middle);
  }

  &.discreet {
    box-shadow: 0 4.24 52.97px var(--black-alpha-middle);
  }

  &.important {
    box-shadow: 0 0 73.91px var(--black-alpha-middle);
  }
}