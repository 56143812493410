import {
    VisualClickTooltipWhiteComponent
} from "../../../../sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import {DatePickerComponent} from "../DatePickerComponent";
import {IDatePicker} from "../types/IDatePicker";
import * as s from "./date-picker-tooltip.scss";
import {Resources} from "../../../../../resources/Resources";

export class DatePickerTooltipComponent extends VisualClickTooltipWhiteComponent {

    public picker: DatePickerComponent;

    constructor(component: Component, settings?: IDatePicker) {
        super(component, 200, 260);
        this.picker = new DatePickerComponent(settings);
        //language=HTML
        this.template = `
            <div class="${s.componentDatePickerTooltip}">
                ${this.draw(this.picker)}
                <div class="${s.buttons}">
                    <div class="${s.button} ${s.validButton}">
                        ${Resources.t("words.validate")}
                    </div>
                    <div class="${s.button} ${s.cancelButton}">
                        ${Resources.t("words.cancel")}
                    </div>
                </div>
            </div>
        `;

        this.mobileStyle(true);
    }


    commit() {
        this.el(s.validButton).onClick(() => this.onValid(this.picker.firstDate));
        this.el(s.cancelButton).onClick(() => this.dispose());

        super.commit();
    }

    onValid(date: Date) {

    }

}