@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsiteHeadSubmenuContent {
  display: flex;
  flex-direction: row;
  gap: 30px;

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;

    .start {
      border-radius: 14px;
      background: var(--web-grad-blue);
      font-size: 16px;
      color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 32px;
      height: fit-content;
      cursor: pointer;

      @include texts.font("semi-bold");

      &.login {
        background: var(--grey-sur);
        color: black;

        &:hover {
          background: var(--grey-sur-hover);
        }
      }

      &:hover {
        background: var(--web-blue-hover);

        .icon {
          transform: translateX(8px);
        }
      }

      .content {
        display: flex;
        gap: 15px;
        position: relative;
        align-items: center;

        .icon {
          height: 16px;
          width: 16px;
          filter: var(--icon-white);
          transition: transform 0.1s ease-in-out;

          @include icons.ico("black-right-arrow");
        }
      }
    }
  }


  .column {
    width: 50%;

    &.open {
      .arrow {
        transform: rotate(90deg);
      }
    }

    .title {
      margin-bottom: 10px;
      font-size: 14px;
      padding: 0 12px !important;
      margin-top: 10px;
      text-align: left;
      color: var(--grey-text-dark);
      font-weight: unset;
      text-transform: uppercase;

      .arrow {
        transition: 0.1s;
        height: 16px;
        width: 16px;
        margin-left: auto;
        display: none;

        @include icons.ico("black-right-bold");
      }
    }

    .cases {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .case {
        display: flex;
        flex-direction: row;
        gap: 20px;
        border-radius: 20px;
        cursor: pointer;
        padding: 12px;

        &.inbox, .livechat, .mail, .statistics, .helpcenter, .contacts, .serviceClient {
          visibility: visible;
        }

        &:hover {
          background: var(--grey-sur);
        }

        .capsuleContainer {
          margin-top: 4px;
          min-width: 42px;
          height: 42px;
          width: 42px;
          display: flex;
          justify-content: center;
          align-items: center;

          &.grey {
            background: var(--grey-sur);
            border-radius: 100%;
          }

          .icon {
            height: 24px;
            width: 24px;

            &.ai {
              @include icons.ico("black-lineal-ai");
            }

            &.ai {
              @include icons.ico("black-lineal-ai");
            }

            &.seo {
              @include icons.ico("black-lineal-target");
            }

            &.statistics {
              @include icons.ico("black-chart");
            }

            &.autopilot {
              @include icons.ico("black-ai-stars");
            }

            &.wix {
              @include icons.ico("black-wix");
            }

            &.prestashop {
              @include icons.ico("colored-prestashop");
            }

            &.wordpress {
              @include icons.ico("colored-wordpress");
            }

            &.shopify {
              @include icons.ico("colored-shopify");
            }
          }
        }

        .texts {
          text-align: left;
          visibility: visible;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 5px;

          .caseTitle {
            color: var(--black);
            font-size: 17px;

            @include texts.font("semi-bold");
          }

          .description {
            font-size: 13px;
            color: var(--black);

            @include texts.font("medium");

            strong {
              font-weight: unset;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 930px) {
    padding: 0;
    display: block;

    .column {
      width: 100%;

      .title {
        color: var(--black);
        border-bottom: 2px dashed var(--grey-sur);
        height: 40px;
        display: flex;
        margin-bottom: unset;
        margin-top: unset;
        align-items: center;
        font-size: 18px;
        cursor: pointer;

        &:hover {
          background: var(--grey-sur);
        }

        .arrow {
          display: block;
        }
      }

      .cases {
        margin-top: 5px;
        display: none;
      }
    }
  }
}