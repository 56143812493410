@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/boxs";

.componentCustomerPopup {
  position: fixed;
  z-index: 9999;
  width: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 15px;

  .box {
    width: 100%;
    transform: translateY(500px) scale(0.8);
    max-width: 700px;
    pointer-events: all;
    background: var(--white);
    padding: 20px;

    @include boxs.sur;

    .head {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      gap: 10px;

      .title {
        font-size: 21px;

        @include texts.font("semi-bold");
      }

      .close {
        height: 30px;
        width: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        border-radius: 100%;

        &:hover {
          background: var(--grey-sur);
        }

        .icon {
          height: 18px;
          width: 18px;

          @include icons.ico("black-close");
        }
      }
    }

    .content {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 10px;

      .icon {
        height: 70px;
        flex: 0 0 70px;
        width: 70px;

        @include icons.ico("3d-confetti");
      }

      .text {
        font-size: 17px;

        @include texts.font("semi-bold");
      }
    }

    .cases {
      display: flex;
      gap: 10px;

      .case {
        height: 107px;
        width: 160px;
        border: 1px solid var(--grey-sur);
        padding: 5px;
        border-radius: 14px;
        margin-bottom: 10px;

        &.context {
          .icon {
            @include icons.ico("3d-context");
          }
        }

        &.style {
          .icon {
            @include icons.ico("3d-style");
          }
        }

        &.ai {
          .icon {
            @include icons.ico("3d-ai");
          }
        }

        .head {
          display: flex;
          align-items: center;
          font-size: 12px;
          gap: 6px;
          border-radius: 100%;
          margin-bottom: 6px;

          @include texts.font("semi-bold");

          .round {
            height: 21px;
            width: 21px;
            background: var(--grey-sur);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;

            .icon {
              height: 12px;
              width: 12px;
            }
          }
        }

        .text {
          font-size: 12px;
          color: var(--grey-text-very-dark);
        }
      }
    }

    .button {
      width: fit-content;
      margin-left: auto;
      background: var(--black);
      color: var(--white);
      display: flex;
      align-items: center;
      padding: 12px 20px;
      border-radius: 24px;
      gap: 10px;
      transition: 0.1s;

      &:hover {
        opacity: 0.8;

        .icon {
          margin-left: 10px;
        }
      }

      .icon {
        height: 14px;
        width: 14px;
        filter: var(--icon-white);
        transition: 0.1s;

        @include icons.ico("black-right-arrow");
      }
    }
  }

  @media (max-width: 480px) {
    .box {
      .cases {
        display: none;
      }
    }
  }
}