/**
 * Retourne les codes de mois entre deux dates
 */

export function dateMonthsCountBetweenDates(timestamp1: number, timestamp2: number): number {
    // Création des objets Date à partir des timestamps
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);

    // Calcul des composants année et mois
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();
    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();

    // Calcul de la différence en mois
    return (year2 - year1) * 12 + (month2 - month1);
}