export function loadScript(url: string, callback?: () => void): void {
    const script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {  // Pour les anciens navigateurs IE
        script.onreadystatechange = function() {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                if (callback) callback();
            }
        };
    } else {  // Pour tous les autres navigateurs
        script.onload = function() {
            if (callback) callback();
        };
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
}