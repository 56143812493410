import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./site.scss";
import {AvatarComponent} from "../avatar/AvatarComponent";
import {ISiteModel} from "../../../../../models/site/ISiteModel";

export class SiteComponent extends Component {
    constructor(site: ISiteModel, checked?: boolean) {
        super();
        //language=HTML
        this.template = `
            <div site class="${s.componentSite}">
                <div class="${s.avatarContainer}">
                    ${this.draw(new AvatarComponent(site.avatar))}
                    ${site.notificationsCount > 0 ? `<div class="${s.notifs}">${site.notificationsCount}</div>` : ``}
                </div>
                <div class="${s.content}">
                    <div class="${s.name}">
                        <div class="${s.text}">
                            ${this.reactive(() => site.name)}
                        </div>
                    </div>
                    <div class="${s.statistics}">
                        <div class="${s.statistic}">
                            <div class="${s.icon} ${s.users}"></div>
                            <div class="${s.value}">
                                ${site.accountsCount}
                            </div>
                        </div>
                        <div class="${s.statistic}">
                            <div class="${s.icon} ${s.members}"></div>
                            <div class="${s.value}">
                                ${site.contactsCount}
                            </div>
                        </div>
                    </div>
                </div>
                ${checked ? `<div class="${s.checked}"></div>` : ``}
            </div>
        `;
    }
}