import {
    dateMonthsCountBetweenDates
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/DateMonthsCountBetweenDates";
import {Resources} from "../../../../../../resources/Resources";
import {OfferPeriodType} from "../../../../../../models/offer/product/OfferPeriodType";
import {IOfferDetailsModel} from "../../../../../../models/offer/IOfferDetailsModel";
import {IOfferProductModel} from "../../../../../../models/offer/product/IOfferProductModel";

export function offerAdvantage(details: IOfferDetailsModel, product: IOfferProductModel): IOfferAdvantage {
    if (details.payment) {
        let advantages = details.payment.nextAdvantages?.filter(value => value.productTemplateId == product.template.id);
        let advantagePercentage = 0;
        let advantageMonths = 0;

        if (advantages && advantages.length > 0) {
            advantagePercentage = advantages[0].advantagePercentage * 100;
            advantageMonths = dateMonthsCountBetweenDates(advantages[0].startDate, advantages[0].endDate) - 1;
        }

        let advantageText = advantagePercentage > 0 ? `${advantagePercentage}% ${Resources.t("words.offerAdvantageNow")}` : ``;
        if (details.offer.advantageCode && details.offer.periodType == OfferPeriodType.ANNUAL) {
            advantageText = Resources.t("words.offerAdvantageYearApplied");
        }

        return {
            advantageMonths: advantageMonths,
            advantagePercentage: advantagePercentage,
            advantageText: advantageText
        }
    }
}

export interface IOfferAdvantage {
    advantageMonths: number,
    advantagePercentage: number,
    advantageText: string
}