import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {IPublisherAttachmentModel} from "../../../models/publisher/attachment/IPublisherAttachmentModel";
import {PublisherTransmissionService} from "../transmissions/PublisherTransmissionService";
import {AccountService} from "../../account/AccountService";
import {AttachmentType} from "../../../models/enums/AttachmentType";
import {FileService} from "../../file/FileService";
import {IFileModel} from "../../../models/file/IFileModel";
import {PreviewService} from "../../preview/PreviewService";
import {IPreviewModel} from "../../../models/preview/IPreviewModel";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {ProductType} from "../../../models/product/ProductType";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {
    IPublisherAttachmentResponseModel
} from "../../../models/publisher/attachment/IPublisherAttachmentResponseModel";

export class PublisherAttachmentService {
    public static attachments: IPublisherAttachmentModel[] = observable([]);

    public static dispose(): void {
        this.attachments = observable([]);
    }

    public static init(): void {

    }

    public static async createFiles(postId: string, files: File[], progress?: (progress: number) => void, component?: INetworkComponent): Promise<IPublisherAttachmentResponseModel> {
        let request = await Network.postFormData(ProductType.PANEL, `/publisher/attachment/files/create`, {
            files: files,
            postId: postId
        }, component, {
            onUploadProgress: (progressEvent) => {
                progress(progressEvent.loaded * 100 / progressEvent.total);
            }
        });

        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    /*
    public static async create(postId: string, attachmentType: AttachmentType, content: string, component?: INetworkComponent): Promise<IPublisherAttachmentResponseModel> {
        let request = await Network.post(ProductType.PANEL, "/publisher/attachment/createOther", {
            postId: postId,
            attachmentType: attachmentType,
            content: content
        }, component);

        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

     */

    /**
     * store
     */

    public static storeAll(attachments: IPublisherAttachmentModel[]): IPublisherAttachmentModel[] {
        for (let key in attachments)
            attachments[key] = this.store(attachments[key]);

        return Services.storeAll(attachments);
    }


    public static store(attachment: IPublisherAttachmentModel): IPublisherAttachmentModel {
        attachment.transmissions = PublisherTransmissionService.storeAll(attachment.transmissions);
        attachment.owner = AccountService.store(attachment.owner);
        if (attachment.type == AttachmentType.FILE) {
            attachment.content = FileService.store(attachment.content as IFileModel);
        }

        if (attachment.type == AttachmentType.PREVIEW) {
            attachment.content = PreviewService.store(attachment.content as IPreviewModel);
        }

        return Services.store("id", this.attachments, attachment);
    }
}