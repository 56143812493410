import {
    VisualClickTooltipWhiteComponent
} from "../../../../../sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import {DateIntervalPickerComponent} from "../DateIntervalPickerComponent";

export class DateIntervalPickerTooltipComponent extends VisualClickTooltipWhiteComponent {

    public picker: DateIntervalPickerComponent;

    constructor(component: Component, firstDate: Date, secondDate: Date, firstComparatorDate?: Date, secondComparatorDate?: Date) {
        super(component, 200, 480);
        this.picker = new DateIntervalPickerComponent(firstDate, secondDate, firstComparatorDate, secondComparatorDate);
        //language=HTML
        this.template = `
            <div>
                ${this.draw(this.picker)}
            </div>
        `;

        this.mobileStyle(true);
    }

    commit() {
        super.commit();
        this.picker.onCancel = () => this.dispose();
    }
}