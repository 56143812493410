@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.globalProgressBar {
  height: 100%;
  width: 100%;
  background: var(--grey-sur);
  overflow: hidden;
  position: relative;
  cursor: pointer;

  @include positioning.flex-globally;

  &.white {
    background: var(--white);
  }

  .percent {
    position: absolute;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);

    @include texts.font("semi-bold");
  }

  .bar {
    height: 100%;
    width: 0;
    background: var(--blue);
    position: absolute;
    top: 0;
    left: 0;
  }
}