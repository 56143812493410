.componentTooltip {
  border: 1px solid var(--grey-alpha);
  display: inline-block;
  line-height: 0px;
  border-radius: 50%;
  font-size: 75%;
  position: relative;
  top: -1px;
  margin-left: 5px;
  cursor: pointer;
  padding: unset !important;

  &:hover {
    background: var(--grey-alpha);
    border: 1px solid transparent;
  }

  span {
    display: inline-block;
    padding: unset !important;
    padding-top: 50% !important;
    padding-bottom: 50% !important;
    margin-left: 6px;
    margin-right: 6px;
  }

}