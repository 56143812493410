@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsiteBlackJoin {
  border-radius: 22px;
  border: 1px solid var(--web-border-white);
  background: var(--web-background-grid-blue);
  padding: 25px;
  width: calc(100% - 50px);
  position: relative;
  margin-top: 33px;
  display: flex;
  flex-direction: row;
  gap: 25px;

  .names {
    display: flex;
    flex-direction: column;
    gap: 25px;
    position: relative;
    z-index: 1;

    .title {
      font-size: 28px;
      display: flex;
      align-items: center;
      gap: 10px;

      @include texts.font("semi-bold");
    }

    .description {
      font-size: 15px;
      max-width: 500px;
    }

    .start {
      margin-top: 30px;
      border-radius: 20px;
      border-top: 2px solid var(--web-border-blue);
      background: var(--web-grad-blue);
      font-size: 16px;
      color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 32px;
      height: fit-content;
      cursor: pointer;
      width: fit-content;

      &:hover {
        background: var(--web-blue-hover);

        .icon {
          transform: translateX(8px);
        }
      }

      .startShadow {
        background: var(--web-blue);
        filter: blur(100px);
        width: 168px;
        height: 168px;
        position: absolute;
        pointer-events: none;
      }

      .content {
        display: flex;
        gap: 15px;
        position: relative;
        align-items: center;

        .icon {
          height: 16px;
          width: 16px;
          filter: var(--icon-white);
          transition: transform 0.1s ease-in-out;

          @include icons.ico("black-right-arrow");
        }
      }
    }
  }

  .iconContainer {
    height: 100%;
    width: 280px;
    flex: 0 0 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    z-index: 1;

    .icon {
      width: 265px;
      height: 265px;

      @include icons.ico("illus-black-search-engine");
    }
  }

  .shadow {
    width: 84px;
    height: 84px;
    background: var(--web-blue);
    filter: blur(85px);
    position: absolute;
    bottom: 0;
    right: 0;
  }

  @media (max-width: 930px) {
    .names {
      z-index: 1;
      position: relative;
    }
    .iconContainer {
      position: absolute;
      right: -50%;
      z-index: 0;
    }
  }
}