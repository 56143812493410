import {WebsitePageComponent} from "../components/pages/WebsitePageComponent";
import {WebsiteContentBlogWritingComponent} from "../components/content/pages/blog/WebsiteContentBlogWritingComponent";
import {
    Component
} from "../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";

export class WebsiteBlogWritingPage extends WebsitePageComponent {

    constructor() {
        super();
        this.updateMetas("ai");
        this.status.online = undefined;
    }

    contentComponent(): Component {
        return new WebsiteContentBlogWritingComponent();
    }

    onHash(): void {

    }
}