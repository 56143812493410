/**
 * Retourne la valeur à mettre dans un input selon une date
 */
export function dateInput(date: Date, noHours?: boolean): string {
    let day = ("0" + date.getDate()).slice(-2);
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let year = date.getFullYear();
    let hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

    let hoursText = noHours != undefined ? `` : `T${hour}:${minutes}`;

    return `${year}-${month}-${day}${hoursText}`;
}