import {INetworkComponent} from "../../network/types/INetworkComponent";
import {Network} from "../../network/Network";
import {HttpStatus} from "../../network/status/HttpStatus";
import {observable} from "@nx-js/observer-util";
import {Services} from "../Services";
import {IInvitationModel} from "../../models/invitation/IInvitationModel";
import {SiteService} from "../site/SiteService";
import {RoleService} from "../roles/RoleService";
import {ProductType} from "../../models/product/ProductType";

export class InvitationService {
    public static invitations: IInvitationModel[] = observable([]);

    public static init() {
    }

    public static dispose() {
        this.invitations = observable([]);
    }

    /**
     * http
     */
    public static async findById(id: string, component?: INetworkComponent): Promise<IInvitationModel> {
        let request = await Network.get(ProductType.PANEL, `/invitation/${id}`, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async findBySite(siteId: string, component?: INetworkComponent): Promise<IInvitationModel[]> {
        let request = await Network.get(ProductType.PANEL, `/invitation/site/${siteId}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return undefined;
    }

    public static async use(id: string, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.get(ProductType.PANEL, `/invitation/use/${id}`, component);
        return request.status == HttpStatus.OK;
    }

    public static async create(hours: number, maxUses: number, rolesIds: string[], component?: INetworkComponent): Promise<IInvitationModel> {
        let request = await Network.post(ProductType.PANEL, "/invitation",
            {
                hours: hours,
                maxUses: maxUses,
                rolesIds: rolesIds
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async delete(invitation: IInvitationModel, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.delete(ProductType.PANEL, `/invitation/${invitation.id}`, component);
        if (request.status == HttpStatus.OK) {
            this.unStore(invitation);
            return true;
        }

        return false;
    }

    /**
     * store
     */

    public static storeAll(invitations: IInvitationModel[]): IInvitationModel[] {
        for (let key in invitations)
            invitations[key] = this.store(invitations[key]);

        return Services.storeAll(invitations);
    }

    public static store(invitation: IInvitationModel): IInvitationModel {
        invitation.site = SiteService.store(invitation.site);
        invitation.roles = RoleService.storeAll(invitation.roles);

        return Services.store("id", this.invitations, invitation);
    }

    public static unStore(invitation: IInvitationModel) {
        Services.unStore("id", this.invitations, invitation);
    }

}