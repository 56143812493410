import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "../website-content.scss";
import {Resources} from "../../../../../../../resources/Resources";
import {WebsiteTableComponent} from "../../table/WebsiteTableComponent";

export class WebsiteContentPolicyComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteTableComponent({
                            clauses: Resources.t("words.privacyPolicy.clauses"),
                            title: Resources.t("words.privacyPolicy.conditions"),
                            subtitle: Resources.t("words.privacyPolicy.date")
                        }))}
                    </div>
                </div>
            </div>
        `;
    }
}