import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import {LoaderLightComponent} from "../../loader/light/LoaderLightComponent";
import * as s from "./buttons-saves.scss";
import {IButtonsSave} from "./types/IButtonsSave";
import {Resources} from "../../../../resources/Resources";

export class ButtonsSaveComponent extends Component {
    public saveButton: Component;
    public cancelButton: Component;
    public saveLoading: boolean;
    public cancelLoading: boolean;
    public settings?: IButtonsSave;

    constructor(settings?: IButtonsSave) {
        super();
        this.settings = settings;

        //language=HTML
        this.template = `
            <div btnSave class="${s.globalButtonsSave}">
                <div btnS class="${s.saveButton}">
                    ${this.settings?.saveText ? this.settings.saveText : Resources.t("words.save")}
                </div>
                <div btnC class="${s.cancelButton}">
                    ${this.settings?.cancelText ? this.settings.cancelText : Resources.t("words.cancel")}
                </div>
            </div>
        `;

    }

    commit() {
        this.saveButton = this.el(s.saveButton);
        this.cancelButton = this.el(s.cancelButton);

        this.saveButton.onClick(() => {
            if (!this.saveButton.hasClass(s.inactive)) {
                this.onSave();
            }
        }, true);
        this.cancelButton.onClick(() => {
            if (!this.cancelLoading)
                this.onCancel();
        }, true);

        if (this.settings) {
            if (this.settings.hideSaveButton) {
                this.saveButton.remove();
            }
            if (this.settings.hideCancelButton) {
                this.cancelButton.remove();
            }
        }

        super.commit();
    }

    /**
     * events
     */

    onSave() {

    }

    onCancel() {

    }

    setCancelLoading() {
        this.cancelButton.save();
        this.cancelButton.setStyle(`padding:0;height:${this.cancelButton.getHeight()}px;width:${this.cancelButton.getWidth()}px;`);
        this.cancelButton.clear();
        this.cancelButton.render(new LoaderLightComponent());
        this.cancelLoading = true;
    }


    remCancelLoading() {
        if (this.cancelLoading) {
            this.cancelButton.restore();
            this.cancelLoading = true;
        }
    }

    setLoadingAndInactive() {
        this.setLoading();
        this.setInactive();
    }

    remLoadingAndInactive() {
        this.remLoading();
        this.removeInactive();
    }

    setLoading() {
        this.saveButton.save();
        this.saveButton.setStyle(`padding:0;height:${this.saveButton.getHeight()}px;width:${this.saveButton.getWidth()}px;`);
        this.saveButton.clear();
        this.saveButton.render(new LoaderLightComponent());
        this.saveLoading = true;
    }

    remLoading() {
        if (this.saveLoading) {
            this.saveButton.restore();
            this.saveLoading = false;
        }
    }

    setInactive() {
        this.saveButton.addClass(s.inactive);
    }

    removeInactive() {
        this.saveButton.removeClass(s.inactive);
    }

    isInactive(): boolean {
        return this.saveButton.hasClass(s.inactive);
    }
}