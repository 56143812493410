@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.page {

  .offer {
    position: relative;
    width: 100%;
    height: 46px;
    display: flex;
    border-bottom: 1px solid var(--grey-sur);
    cursor: pointer;

    .left {
      display: flex;
      align-items: center;
      height: 100%;
      margin-left: 15px;

      .iconContainer {
        height: 30px;
        width: 30px;
        position: relative;
      }

      .name {
        margin-left: 15px;

        @include texts.font("semi-bold");
      }

      .price {
        background: var(--offer-free);
        color: var(--white);
        font-size: 12px;
        padding: 4px 8px;
        border-radius: 8px;
        margin-left: 8px;
      }
    }

    .right {
      margin-left: auto;
      display: flex;
      margin-right: 15px;
      align-items: center;
      height: 100%;

      .text {
        font-size: 13px;
        color: var(--grey-text-dark);
      }

      .button {
        background: var(--offer-free);
        color: var(--white);
        padding: 10px 14px;
        margin-left: 10px;
        font-size: 14px;
        border-radius: 8px;

        @include texts.font("semi-bold");
      }
    }
  }

  .content {
    height: 100%;

    &.free {
      height: calc(100% - 47px);
    }
  }

  .head {
    position: relative;
    height: 49px;
    z-index: 6;
    background: var(--white);
    border-bottom: 1px solid var(--grey-sur) !important;

    @include positioning.clear;

    .capsuleContainer {
      height: 30px;
      width: 30px;
      margin-left: 12px;
      float: left;
      margin-top: 10px;
    }

    .productSolo {
      margin-top: 13px;
      margin-left: 16px;
      font-size: 20px;
      width: fit-content;
      float: left;

      @include texts.font("bold");
    }

    .productName {
      margin-top: 13px;
      margin-left: 8px;
      font-size: 20px;
      width: fit-content;
      float: left;

      @include texts.font("bold");
    }

    .rightContainer {
      float: right;
      height: 100%;
      width: calc(100% - 170px);

      .headSide {
        height: 35px;
        width: 100%;
        border-left: 1px solid var(--grey-sur);
        padding-left: 10px;
        margin-top: 7px;

        .caseButton {
          float: left;
          border-radius: 10px;
          padding: 0 10px;
          position: relative;
          height: 100%;
          cursor: pointer;
          transition: 0.2s;
          overflow: hidden;
          margin-right: 10px;

          @include positioning.flex-globally;

          &:hover {
            background: var(--grey-sur);
          }

          &.add {
            visibility: visible;
          }

          &[checked] {
            &:after {
              left: 0;
              width: 100%;
              top: unset;
              height: 100%;
              bottom: 0;
              content: "";
              position: absolute;
              background: var(--grey-sur);
            }
          }

          .pastille {
            height: 14px;
            border-radius: 6px;
            margin-right: 5px;
            width: 14px;
            float: left;
            z-index: 2;

            .plus {
              height: 12px;
              width: 12px;
              margin-top: 2px;

              @include icons.ico("black-plus");
            }

          }

          .text {
            float: left;
            font-size: 16px;
            z-index: 2;

            @include texts.font("bold");
          }
        }
      }
    }

  }

  @media (max-width: 480px) {
    .offer {
      .left {
        .price {
          display: none;
        }
      }

      .right {
        .text {
          display: none;
        }
      }
    }
  }
}