import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-black-allie.scss";
import {Resources} from "../../../../../../resources/Resources";

export class WebsiteBlackAllieComponent extends Component {
    constructor() {
        super();

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteBlackAllie}">
                <div class="${s.title}">
                    ${Resources.t("words.yourBestSeoAlly")}
                </div>
                <div class="${s.bigTitle}">
                    ${Resources.t("words.analyzeRankingAndCompetitors")}
                </div>
                <div class="${s.cases}">
                    <div class="${s.case}">
                        <div class="${s.name}">
                            <div class="${s.icon} ${s.chart}"></div>
                            ${Resources.t("words.identifyKeywordsToOptimize")}
                        </div>
                        <div class="${s.description}">
                            ${Resources.t("words.identifyLowCompetitionKeywords")}
                        </div>
                    </div>
                    <div class="${s.case}">
                        <div class="${s.name}">
                            <div class="${s.icon} ${s.speedometer}"></div>
                            ${Resources.t("words.trackKeywordsYouWorkOn")}
                        </div>
                        <div class="${s.description}">
                            ${Resources.t("words.monitorPerformanceAndRanking")}
                        </div>
                    </div>
                    <div class="${s.case}">
                        <div class="${s.name}">
                            <div class="${s.icon} ${s.refresh}"></div>
                            ${Resources.t("words.reactQuicklyToChanges")}
                        </div>
                        <div class="${s.description}">
                            ${Resources.t("words.realTimeAnalysisToOptimize")}
                        </div>
                    </div>
                </div>
                <div class="${s.zig}"></div>
                <div class="${s.bigTitle}">
                    ${Resources.t("words.useAiToCreateAndOptimizeContent")}
                </div>
                <div class="${s.cases}">
                    <div class="${s.case} ${s.full}">
                        <div class="${s.name}">
                            ${Resources.t("words.optimizeExistingContentForKeyword")}
                        </div>
                        <div class="${s.icons}">
                            <div class="${s.description}">
                                ${Resources.t("words.advancedSystemForOptimization")}
                            </div>
                            <div class="${s.illus} ${s.optimize}"></div>
                        </div>
                    </div>
                    <div class="${s.case} ${s.full}">
                        <div class="${s.name}">
                            ${Resources.t("words.createEngagingSeoContent")}
                        </div>
                        <div class="${s.icons}">
                            <div class="${s.description}">
                                ${Resources.t("words.generateOptimizedArticles")}
                            </div>
                            <div class="${s.illus} ${s.plan}"></div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

}
