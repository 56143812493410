@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/inputs";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";

.globalDatePicker {
  height: 100%;
  width: 100%;

  .top {
    height: 26px !important;
    position: relative;
    margin-bottom: 8px;

    .input {
      @include inputs.white;

      border: unset;
      height: 26px;
      border-radius: 9px;
      width: calc(100% - 120px);
      cursor: text;
      float: left;
      padding: 0 5px;

      @include texts.font("semi-bold");

      &:hover {
        background: var(--grey-sur);
      }

      &.interval {
        text-align: center;
        width: calc(50% - 11px) !important;
      }

      &.First {
        visibility: visible;
      }

      &.Second {
        visibility: visible;
      }

    }

    .arrowIcon {
      height: 12px;
      width: 12px;
      margin-right: 5px;
      float: left;
      margin-top: 7px;
      margin-left: 5px;

      @include icons.ico("black-right-arrow");
    }

    .hours {
      width: 85px;
      position: relative;
      float: right;
      margin-right: -5px;
      padding: 3.6px 0 0 0;
      height: 21px;
      cursor: pointer;
      border-radius: 8px;

      &:hover {
        background: var(--grey-sur);
      }

      input {
        width: 38px !important;
        border: unset;
        background: transparent;

        @include texts.font("semi-bold");

        &.left {
          float: left;
        }

        &.right {
          float: right;
        }
      }

      .dots {
        margin-left: 3px;
        float: left;
        margin-top: -1px;

        @include texts.font("bold");
      }
    }
  }

  .calendarContainer {
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% - 25px);
    position: relative;
  }
}




