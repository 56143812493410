@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsiteColumnsThreeWhite {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .description {
    width: 70%;
    font-size: 44px;
    text-align: center;
    background: var(--grad-blue);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include texts.font("bold");
  }

  .columns {
    width: 100%;
    display: flex;
    margin-top: 50px;
    flex-direction: row;
    gap: 43px;
    position: relative;

    .column {
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 40px;
      border: 1px solid var(--grey-sur);
      border-radius: 33px;
      position: relative;

      .icon {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          border-radius: 12.5px;
          box-shadow: 0 2.16px 10.82px var(--black-alpha-middle);

          &.special {
            width: 45%;
            border-radius: 0;
            box-shadow: none;
          }
        }
      }

      .text {
        margin-bottom: 40px;
        text-align: center;
        background: var(--grad-black);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 20px;

        @include texts.font("semi-bold");
      }
    }
  }

  @media (max-width: 750px) {
    text-align: center;

    .description {
      width: 100%;
      font-size: 25px;
      text-align: center;
      margin-left: 0;

      @include texts.font("bold");
    }

    .columns {
      display: flex;
      margin-top: 25px;
      flex-direction: column;
      gap: 20px;

      .column {
        width: calc(100% - 70px);
        padding: 35px 35px;
        background: var(--grey-sur);
        border-radius: 33px;

        .icon {
          img {
            width: 40%;
            border-radius: 12.5px;
            box-shadow: 0 2.16px 10.82px var(--black-alpha-middle);

            &.special {
              width: 35%;
              border-radius: 0;
              box-shadow: none;
            }
          }
        }

        .text {
          margin-bottom: 20px;
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .columns {
      .column {
        .icon {
          img {
            width: 70%;
            border-radius: 12.5px;
            box-shadow: 0 2.16px 10.82px var(--black-alpha-middle);
          }
        }
      }
    }
  }
}