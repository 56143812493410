/**
 * datas d'une image
 */
import {SedestralMachine} from "../machine/SedestralMachine";

export async function fileImageData(url: string): Promise<{ ratio: number }> {
    return SedestralMachine.promise((resolve) => {
        let image = new Image();
        image.onload = () => {
            const w = image.naturalWidth;
            const h = image.naturalHeight;

            let aspectRatio;

            if (w > h) {
                aspectRatio = w / h;
            } else {
                aspectRatio = h / w;
            }

            resolve({ratio: aspectRatio});
        }

        image.src = url;
    });
}