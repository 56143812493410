/**
 * date format display date
 */
export function dateDisplay(date: Date, language: string, format?: object): string {
    let formatter = new Intl.DateTimeFormat(language, format == undefined ? {
        day: "numeric",
        month: "long",
        year: "numeric"
    } : format);
    return formatter.format(date);
}