import {IAccountPasswordLostModel} from "../../../../models/account/password/lost/IAccountPasswordLostModel";
import {INetworkComponent} from "../../../../network/types/INetworkComponent";
import {Network} from "../../../../network/Network";
import {ProductType} from "../../../../models/product/ProductType";
import {Services} from "../../../Services";
import {Resources} from "../../../../resources/Resources";
import {HttpStatus} from "../../../../network/status/HttpStatus";

export class AccountPasswordLostService {


    public static dispose(): void {

    }

    public static init(): void {

    }

    /**
     * rest
     */

    public static async lost(lost: IAccountPasswordLostModel, component?: INetworkComponent): Promise<void> {
        Services.handleErrors(component, [
            {status: HttpStatus.NOT_FOUND, message: Resources.t("words.forgotPasswordEmailNoFound")}
        ]);
        return await Network.postJson(ProductType.PANEL, `/account/password/lost`, lost, component);
    }
}