import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-black-text.scss";
import {IWebsiteBlackText} from "./types/IWebsiteBlackText";

export class WebsiteBlackTextComponent extends Component {
    constructor(text: IWebsiteBlackText) {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteBlackText} ${s[text.size]} ${s[text.shadow]}">
                <div class="${s.shadow}"></div>
                <div class="${s.content}">
                    ${text.text}
                </div>
            </div>
        `;
    }
}