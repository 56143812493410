@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";

.componentStatisticsCardSimple {
  height: 28px;
  border: var(--grey-sur) 1px solid;
  margin-top: 8px;
  width: 100%;
  border-radius: 20px;
  position: relative;

  .statistic {
    width: calc(50% - 1px);
    height: 100%;
    border-right: var(--grey-sur) 1px solid;
    float: left;
    display: flex;

    .text {
      font-size: 12px;
      text-align: center;
      margin-left: 8px;
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;

      @include texts.font("semi-bold");

    }
  }

  @media screen and (max-width: 480px) {
    .statistic {
      .number {
        font-size: 16px !important;
        margin-top: -4px !important;
      }

      .text {
        font-size: 14px !important;
      }
    }
  }
}