import {
    StatisticsSplitType
} from "../../../../../products/panel/components/components/statistics/types/StatisticsSplitType";
import {INetworkComponent} from "../../../../../network/types/INetworkComponent";
import {Network} from "../../../../../network/Network";
import {HttpStatus} from "../../../../../network/status/HttpStatus";
import {IStatisticsValuesModel} from "../../../../../models/statistics/IStatisticsValuesModel";
import {ProductType} from "../../../../../models/product/ProductType";

export class InboxConversationMessageStatisticsService {
    public static init(): void {

    }

    public static dispose(): void {

    }

    public static async findResponseDelay(startTime: number, endTime: number, oldStartTime: number, oldEndTime: number, splitType: StatisticsSplitType, filtersData?: string, component?: INetworkComponent): Promise<IStatisticsValuesModel> {
        let request = await Network.postJson(ProductType.PANEL, `/conversations/messages/statistics/responseDelay/${startTime}/${endTime}/${oldStartTime}/${oldEndTime}/${splitType}`, JSON.parse(filtersData ? filtersData : "{}"), component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async findCount(startTime: number, endTime: number, oldStartTime: number, oldEndTime: number, splitType: StatisticsSplitType, filtersData?: string, component?: INetworkComponent): Promise<IStatisticsValuesModel> {
        let request = await Network.postJson(ProductType.PANEL, `/conversations/messages/statistics/count/${startTime}/${endTime}/${oldStartTime}/${oldEndTime}/${splitType}`, JSON.parse(filtersData ? filtersData : "{}"), component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async findReceivedOffline(startTime: number, endTime: number, oldStartTime: number, oldEndTime: number, splitType: StatisticsSplitType, filtersData?: string, component?: INetworkComponent): Promise<IStatisticsValuesModel> {
        let request = await Network.postJson(ProductType.PANEL, `/conversations/messages/statistics/receivedOffline/${startTime}/${endTime}/${oldStartTime}/${oldEndTime}/${splitType}`, JSON.parse(filtersData ? filtersData : "{}"), component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async findHours(startTime: number, endTime: number, splitType: StatisticsSplitType, filtersData?: string, component?: INetworkComponent): Promise<IStatisticsValuesModel> {
        let request = await Network.postJson(ProductType.PANEL, `/conversations/messages/statistics/hours/${startTime}/${endTime}/${splitType}`, JSON.parse(filtersData ? filtersData : "{}"), component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

}