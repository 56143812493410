@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";

.componentWebsitePage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .head {
    width: 100%;
    position: relative;
    border-bottom: 1px solid var(--white-sur);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    z-index: 2;
    background: var(--black);
  }

  .content {
    padding: 0;

    @include positioning.webResponsive;

    .contents {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 50px;

      .container {
        width: 100%;
      }
    }
  }

  .footerContainer {
    margin: 70px 0 0 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
}