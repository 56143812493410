import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {
    LoaderLightComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import {SiteService} from "../../../../../../services/site/SiteService";
import {
    VisualSelectBoxComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/selectbox/VisualSelectBoxComponent";
import {Resources} from "../../../../../../resources/Resources";
import {CountryIconComponent} from "../../country/CountryIconComponent";
import * as s from "./site-tax.scss";
import {ISiteTaxModel} from "../../../../../../models/site/address/tax/ITaxTypeModel";
import {EntityService} from "../../../../../../services/entity/EntityService";

export class SiteTaxComponent extends Component {

    public taxes: ISiteTaxModel[];
    public selectBox: VisualSelectBoxComponent;

    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentSiteTax}">
                ${this.draw(new LoaderLightComponent())}
            </div>
        `;
    }

    commit() {
        this.init();
        super.commit();
    }

    async init() {
        this.taxes = await SiteService.getTaxTypes(this);
        let entries = [];
        let selectedValue: number;

        this.taxes.forEach(value => {
            let country = Resources.country(value.country);

            entries.push({
                value: value.id,
                label: (country ? `<div class="${s.componentSiteTaxIcon}">${this.draw(new CountryIconComponent(country.a3))}</div> ` : ``) + value.label,
                description: country?.n ?? value.country
            });

            if (EntityService.activeSite.address?.tax?.type) {
                selectedValue = EntityService.activeSite.address?.tax?.type;
            } else if (country?.a2 == (EntityService.activeSite.address?.country ?? Resources.getCountryCode())) {
                selectedValue = value.id;
            }
        })

        this.clearAll();
        this.selectBox = new VisualSelectBoxComponent({
            search: true,
            searchDescription: true,
            entries: entries,
            selectedValue: selectedValue
        });
        this.render(this.selectBox);
    }

    getSelectedTax(): ISiteTaxModel {
        return this.taxes.find(value => value.id == this.selectBox.getSelection().value);
    }

}