import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import {Resources} from "../../../../resources/Resources";
import {IDateCalendarMonthDay} from "./types/IDateCalendarMonthDay";
import {IDateCalendarMonthWeekDay} from "./types/IDateCalendarMonthWeekDay";
import {dateNextMonth} from "../../../sedestral-interface-component/utilities/DateNextMonth";
import {dateNumberDays} from "../../../sedestral-interface-component/utilities/DateNumberDays";
import {datePreviousMonth} from "../../../sedestral-interface-component/utilities/DatePreviousMonth";
import {dateDisplay} from "../../../sedestral-interface-component/utilities/DateDisplay";
import {dateGetWeek} from "../../../sedestral-interface-component/utilities/DateGetWeek";

export class DateCalendarAbstractComponent extends Component {

    public currentDate: Date;

    constructor(currentDate?: Date) {
        super();
        this.currentDate = currentDate ? currentDate : new Date();
    }

    /**
     * les initiales des jours de la semaine
     */
    abstractNames(): string[] {
        return [
            Resources.t("words.monday"),
            Resources.t("words.tuesday"),
            Resources.t("words.wednesday"),
            Resources.t("words.thursday"),
            Resources.t("words.friday"),
            Resources.t("words.saturday"),
            Resources.t("words.sunday")
        ];
    }

    /**
     * WEEK
     */

    /**
     * retourne les jours de la semaine
     */
    abstractMonthWeekDays(): IDateCalendarMonthWeekDay[] {
        let week = dateGetWeek(this.currentDate);
        let names = this.abstractNames();
        let dates: IDateCalendarMonthWeekDay[] = [];

        for (let i = 0; i < 7; i++) {
            let date = new Date(week.monday);
            date.setDate(date.getDate() + i);

            dates.push({number: date.getDate(), name: names[i], date: date});
        }
        return dates;
    }

    abstractNextWeek(): Date {
        let week = dateGetWeek(this.currentDate);
        week.monday.setDate(week.monday.getDate() + 7);

        return week.monday;
    }

    abstractPreviousWeek(): Date {
        let week = dateGetWeek(this.currentDate);
        week.monday.setDate(week.monday.getDate() - 7);

        return week.monday;
    }

    /**
     * MONTH
     */

    /**
     * Liste de 42 numéros de jours des mois précédents, en cours et suivants
     * +1 à la place de +2 pour mettre dimanche en premier
     */
    abstractMonthDays(): IDateCalendarMonthDay[] {
        let previousMonth = datePreviousMonth(this.currentDate.getFullYear(), this.currentDate.getMonth());
        let nextMonth = dateNextMonth(this.currentDate.getFullYear(), this.currentDate.getMonth());

        let monthDays = dateNumberDays(this.currentDate.getFullYear(), this.currentDate.getMonth());
        let previousMonthDays = dateNumberDays(previousMonth['year'], previousMonth['month']);

        let dateMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
        //Pour démarrer la semaine par Dimanche
        // let startOffset = dateMonth.getDay();

        let startOffset = (dateMonth.getDay() - 1 + 7) % 7; // Ajustement pour commencer la semaine par lundi
        let endOffset = 42 - (monthDays + startOffset);

        let days: IDateCalendarMonthDay[] = [];

        // Pour les jours du mois précédent
        for (let i = 1; i <= startOffset; i++) {
            days.push({
                day: previousMonthDays - startOffset + i,
                month: previousMonth['month'],
                year: previousMonth['year'],
                grey: true
            });
        }

        // Pour les jours du mois actuel
        for (let i = 1; i <= monthDays; i++) {
            days.push({
                day: i,
                month: this.currentDate.getMonth(),
                year: this.currentDate.getFullYear()
            });
        }

        // Pour les jours du mois suivant
        for (let i = 1; i <= endOffset; i++) {
            days.push({
                day: i,
                month: nextMonth['month'],
                year: nextMonth['year'],
                grey: true
            });
        }

        return days;
    }

    /**
     * Retourne le nom du mois
     */
    abstractMonthName() {
        return dateDisplay(this.currentDate, Resources.language, {
            month: "long",
            year: "numeric"
        });
    }

    /**
     * mois suivant
     */
    abstractNextMonth(): Date {
        let month = dateNextMonth(this.currentDate.getFullYear(), this.currentDate.getMonth());
        return new Date(month['year'], month['month']);
    }

    /**
     * mois précédent
     */
    abstractPreviousMonth(): Date {
        let month = datePreviousMonth(this.currentDate.getFullYear(), this.currentDate.getMonth());
        return new Date(month['year'], month['month']);
    }

}