@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentWebsiteBrand {
  display: flex;
  flex-direction: row;
  gap: 15.6px;

  .capsuleContainer {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  .productName {
    margin-top: 3px;
    font-size: 24px;
    @include texts.font("bold");
  }
}