/**
 * Met les secondes au format lisible par exemple "1h" "4h 23m"
 */

export function dateTinyString(time: number, oneValue?: boolean) {
    if (time <= 0) {
        return "0s";
    }

    let d = Math.floor(time / 86400);
    let h = Math.floor(time % 86400 / 3600);
    let m = Math.floor(time % 86400 % 3600 / 60);
    let s = Math.floor(time % 86400 % 3600 % 60);

    let dDisplay = d > 0 ? d + "j " : "";
    let hDisplay = h > 0 ? h + "h " : "";
    let mDisplay = m > 0 ? m + "m " : "";
    let sDisplay = (h == 0) ? s > 0 ? s + "s " : "" : "";

    if (oneValue) {
        if (d >= 1) {
            return dDisplay;
        }
        if (h >= 1) {
            return hDisplay;
        }
        if (m >= 1) {
            return mDisplay;
        }
    }

    if (d >= 7) {
        return dDisplay;
    }
    if (d >= 1) {
        return dDisplay + hDisplay;
    }
    if (h >= 10) {
        return hDisplay;
    }
    if (m >= 30) {
        return mDisplay;
    }

    return hDisplay + mDisplay + sDisplay;
}