import {Component} from "../../sedestral-interface-component/interface/component/Component";
import {IFilePicker} from "./types/IFilePicker";
import {Network} from "../../../network/Network";
import {Resources} from "../../../resources/Resources";
import {numberBytesToMO} from "../../sedestral-interface-component/utilities/NumberBytesToMO";
import {fileUrlExtension} from "../../sedestral-interface-component/utilities/FileUrlExtension";
import {fileIsSecure} from "../../sedestral-interface-component/utilities/FileIsSecure";
import {fileIsImage} from "../../sedestral-interface-component/utilities/FileIsImage";
import {fileIsGif} from "../../sedestral-interface-component/utilities/FileIsGif";
import {fileIsFile} from "../../sedestral-interface-component/utilities/FileIsFile";
import {randomString} from "../../sedestral-interface-component/utilities/RandomString";
import {fileIsVideo} from "../../sedestral-interface-component/utilities/FileIsVideo";

export class VisualFilePickerComponent extends Component {

    public id: string;
    public settings: IFilePicker;
    public files: File[];

    public filesInput: Component;
    public inputContainer: Component;


    constructor(settings?: IFilePicker) {
        super();
        this.id = randomString(30, true);
        this.settings = settings == undefined ? {} : settings;
        if (!this.settings.maxSize) {
            this.settings.maxSize = 25;
        }
        this.files = [];
    }

    commit() {
        let container = this.inputContainer == undefined ? this : this.inputContainer;
        this.filesInput = container.append(`<input class="files-picker" id="files-picker${this.id}" type='file' ${this.settings.multiple ? "multiple" : ""}/>`);
        this.filesInput.addListener("change", () => {
            if (this.filesInput.element instanceof HTMLInputElement) {
                for (let i = 0; i < this.filesInput.element.files.length; i++) {

                    let file = this.filesInput.element.files[i];
                    if (this.settings.maxCount != undefined && this.files.length > this.settings.maxCount) {
                        return this.error(Resources.t("words.maxFileLimitError"));
                    }

                    if (this.settings.maxSize != undefined && (this.size() + numberBytesToMO(file.size)) > this.settings.maxSize) {
                        return this.error(Resources.r("words.maxSizeLimitError", {maxSize: this.settings.maxSize}));
                    }

                    if (this.settings.secure != undefined && !fileIsSecure(fileUrlExtension(file.name))) {
                        return this.error(Resources.t("words.pickerErrorFileType"));
                    }

                    if (fileIsImage(file.type)) {
                        if (this.settings.images == undefined || !this.settings.images) {
                            return this.error(Resources.t("words.imageNotAllowedError"));
                        }

                        if (this.settings.imagesCount != undefined && this.imagesCount() >= this.settings.imagesCount) {
                            return this.error(Resources.t("words.publisherErrorImageLength"));
                        }

                        if (this.settings.imagesIndividualSize != undefined && numberBytesToMO(file.size) >= this.settings.imagesIndividualSize) {
                            return this.error(Resources.t("words.publisherErrorSize"));
                        }

                        if (this.settings.imagesMaxSize != undefined && this.imagesSize() >= this.settings.imagesMaxSize) {
                            return this.error(Resources.t("words.maxImageTotalSizeError"));
                        }

                        // Calcul du ratio de l'image
                        /* const image = new Image();
                         const objectURL = URL.createObjectURL(file);
                         image.onload = () => {
                             const ratio = image.width / image.height;

                             if (this.settings.minImageRatio && ratio < this.settings.minImageRatio) {
                                 return this.error(Resources.t("words.minImageRatioError"));
                             }

                             if (this.settings.maxImageRatio && ratio > this.settings.maxImageRatio) {
                                 return this.error(Resources.t("words.maxImageRatioError"));
                             }

                             // Si tout est bon, alors on continue
                             this.onImagePick(file);
                             this.onImageAndGifPick(file);

                             // Libérer la mémoire (URL.createObjectURL)
                             URL.revokeObjectURL(objectURL);
                         }*/

                        this.onImagePick(file);
                        this.onImageAndGifPick(file);
                    }

                    if (fileIsGif(file.type)) {
                        if (this.settings.gifs == undefined || !this.settings.gifs) {
                            return this.error(Resources.t("words.gifNotAllowedError"));
                        }

                        if (this.settings.gifsCount != undefined && this.gifsCount() >= this.settings.gifsCount) {
                            return this.error(Resources.t("words.publisherErrorGifLength"));
                        }

                        if (this.settings.gifsIndividualSize != undefined && numberBytesToMO(file.size) >= this.settings.gifsIndividualSize) {
                            return this.error(Resources.t("words.maxGifSizeError"));
                        }

                        if (this.settings.gifsMaxSize != undefined && this.gifsSize() >= this.settings.gifsMaxSize) {
                            return this.error(Resources.t("words.maxGifTotalSizeError"));
                        }

                        this.onGifPick(file);
                        this.onImageAndGifPick(file);
                    }

                    if (fileIsFile(file.type)) {
                        if (this.settings.files == undefined || !this.settings.files) {
                            return this.error(Resources.t("words.fileNotAllowedError"));
                        }

                        if (this.settings.filesCount != undefined && this.filesCount() >= this.settings.filesCount) {
                            return this.error(Resources.t("words.maxFileLimitError"));
                        }

                        if (this.settings.filesIndividualSize != undefined && numberBytesToMO(file.size) >= this.settings.filesIndividualSize) {
                            return this.error(Resources.t("words.maxFileSizeError"));
                        }

                        if (this.settings.filesMaxSize != undefined && this.filesSize() >= this.settings.filesMaxSize) {
                            return this.error(Resources.t("words.maxFileTotalSizeError"));
                        }

                        this.onFilePick(file);
                    }

                    if (fileIsVideo(file.type)) {
                        if (this.settings.videos == undefined || !this.settings.videos) {
                            return this.error(Resources.t("words.videoNotAllowedError"));
                        }

                        if (this.settings.videosCount != undefined && this.videosCount() >= this.settings.videosCount) {
                            return this.error(Resources.t("words.maxVideoLimitError"));
                        }

                        if (this.settings.videosIndividualSize != undefined && numberBytesToMO(file.size) >= this.settings.videosIndividualSize) {
                            return this.error(Resources.t("words.maxVideoSizeError"));
                        }

                        if (this.settings.videosMaxSize != undefined && this.videosSize() >= this.settings.videosMaxSize) {
                            return this.error(Resources.t("words.maxVideoTotalSizeError"));
                        }

                        this.onVideoPick(file);
                    }

                    if (!this.settings.multiple) {
                        this.files = [];
                    }

                    this.files.push(file);
                    this.onPick(file);
                }

                this.filesInput.element.value = "";
            }
        });

        super.commit();
    }


    error(value: string): void {
        if (this.filesInput.element instanceof HTMLInputElement) {
            this.filesInput.element.value = "";
        }

        this.notify(value);
    }

    notify(value: string): void {
        Network.router.static.components.notifications.notify(value, undefined, 3000);
    }

    removeFile(file: File) {
        this.files.splice(this.files.indexOf(file), 1);
        this.onRemoveFile(file);
    }

    /**
     * override
     */
    onPick(file: File) {

    }

    onImagePick(file: File) {

    }

    onGifPick(file: File) {

    }

    onImageAndGifPick(file: File) {

    }

    onFilePick(file: File) {

    }

    onVideoPick(file: File) {

    }

    onRemoveFile(file: File) {

    }

    /**
     * size
     */
    size(): number {
        let size = 0;
        for (let file of this.files) {
            size += file.size;
        }

        return numberBytesToMO(size);
    }

    imagesSize(): number {
        let size = 0;
        for (let file of this.files) {
            if (fileIsImage(file.type)) {
                size += file.size;
            }
        }

        return numberBytesToMO(size);
    }

    gifsSize(): number {
        let size = 0;
        for (let file of this.files) {
            if (fileIsGif(file.type)) {
                size += file.size;
            }
        }

        return numberBytesToMO(size);
    }

    filesSize(): number {
        let size = 0;
        for (let file of this.files) {
            if (fileIsFile(file.type)) {
                size += file.size;
            }
        }

        return numberBytesToMO(size);
    }

    videosSize(): number {
        let size = 0;
        for (let file of this.files) {
            if (fileIsVideo(file.type)) {
                size += file.size;
            }
        }

        return numberBytesToMO(size);
    }


    /**
     * count
     */
    // @ts-ignore
    count(): number {
        return this.files.length;
    }

    imagesCount(): number {
        let size = 0;
        for (let file of this.files) {
            if (fileIsImage(file.type)) {
                size++;
            }
        }
        return size;
    }

    gifsCount(): number {
        let size = 0;
        for (let file of this.files) {
            if (fileIsGif(file.type)) {
                size++;
            }
        }
        return size;
    }

    filesCount(): number {
        let size = 0;
        for (let file of this.files) {
            if (fileIsFile(file.type)) {
                size++;
            }
        }
        return size;
    }

    videosCount(): number {
        let size = 0;
        for (let file of this.files) {
            if (fileIsVideo(file.type)) {
                size++;
            }
        }
        return size;
    }
}