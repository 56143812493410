import {Resources} from "../../../resources/Resources";

export function numberPrice(priceCentimes: number, includedTax?: boolean) {
    const priceEuros = priceCentimes / 100;
    const formattedPrice = priceEuros.toFixed(2);

    const tax = (includedTax == undefined ? "" : includedTax == true
        ? " " + Resources.t("words.offerIncludeTax")
        : " " + Resources.t("words.offerExcludeTax"));

    if (formattedPrice.endsWith("00")) {
        return formattedPrice.substring(0, formattedPrice.length - 3) + "€" + tax;
    }

    return formattedPrice + "€" + tax;
}