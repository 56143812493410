import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./offer-solution.scss";
import {
    IOfferProductSolutionTemplateModel
} from "../../../../../../models/offer/product/solution/IOfferProductSolutionTemplateModel";
import {Resources} from "../../../../../../resources/Resources";
import {
    IOfferProductBundleTemplateModel
} from "../../../../../../models/offer/product/bundles/IOfferProductBundleTemplateModel";
import {Network} from "../../../../../../network/Network";

export class OfferSolutionComponent extends Component {

    public solution: IOfferProductSolutionTemplateModel;
    public bundle: IOfferProductBundleTemplateModel;
    public value: number;

    public inputContainer: Component;
    public lessButton: Component;
    public plusButton: Component;

    constructor(bundle: IOfferProductBundleTemplateModel, solution: IOfferProductSolutionTemplateModel) {
        super();
        this.solution = solution;
        this.bundle = bundle;
        this.value = solution.quantityInitial;

        //language=HTML
        this.template = `
            <div class="${s.componentOfferSolution}">
                <div class="${s.name}">
                    ${Resources.t(`words.offerSolutionDescription${solution.type}`)}
                </div>
                <div class="${s.cursor}">
                    <div class="${s.round} ${s.less}">
                        <div class="${s.icon}"></div>
                    </div>
                    <div class="${s.input}">${solution.quantityInitial}</div>
                    <div class="${s.round} ${s.plus}">
                        <div class="${s.icon}"></div>
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        this.inputContainer = this.el(s.input);
        this.lessButton = this.el(s.less);
        this.plusButton = this.el(s.plus);

        this.lessButton.onClick(() => this.lessGranularity());
        this.plusButton.onClick(() => this.plusGranularity());

        super.commit();
    }

    plusGranularity() {
        if (this.bundle.maxOption == undefined || this.solutionCount() < this.bundle.maxOption) {
            this.value += this.solution.granularity;
            this.inputContainer.setText(this.value + "");
            this.onSolutionChange(this.solutionCount());
        } else {
            Network.router.static.components.notifications.notify(Resources.t("words.offerSolutionLimit"), undefined, 3000);
        }
    }

    lessGranularity() {
        let value = this.value - this.solution.granularity;
        if (value >= this.solution.quantityInitial) {
            this.value = value;
            this.inputContainer.setText(this.value + "");

            this.onSolutionChange(this.solutionCount());
        }
    }

    setGranularity(count: number) {
        this.value = this.solution.quantityInitial + (this.solution.granularity * count);
        this.inputContainer?.setText(this.value + "");
    }

    solutionCount() {
        return Math.round((this.value - this.solution.quantityInitial) / this.solution.granularity);
    }

    onSolutionChange(count: number) {

    }

}