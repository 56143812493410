import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {
    ButtonsSaveComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/buttons/save/ButtonsSaveComponent";
import * as s from "./contact-creator.scss";
import {IContactModel} from "../../../../../../models/contact/IContactModel";
import {Resources} from "../../../../../../resources/Resources";
import {ContactService} from "../../../../../../services/contact/ContactService";
import {Network} from "../../../../../../network/Network";
import {
    VisualInputStatesComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/input/states/VisualInputStatesComponent";
import {
    txtNameIsVisitor
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/TxtNameIsVisitor";

export class ContactCreatorComponent extends Component {

    public contact: IContactModel;

    public inputName: VisualInputStatesComponent;
    public inputEmail: VisualInputStatesComponent;
    public inputPhone: VisualInputStatesComponent;

    public buttonsComponent: ButtonsSaveComponent;

    constructor(contact?: IContactModel) {
        super();
        this.contact = contact;
        this.inputName = new VisualInputStatesComponent({
            placeholder: Resources.t("words.contactName"),
            required: true,
            value: !txtNameIsVisitor(this.contact?.name) ? this.contact?.name : undefined
        });
        this.inputEmail = new VisualInputStatesComponent({
            placeholder: Resources.t("words.emailAddress"),
            type: "email",
            value: this.contact?.email
        });
        this.inputPhone = new VisualInputStatesComponent({
            type: "tel",
            placeholder: Resources.t("words.numberPhone"),
            value: this.contact?.numberPhone
        });

        this.buttonsComponent = new ButtonsSaveComponent();

        //language=HTML
        this.template = `
            <div class="${s.componentContactCreator}">
                <div class="${s.description}">
                    ${Resources.t("words.contactCanCreate")}
                </div>
                <div class="${s.inputContainer}">
                    ${this.draw(this.inputName)}
                </div>
                <div class="${s.inputContainer}">
                    ${this.draw(this.inputEmail)}
                </div>
                <div class="${s.inputContainer}">
                    ${this.draw(this.inputPhone)}
                </div>

                <div class="${s.buttonsContainer}">
                    ${this.draw(this.buttonsComponent)}
                </div>
            </div>
        `;
    }

    commit() {
        this.buttonsComponent.onSave = async () => {
            if (this.inputName.check() && this.inputPhone.check() && this.inputPhone.check()) {
                this.buttonsComponent.setLoadingAndInactive();
                let contact = await ContactService.create(this.contact?.id, this.inputName.getValue(), this.inputEmail.getValue(), this.inputPhone.getValue(), this);
                if (contact) {
                    if (!Network.router.pages.getPath().includes("/contacts/c/")) {
                        await Network.router.pages.redirect(`/contacts/c/${contact.id}`, true);
                    }
                    this.onCreate(contact);
                } else {
                    this.buttonsComponent.remLoadingAndInactive();
                }
            }
        }

        this.buttonsComponent.onCancel = () => this.onCancel();

        super.commit();
    }

    onCreate(contact: IContactModel) {

    }

    onCancel() {

    }

}