import {
    Component
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-columns-grid-white.scss";

export class WebsiteColumnsGridWhiteComponent extends Component {

    public details: { icon: string, title: string, subtitle: string }[];
    public detailsContainer: Component;
    public color: string;

    constructor(p: { color: string, title: string, details: { icon: string, title: string, subtitle: string }[] }) {
        super();

        this.color = p.color;
        this.details = p.details;

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteColumnsGridWhite}">
                <div class="${s.head}">
                    <h3 class="${s.title} ${s[this.color]}">
                        ${p.title}
                    </h3>
                </div>
                <div class="${s.details}">
                    
                </div>
            </div>
        `;
    }

    commit() {
        this.detailsContainer = this.el(s.details);
        this.details.forEach(detail => {
            //language=HTML
            this.detailsContainer.append(`
                <div class="${s.detail}">
                    <div class="${s.icon} ${s[detail.icon]}"></div>
                    <h4 class="${s.title}">
                        ${detail.title}
                    </h4>
                    <p class="${s.subtitle}">
                        ${detail.subtitle}
                    </p>
                </div>
            `);
        });
        super.commit();
    }
}