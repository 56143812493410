import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./offer-content.scss";
import {
    IOfferProductBundleTemplateModel
} from "../../../../../../models/offer/product/bundles/IOfferProductBundleTemplateModel";
import {IOfferProductTemplateModel} from "../../../../../../models/offer/product/templates/IOfferProductTemplateModel";
import {Resources} from "../../../../../../resources/Resources";

export class OfferContentComponent extends Component {

    public bundle: IOfferProductBundleTemplateModel;
    public offersContainer: Component;

    public contents: string[];

    constructor(contents: string[], text: string) {
        super();
        this.contents = contents;

        //language=HTML
        this.template = `
            <div class="${s.componentOfferContent}">
                ${text ? `<div class="${s.title}">${text}</div>` : ``}
                <ul class="${s.offers}"></ul>
            </div>
        `;
    }

    commit() {
        this.offersContainer = this.el(s.offers);
        this.contents.forEach(value => {
            this.offersContainer.append(`<li>${value}</li>`);
        });
        super.commit();
    }
}