@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentCountryIcon {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .empty {
    height: 80%;
    width: 80%;

    @include icons.ico("black-language");
  }
}