export function getHostFromUrl(url: string) {
    try {
        // Ajouter le préfixe 'http://' si l'URL ne commence pas par 'http://' ou 'https://'
        if (!/^https?:\/\//i.test(url)) {
            url = 'https://' + url;
        }
        const parsedUrl = new URL(url);
        return parsedUrl.host;
    } catch (e) {
        console.error("Invalid URL:", e);
        return null;
    }
}

export function getParam(param: string): string | null {
    try {
        const url = window.location.href;
        const regex = new RegExp('[?&]' + param + '=([^&#]*)', 'i');
        const result = regex.exec(url);
        return result ? decodeURIComponent(result[1]) : null;
    } catch (error) {
        return null;
    }
}

export function removeParam(param: string) {
    try {
        const url = window.location.href;
        const regex = new RegExp('[?&]' + param + '(=([^&#]*)|&|#|$)', 'i');

        // Si le paramètre n'existe pas dans l'URL, ne rien faire
        if (!regex.test(url)) return;

        let newUrl = url.replace(regex, (match, p1, p2, offset, string) => {
            return string.charAt(offset - 1) === '?' ? '?' : '';
        });

        // Nettoyer les '&' ou '?' en double générés
        newUrl = newUrl.replace(/[?&]+$/, '');
        newUrl = newUrl.replace(/[?&]+(?=[#])/g, '');

        // Remplacer l'URL dans la barre d'adresse sans recharger la page
        window.history.replaceState(null, '', newUrl);
    } catch (error) {
        console.error('Error removing parameter:', error);
    }
}
