@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsiteBlackUnion {
  display: flex;
  flex-direction: column;
  gap: 35px;

  .rect {
    display: flex;
    flex-direction: column;
    align-items: center;

    .pastille {
      border-radius: 39px;
      align-items: center;
      padding: 10px 18px;
      gap: 10px;
      border: 1px solid rgba(80, 130, 255, 0.15);
      background: rgba(80, 130, 255, 0.10);
      color: var(--web-blue);
      font-size: 16px;
      display: flex;

      .iconContainer {
        display: flex;
        align-items: center;
        justify-content: center;

        .shadow {
          width: 32px;
          height: 32px;
          background: var(--web-blue);
          filter: blur(12.5px);
          position: absolute;
        }

        .icon {
          height: 27px;
          width: 27px;
          position: relative;

          @include icons.ico("3d-ai");
        }
      }
    }
  }

  .union {
    position: relative;
    width: 800px;
    height: 98px;
    left: calc(50% - 400px);

    @include icons.ico("illus-black-union");
  }

  .cases {
    display: flex;
    gap: 25px;

    .case {
      position: relative;
      width: 33.33%;
      border-radius: 22px;
      padding: 1px;

      &.blue {
        &::before {
          background: linear-gradient(to bottom, var(--web-blue), transparent);
        }

        .content {
          background: linear-gradient(#000, #00071a);

          .blur {
            background: var(--web-blue);
          }

          .name {
            color: var(--web-blue);
          }
        }
      }

      &.purple {
        &::before {
          background: linear-gradient(to bottom, var(--web-purple), transparent);
        }

        .content {
          background: linear-gradient(#000, #0c0818);

          .blur {
            background: var(--web-purple);
          }

          .name {
            color: var(--web-purple);
          }
        }
      }

      &.yellow {
        &::before {
          background: linear-gradient(to bottom, var(--web-yellow), transparent);
        }

        .content {
          background: linear-gradient(#000, #121200);

          .blur {
            background: var(--web-yellow);
          }

          .name {
            color: var(--web-yellow);
          }
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 22px;
        z-index: 1;
        pointer-events: none;
      }

      .content {
        position: relative;
        z-index: 2;
        width: calc(100% - 60px);
        height: calc(100% - 100px);
        border-radius: 22px;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 50px 30px;
        display: flex;
        flex-direction: column;
        gap: 25px;

        .names {
          width: 230px;
          text-align: center;
          position: relative;

          .blur {
            width: 100%;
            height: 100%;
            filter: blur(75px);
            position: absolute;
          }

          .name {
            font-size: 32px;
            width: 100%;
            text-align: center;
            position: relative;

            @include texts.font("bold");
          }
        }

        .description {
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {

    .union {
      width: 300px;
      height: 36px;
      left: calc(50% - 150px);
    }

    .cases {
      flex-direction: column;

      .case {
        width: 100%;
      }
    }
  }
}