.globalButtonCheckBox {
  border: 2px solid var(--grey-sur);
  padding: 3px;
  border-radius: 100%;
  position: relative;
  width: fit-content;
  cursor: pointer;

  .caret {
    height: 8px;
    width: 8px;
    background: var(--white);
    border-radius: 100%;
  }

  &[unchecked] {
    &:hover {
      .caret {
        background: var(--grey-sur);
      }
    }
  }

  &[checked] {
    background: var(--green);

    .caret {
      box-shadow: 0 0 2px 2px #0000001a;
    }
  }
}