import * as s from "./country-picker.scss";

import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {
    IVisualSelectBox
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/selectbox/types/IVisualSelectBox";
import {
    VisualSelectBoxComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/selectbox/VisualSelectBoxComponent";
import {
    objectUpdate
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/ObjectUpdate";
import {Resources} from "../../../../../../resources/Resources";

export class CountryPickerComponent extends Component {
    public selectBox: VisualSelectBoxComponent;
    public settings?: IVisualSelectBox;

    public value: string;
    public filters?: string[];


    constructor(value?: string, settings?: IVisualSelectBox, filters?: string[]) {
        super();
        this.value = value;
        this.settings = settings;
        this.filters = filters;

        //language=HTML
        this.template = `
            <div class="${s.componentCountryPicker}"></div>
        `;
    }

    commit() {
        super.commit();
        this.selectBox = new VisualSelectBoxComponent(objectUpdate(this.settings ? this.settings : {}, {
            entries: Resources.countries
                .filter(l => !this.filters || this.filters.includes(l.a2))
                .map(v => {
                    return {
                        label: v.n,
                        value: v.a2
                    }
                }),
            selectedValue: this.value,
            search: this.settings?.search != undefined ? this.settings.search : true
        }));
        this.selectBox.onSelect = entry => this.onSelect(entry.value as string);

        this.clearAll();
        this.render(this.selectBox);
    }

    onSelect(language: string) {

    }
}