/**
 * Lis un fichier audio
 */
export function soundPlay(url: string): HTMLAudioElement {
    try {
        let audio = new Audio(url);
        audio.play();
        return audio;
    } catch (e) {

    }
}