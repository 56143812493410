import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import * as s from "./empty-network.scss";
import {LoaderLightComponent} from "../../loader/light/LoaderLightComponent";
import {CapsuleComponent} from "../../../../products/panel/components/components/capsule/CapsuleComponent";
import {ProductType} from "../../../../models/product/ProductType";
import {Resources} from "../../../../resources/Resources";

export class EmptyNetworkComponent extends Component {
    constructor() {
        super();

        //language=HTML
        this.template = `
            <div class="${s.globalEmptyNetwork}">

                <div style="margin-top:100px;">
                    <div class="${s.flex}">
                        <div class="${s.icon}">
                            ${this.draw(new CapsuleComponent(ProductType.SEDESTRAL))}
                        </div>
                    </div>
                    <div class="${s.flex}">
                        <div class="${s.text}">
                            ${Resources.t("words.unavailableMessage")}
                        </div>
                    </div>
                    <div class="${s.flex}">
                        <div style="position: relative;height: 80px;width: 100%;">
                            ${this.draw(new LoaderLightComponent())}
                        </div>
                    </div>
                    <div class="${s.flex}">
                        <div class="${s.logo}">
                            <div class="${s.capsuleContainer}">
                                ${this.draw(new CapsuleComponent(ProductType.SEDESTRAL))}
                            </div>
                            <div class="${s.name}">
                                Sedestral
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        `;
    }
}