@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsitePresentationLight {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 19.55px;
  flex-direction: row;
  gap: 100px;
  padding: 70px 0px;

  .left {
    height: 100%;

    &.tall {
      width: 50%;
    }

    &.medium {
      width: 60%;
    }

    .article {
      width: 100%;
      height: 100%;

      .title {
        background: var(--grad-black);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 44px;

        @include texts.font("bold");
      }

      .subtitle {
        margin-top: 12px;
        font-size: 18px;

        @include texts.font("semi-bold");
      }

      .more {
        color: var(--black);
        padding: 19px 21px;
        background: var(--grey-sur);
        margin-top: 20px;
        border-radius: 34px;
        width: fit-content;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        font-size: 18px;

        @include texts.font("semi-bold");

        .arrow {
          width: 13px;
          height: 13px;
          margin: 5px 0 0 10px;

          @include icons.ico("black-right-bold");
        }
      }
    }
  }

  .right {
    &.tall {
      width: 50%;
    }

    &.medium {
      width: 40%;
    }

    .visual {
      width: 100%;
      height: 100%;

      .image {
        width: 100%;
        height: 100%;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center !important;

        &.illus-mails-advanced {
          @include icons.ico("illus-mails-advanced");
        }

        &.illus-seo {
          @include icons.ico("illus-seo");
        }

        &.illus-perms {
          @include icons.ico("illus-perms");
        }

        &.illus-funnel {
          @include icons.ico("illus-funnel");
        }
      }
    }
  }

  @media (max-width: 1050px) {
    .left {
      .article {
        .title {
          font-size: 38px;
        }

        .subtitle {
          font-size: 16px;
        }

        .more {
          padding: 17px 19px;
          font-size: 16px;

          .arrow {
            margin: 3px 0 0 10px;
          }
        }

        &.illus-funnel {
          @include icons.ico("illus-funnel");
        }
      }
    }
  }

  @media (max-width: 750px) {
    gap: 50px;

    .left {
      .article {
        .title {
          font-size: 30px;
        }

        .subtitle {
          font-size: 16px;
        }

        .more {
          padding: 17px 19px;
          font-size: 16px;

          .arrow {
            margin: 3px 0 0 10px;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 0;
    padding: 0;

    .left {
      width: 100% !important;

      .article {
        .title {
          font-size: 25px;
        }
      }
    }

    .right {
      height: 190px;
      width: 100% !important;
      margin-top: 20px;

      .visual {
        .image {
          height: 100%;
          margin: auto;
        }
      }
    }
  }
}
