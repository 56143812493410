import {
    VisualModalComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/modal/VisualModalComponent";
import {OfferBlockedComponent} from "../OfferBlockedComponent";
import {OfferProductSolutionType} from "../../../../../../../models/offer/product/solution/OfferProductSolutionType";
import {ProductType} from "../../../../../../../models/product/ProductType";
import {Resources} from "../../../../../../../resources/Resources";

export class OfferBlockedModalComponent extends VisualModalComponent {
    constructor(currentQuantity: number, limitQuantity: number, solutionType: OfferProductSolutionType, productType: ProductType) {
        super();
        this.name = Resources.t("words.offerBlocked");
        this.modalStyle = "width: 300px;";
        this.modalTemplate = this.draw(new OfferBlockedComponent(currentQuantity, limitQuantity, solutionType, productType));
    }
}