import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-black-grid.scss";
import {NetworkVendor} from "../../../../../../network/NetworkVendor";
import {IWebsiteBlackGrid, IWebsiteBlackGridEntry} from "./types/IWebsiteBlackGrid";
import {isMobile} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/IsMobile";

export class WebsiteBlackGridComponent extends Component {

    public grid: IWebsiteBlackGrid;

    constructor(grid: IWebsiteBlackGrid) {
        super();
        this.grid = grid;

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteBlackGrid}"></div>`;
    }

    commit() {
        this.grid.lines.forEach(value => this.renderLine(value.entries));

        super.commit();
    }

    renderLine(entries: IWebsiteBlackGridEntry[]) {
        let line = this.append(`<div class="${s.line}"></div>`);

        entries.forEach((entry) => {
            //language=HTML
            let entryContainer = line.append(`
                <div class="${s.entry} ${s[entry.color]} ${s[entry.size]} ${s[entry.shadowPosition]}">
                    <div class="${s.names}">
                        <div class="${s.title} ${entry.step ? s.hasStep : ``}">
                            ${entry.step ? `<div class="${s.step}"><div class="${s.stepShadow}"></div><div class="${s.text}">${entry.step}</div></div>` : ``}
                            ${entry.ai ? `<div class="${s.ai}"><div class="${s.aiShadow}"></div><div class="${s.aiIcon}"></div></div>` : ``}
                            ${entry.title}
                        </div>
                        <div class="${s.description}">
                            ${entry.description}
                        </div>
                    </div>

                    ${entry.type == "image" ? `<div class="${s.imageContainer}"><img class="${s.image}" src="${entry.image}"/></div>` : ``}
                    ${entry.type == "icon" ? `<div class="${s.iconContainer}"><div class="${s.icon} ${s[entry.icon]}"></div></div>` : ``}
                    <div class="${s.shadow}"></div>
                </div>
            `);

            let imageContainer = entryContainer.el(s.imageContainer);
            let iconContainer = entryContainer.el(s.iconContainer);
            entryContainer.isBelowScroll((isVisible) => {
                if (!isVisible) {
                    if (!imageContainer.isNull())
                        imageContainer.translate({opacity: 0, translateY: 60, delay: 200});
                    if (!iconContainer.isNull())
                        iconContainer.translate({opacity: 0, translateY: 60, delay: 200});
                } else {
                    if (!imageContainer.isNull())
                        imageContainer.translate({opacity: 1, translateY: 0, delay: 250});
                    if (!iconContainer.isNull())
                        iconContainer.translate({opacity: 1, translateY: 0, delay: 250});
                }
            }, true);
        });
    }

}