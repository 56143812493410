/**
 * Retourne les codes de mois entre deux dates
 */
import {dateCountDaysBetweenDates} from "./DateCountDaysBetweenDates";

import {dateToCompare} from "./DateToCompare";

export function dateMonthsBetweenDates(startTime: number, endTime: number): number[][] {
    let months = [];
    let from = dateToCompare(new Date(startTime));
    let to = dateToCompare(new Date(endTime), true);
    let daysCount = dateCountDaysBetweenDates(from, to);
    for (let i = 0; i < daysCount; i++) {
        if (months.filter(value => value[0] == from.getFullYear() && value[1] == from.getMonth())[0] == undefined) {
            months.push([from.getFullYear(), from.getMonth()]);
        }

        from.setDate(from.getDate() + 1);
    }
    return months;
}