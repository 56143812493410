/**
 * Retourne retourne l'année et le code de semaine
 */
export function dateWeekOfTheYear(d: Date) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    //console.log(d.getUTCDate() + 4 - (d.getUTCDay() || 2))
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 3));
    let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // @ts-ignore
    let weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    return [d.getUTCFullYear(), weekNo];

}