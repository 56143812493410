@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentWebsiteContentClientService {
  .see {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .subtitle {
      width: 80%;
      font-size: 34px;
      @include texts.font("bold");
      background: var(--grad-black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .title {
      width: 80%;
      font-size: 44px;
      @include texts.font("bold");
      background: var(--grad-black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  @media (max-width: 480px) {
    .see {
      margin: 0;
      gap: 10px;

      .subtitle {
        width: 100%;
        font-size: 22px;
      }

      .title {
        width: 100%;
        font-size: 28px;
      }
    }
  }
}
