import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./channel-icon.scss";
import {ChannelType, channelTypeName} from "../../../../../../models/channel/ChannelType";

export class ChannelIconComponent extends Component {
    constructor(type: ChannelType, black?: boolean) {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentChannelIcon} ${s[`channel${channelTypeName(type)}${black ? "Black" : ""}`]}"></div>`;
    }
}