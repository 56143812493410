import * as s from "./notfound.scss";
import {Resources} from "../../../../../../resources/Resources";
import {PanelPageComponent} from "../../PanelPageComponent";

export class NotFoundPage extends PanelPageComponent {

    constructor() {
        super();
        this.title = Resources.t("words.notFound");
        this.status.online = false;
    }

    init(): void {
        //language=HTML
        this.template = `
            <div class="${s.pageNotfound}">
                <div class="${s.textSide}">
                    <div class="${s.text}">
                        <div class="${s.found}">
                            404
                        </div>
                        <div class="${s.title}">
                            ${Resources.t("words.youLandedInTheWrongPlace")}
                        </div>
                        <div class="${s.description}">
                            ${Resources.t("words.theRequestedPageDoesNotExistOrHasBeenRemoved")}
                        </div>
                    </div>
                </div>

                <div class="${s.illustration}">
                    <div class="${s.icon}"></div>
                </div>
            </div>
        `;
    }

    onHash(): void {
        console.log("on hash")
    }

    remove() {
        super.remove();
    }
}
