import {IAccountPreferencesModel} from "../../../models/account/preference/IAccountPreferencesModel";
import {observable} from "@nx-js/observer-util";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {Services} from "../../Services";
import {ProductType} from "../../../models/product/ProductType";

export class AccountPreferencesService {


    public static preferences: IAccountPreferencesModel[] = observable([]);

    public static dispose(): void {
        this.preferences = observable([]);
    }

    public static init(): void {
    }

    /**
     * rest
     */
    public static async update(accountId: string, language: string, hourClock: string, component?: INetworkComponent): Promise<IAccountPreferencesModel> {
        let request = await Network.post(ProductType.PANEL, "/account/preferences/update",
            {
                accountId: accountId,
                language: language,
                hourClock: hourClock
            }, component);

        if (request.status == HttpStatus.OK) {
            return AccountPreferencesService.store(request.data);
        }

        return undefined;
    }

    public static async updateNotifications(accountId: string, data: any, component?: INetworkComponent): Promise<IAccountPreferencesModel> {
        let request = await Network.putJson(ProductType.PANEL, `/account/preferences/notifications/${accountId}`, data, component);
        if (request.status == HttpStatus.OK) {
            return AccountPreferencesService.store(request.data);
        }

        return undefined;
    }

    /**
     * store
     */

    public static storeAll(preferences: IAccountPreferencesModel[]): IAccountPreferencesModel[] {
        for (let key in preferences) {
            preferences[key] = this.store(preferences[key]);
        }

        return Services.storeAll(preferences);
    }

    public static store(preference: IAccountPreferencesModel): IAccountPreferencesModel {
        if (preference != undefined) {
            return Services.store("accountId", this.preferences, preference);
        }
    }

}