import {
    VisualModalComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/modal/VisualModalComponent";
import {ContactCreatorComponent} from "../ContactCreatorComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {IContactModel} from "../../../../../../../models/contact/IContactModel";

export class ContactCreatorModalComponent extends VisualModalComponent {

    public creator: ContactCreatorComponent;

    constructor(contact?: IContactModel) {
        super();
        this.name = Resources.t("words.contactCreate");
        this.creator = new ContactCreatorComponent(contact);
        this.modalTemplate = this.draw(this.creator);
        this.modalStyle = "width:300px;";
        this.headMargin = true;

        this.creator.onCancel = () => this.close();
        this.creator.onCreate = () => this.close();
    }
}