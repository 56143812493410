import {OfferProductSolutionType} from "../../../../../../../models/offer/product/solution/OfferProductSolutionType";

export interface IOfferContentsTable {
    name: string,
    description: string,
    addToContents?: boolean,
    entries: IOfferContentsTableEntry[],
    solutionType: OfferProductSolutionType,
    type: "bool" | "textBool" | "text",
    boolMin?: number,
    icons?: IOfferContentsTableIcon[]
}

export interface IOfferContentsTableEntry {
    templateId: string,
    value?: any
}

export enum IOfferContentsTableIcon {
    WORDPRESS,
    SHOPIFY,
    WIX,
    PRESTASHOP
}