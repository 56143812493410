export interface IFormatType {
    name: string,
    power: number
}

export const FormatType = {
    BYTES: {name: 'Bytes', power: 1},
    KO: {name: 'Ko', power: 1024},
    MO: {name: 'Mo', power: 1048576},
    GO: {name: 'Go', power: 1073741824},
    TO: {name: 'To', power: 1099511627776}
}