import {observable} from "@nx-js/observer-util";
import {Services} from "../../../Services";
import {
    IInboxShortcutAttachmentModel
} from "../../../../models/inbox/shortcut/attachment/IInboxShortcutAttachmentModel";
import {AttachmentType} from "../../../../models/enums/AttachmentType";
import {FileService} from "../../../file/FileService";
import {PreviewService} from "../../../preview/PreviewService";
import {IFileModel} from "../../../../models/file/IFileModel";
import {IPreviewModel} from "../../../../models/preview/IPreviewModel";

export class InboxShortcutAttachmentService {
    public static attachments: IInboxShortcutAttachmentModel[] = observable([]);

    public static init() {

    }

    public static dispose() {
        this.attachments = observable([]);
    }

    public static storeAll(attachments: IInboxShortcutAttachmentModel[]): IInboxShortcutAttachmentModel[] {
        for (let key in attachments)
            attachments[key] = this.store(attachments[key]);

        return Services.storeAll(attachments);
    }

    public static store(attachment: IInboxShortcutAttachmentModel): IInboxShortcutAttachmentModel {
        if (attachment.type == AttachmentType.FILE) {
            attachment.content = FileService.store(attachment.content as IFileModel);
        }

        if (attachment.type == AttachmentType.PREVIEW) {
            attachment.content = PreviewService.store(attachment.content as IPreviewModel);
        }

        attachment = Services.store("id", this.attachments, attachment);
        return attachment;
    }
}