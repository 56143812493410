import {fileIsImage} from "./FileIsImage";
import {fileIsGif} from "./FileIsGif";

import {fileIsVideo} from "./FileIsVideo";

/**
 * Vérifie si le type est une file
 */
export function fileIsFile(type: string): boolean {
    return !fileIsImage(type) && !fileIsGif(type) && !fileIsVideo(type);

}