import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./contact-ban-creator.scss";
import {
    ButtonsSaveComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/buttons/save/ButtonsSaveComponent";
import {IContactModel} from "../../../../../../../models/contact/IContactModel";
import {AvatarComponent} from "../../../avatar/AvatarComponent";
import {
    DatePickerTooltipComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/date/picker/tooltip/DatePickerTooltipComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {
    dateTinyString
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/DateTinyString";
import {
    dateInput
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/DateInput";

export class ContactBanCreatorComponent extends Component {
    public contact: IContactModel;


    public dateInput: Component;
    public dateContainer: Component;
    public closeButton: Component;
    public buttonsComponent: ButtonsSaveComponent;
    public agoContainer: Component;
    public textArea: Component;

    public currentDate: Date;

    constructor(contact: IContactModel) {
        super();
        this.contact = contact;
        this.buttonsComponent = new ButtonsSaveComponent();
        this.currentDate = new Date(Date.now());
        this.currentDate.setDate(this.currentDate.getDate() + 14);
        //language=HTML
        this.template = `
            <div class="${s.componentContactBanCreator}">
                <div class="${s.head}">
                    <div class="${s.avatarContainer}">
                        ${this.draw(new AvatarComponent(this.contact.avatar).setActive(this.contact))}
                    </div>
                    <div class="${s.name}">
                        ${Resources.t('words.banContactTitle')} ${this.reactive(() => contact.name)}
                    </div>

                    <div class="${s.close}">
                        <div class="${s.icon}"></div>
                    </div>
                </div>
                <textarea class="${s.textarea}" placeholder="${Resources.t('words.commentLabel')}"></textarea>
                <div class="${s.time}">
                    ${Resources.t('words.expirationLabel')}
                    <div class="${s.ago}"></div>
                </div>
                <div class="${s.dateContainer}">
                    <input class="${s.date}" type="datetime-local" value="${dateInput(this.currentDate)}"/>
                </div>
                <div class="${s.buttonsContainer}">
                    ${this.draw(this.buttonsComponent)}
                </div>
            </div>
        `;

    }

    commit() {
        this.dateInput = this.el(s.date);
        this.closeButton = this.el(s.close);
        this.dateContainer = this.el(s.dateContainer);
        this.agoContainer = this.el(s.ago);
        this.textArea = this.el(s.textarea);

        let datePicker;
        this.dateContainer.onClick(() => {
            if (datePicker != undefined && !datePicker.isNull()) {
                datePicker.dispose();
            } else {
                datePicker = new DatePickerTooltipComponent(this.dateContainer, {
                    firstDate: this.currentDate,
                    hours: true
                });
                datePicker.screen(false);
                datePicker.create();
                datePicker.onValid = (date) => {
                    datePicker.dispose();
                    this.currentDate = date;
                    this.dateInput.setValue(dateInput(date));
                    this.renderAgo();
                }
            }
        });

        this.closeButton.onClick(() => this.onClose());
        this.buttonsComponent.onCancel = () => this.onClose();
        this.buttonsComponent.onSave = () => this.onCreate(this.currentDate, this.textArea.getValue());

        this.renderAgo();
        super.commit();
    }


    renderAgo() {
        this.agoContainer.setHtml(dateTinyString((this.currentDate.getTime() - Date.now()) / 1000));
    }

    /**
     * events
     */

    onCreate(date: Date, message: string) {

    }

    onClose() {

    }
}