import * as s from "../website-content.scss";
import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {NetworkVendor} from "../../../../../../../network/NetworkVendor";
import {WebsiteBlackPresentationComponent} from "../../../black/presentation/WebsiteBlackPresentationComponent";
import {WebsiteBlackStepsComponent} from "../../../black/steps/WebsiteBlackStepsComponent";
import {WebsiteBlackTextComponent} from "../../../black/text/WebsiteBlackTextComponent";
import {WebsiteBlackDivideComponent} from "../../../black/divide/WebsiteBlackDivideComponent";
import {WebsiteBlackWordsComponent} from "../../../black/words/WebsiteBlackWordsComponent";
import {Resources} from "../../../../../../../resources/Resources";

export class WebsiteContentBlogWritingComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackPresentationComponent({
                            startText: Resources.t("words.beginFree"),
                            title: Resources.t("words.sedestralTrafficGeneration"),
                            description: Resources.t("words.sedestralArticleGenerationDescription"),
                            people: false,
                            center: true
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackStepsComponent({
                            title: Resources.t("words.generationSteps"),
                            icon: "seo",
                            center: true,
                            contents: [
                                {
                                    name: Resources.t("words.keyword"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-e.mp4"),
                                    duration: 14000
                                },
                                {
                                    name: Resources.t("words.instructions"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-f.mp4"),
                                    duration: 8000
                                },
                                {
                                    name: Resources.t("words.plan"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-g.mp4"),
                                    duration: 21000
                                },
                                {
                                    name: Resources.t("words.writing"),
                                    type: "video",
                                    path: NetworkVendor.image("blog-h.mp4"),
                                    duration: 15000
                                },
                            ]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackTextComponent({
                            text: Resources.t("words.fiveMinuteArticle"),
                            size: "sizeBig"
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackDivideComponent({
                            grid: {
                                lines: [{
                                    entries: [{
                                        step: 1,
                                        title: Resources.t("words.googleResearch"),
                                        description: Resources.t("words.googleResearchDescription"),
                                        type: "icon",
                                        icon: "google",
                                        size: "sizeMid",
                                        color: "colorWhite",
                                        shadowPosition: "shadowNone"
                                    }, {
                                        step: 2,
                                        title: Resources.t("words.detailedSiteAnalysis"),
                                        description: Resources.t("words.detailedSiteAnalysisDescription"),
                                        type: "icon",
                                        icon: "chart",
                                        size: "sizeMid",
                                        color: "colorWhite",
                                        shadowPosition: "shadowLeftCenter"
                                    }]
                                }, {
                                    entries: [{
                                        step: 3,
                                        title: Resources.t("words.createPlanProvideGuidelines"),
                                        description: Resources.t("words.createPlanProvideGuidelinesDescription"),
                                        type: "icon",
                                        icon: "plan",
                                        size: "sizeMid",
                                        color: "colorWhite",
                                        shadowPosition: "shadowBottomCenter"
                                    }, {
                                        step: 4,
                                        title: Resources.t("words.generateContentFromMetrics"),
                                        description: Resources.t("words.generateContentFromMetricsDescription"),
                                        type: "icon",
                                        icon: "write",
                                        size: "sizeMid",
                                        color: "colorWhite",
                                        shadowPosition: "shadowNone"
                                    }]
                                }, {
                                    entries: [{
                                        step: 5,
                                        title: Resources.t("words.applySeoPractices"),
                                        description: Resources.t("words.applySeoPracticesDescription"),
                                        type: "icon",
                                        icon: "seo",
                                        size: "sizeMid",
                                        color: "colorWhite",
                                        shadowPosition: "shadowBottomCenter"
                                    }, {
                                        step: 6,
                                        title: Resources.t("words.searchSuitableImages"),
                                        description: Resources.t("words.searchSuitableImagesDescription"),
                                        type: "icon",
                                        icon: "picture",
                                        size: "sizeMid",
                                        color: "colorWhite",
                                        shadowPosition: "shadowNone"
                                    }]
                                }, {
                                    entries: [{
                                        step: 7,
                                        title: Resources.t("words.enhanceAuthenticity"),
                                        description: Resources.t("words.enhanceAuthenticityDescription"),
                                        type: "icon",
                                        icon: "unique",
                                        size: "sizeMid",
                                        color: "colorWhite",
                                        shadowPosition: "shadowNone"
                                    }, {
                                        step: 8,
                                        title: Resources.t("words.readyArticleIntent"),
                                        description: Resources.t("words.readyArticleIntentDescription"),
                                        type: "icon",
                                        icon: "ready",
                                        size: "sizeMid",
                                        color: "colorYellow",
                                        shadowPosition: "shadowLeftCenter"
                                    }]
                                }, {
                                    entries: [{
                                        title: Resources.t("words.nativeIntegration"),
                                        description: Resources.t("words.nativeIntegrationDescription"),
                                        type: "icon",
                                        icon: "funnel",
                                        size: "sizeLargeCenter",
                                        color: "colorPurple",
                                        shadowPosition: "shadowMid"
                                    }]
                                }]
                            }
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteBlackWordsComponent())}
                    </div>
                </div>
            </div>
        `;
    }
}
