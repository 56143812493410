export class NotificationBadger {
    private canvas: HTMLCanvasElement;
    private src: string;
    private ctx: CanvasRenderingContext2D;
    private faviconSize: number;
    private badgeSize: number;
    private size: number;
    private offset: any;
    private radius: number;
    private backgroundColor: string;
    private color: string;
    private img: any;
    private position: string;
    private faviconEL: HTMLElement = document.querySelector("link[rel$=icon]");

    constructor() {
        this.backgroundColor = "#f00";
        this.color = "#fff";
        this.size = 0.6;
        this.position = "ne";
        this.radius = 8;
        this.src = "";
        this.canvas = document.createElement("canvas");
        this.src = this.src || this.faviconEL != undefined ? (this.faviconEL.getAttribute("href")) : undefined;
        this.ctx = this.canvas.getContext("2d");
    }

    private _value: number;

    get value() {
        return this._value;
    }

    set value(val) {
        this._value = val;
        this.update();
    }

    get dataURL() {
        return this.canvas.toDataURL();
    }

    _drawIcon() {
        if (this.ctx != undefined) {
            this.ctx.clearRect(0, 0, this.faviconSize, this.faviconSize);
            this.ctx.drawImage(this.img, 0, 0, this.faviconSize, this.faviconSize);
        }
    }

    _drawShape() {
        if (this.offset != undefined) {
            const r = this.radius;
            const xa = this.offset.x;
            const ya = this.offset.y;
            const xb = this.offset.x + this.badgeSize;
            const yb = this.offset.y + this.badgeSize;
            this.ctx.beginPath();
            this.ctx.moveTo(xb - r, ya);
            this.ctx.quadraticCurveTo(xb, ya, xb, ya + r);
            this.ctx.lineTo(xb, yb - r);
            this.ctx.quadraticCurveTo(xb, yb, xb - r, yb);
            this.ctx.lineTo(xa + r, yb);
            this.ctx.quadraticCurveTo(xa, yb, xa, yb - r);
            this.ctx.lineTo(xa, ya + r);
            this.ctx.quadraticCurveTo(xa, ya, xa + r, ya);
            this.ctx.fillStyle = this.backgroundColor;
            this.ctx.fill();
            this.ctx.closePath();
        }
    }

    _drawVal() {
        const margin = (this.badgeSize * 0.18) / 2;
        this.ctx.beginPath();
        this.ctx.textBaseline = "middle";
        this.ctx.textAlign = "center";
        this.ctx.font = `bold ${this.badgeSize * 0.82}px Arial`;
        this.ctx.fillStyle = this.color;
        this.ctx.fillText("" + this.value, this.badgeSize / 2 + this.offset.x, this.badgeSize / 2 + this.offset.y + margin);
        this.ctx.closePath();
    }

    _drawFavicon() {
        this.faviconEL.setAttribute("href", this.dataURL);
    }

    _draw() {
        if (this.offset != undefined) {
            this._drawIcon();
            if (this.value) {
                this._drawShape();
            }
            if (this.value) {
                this._drawVal();
            }
            this._drawFavicon();
        }
    }

    _setup() {
        this.faviconSize = this.img.naturalWidth;
        this.badgeSize = this.faviconSize * this.size;
        this.canvas.width = this.faviconSize;
        this.canvas.height = this.faviconSize;
        const sd = this.faviconSize - this.badgeSize;
        const sd2 = sd / 2;
        this.offset = {
            n: {x: sd2, y: 0},
            e: {x: sd, y: sd2},
            s: {x: sd2, y: sd},
            w: {x: 0, y: sd2},
            nw: {x: 0, y: 0},
            ne: {x: sd, y: 0},
            sw: {x: 0, y: sd},
            se: {x: sd, y: sd},
        }[this.position];
    }

    update() {
        this._value = Math.min(99, parseInt("" + this._value, 10));
        if (this.img) {
            this._draw();
        } else {
            this.img = new Image();
            this.img.addEventListener("load", () => {
                this._setup();
                this._draw();
            });
            this.img.src = this.src;
        }
    }
}