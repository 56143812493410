import {TrackingRedirectLogSearch} from "../../models/tracking/redirect/TrackingRedirectLogSearch";
import {INetworkComponent} from "../../network/types/INetworkComponent";
import {ITrackingRedirectModel} from "../../models/tracking/redirect/ITrackingRedirectModel";
import {Network} from "../../network/Network";
import {ProductType} from "../../models/product/ProductType";
import {HttpStatus} from "../../network/status/HttpStatus";

export class TrackingLogService {


    public static dispose(): void {

    }

    public static init(): void {

    }

    /**
     * rest
     */

    public static async searchLogs(search: TrackingRedirectLogSearch, component?: INetworkComponent): Promise<ITrackingRedirectModel[]> {
        let request = await Network.postJson(ProductType.PANEL, `/tracking/logs`, search, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
    }
}