import * as s from "./website-head-submenu-content.scss";
import {WebsiteHeadSubmenuContentComponent} from "./WebsiteHeadSubmenuContentComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {Network} from "../../../../../../../network/Network";
import {WebsiteHeadSubmenuContentProductsComponent} from "./WebsiteHeadSubmenuContentProductsComponent";
import {WebsiteHeadSubmenuContentPricingComponent} from "./WebsiteHeadSubmenuContentPricingComponent";
import {WebsiteHeadSubmenuContentScheduleComponent} from "./WebsiteHeadSubmenuContentScheduleComponent";

export class WebsiteHeadSubmenuContentMobileComponent extends WebsiteHeadSubmenuContentComponent {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteHeadSubmenuContent}">
                ${this.draw(new WebsiteHeadSubmenuContentProductsComponent())}
                ${this.draw(new WebsiteHeadSubmenuContentPricingComponent())}
                ${this.draw(new WebsiteHeadSubmenuContentScheduleComponent())}

                <div class="${s.buttons}">
                    <a href="${Network.router.pages.getPanelUrl('signup')}" target="_blank">
                        <div class="${s.login}">
                            ${Resources.t("words.beginFree")}
                        </div>
                    </a>
                    <a class="${s.case}" href="${Network.router.pages.getPanelUrl('login')}"
                       target="_blank">
                        <div class="${s.start} ${s.login}">
                            <div></div>
                            <div class="${s.content}">
                                ${Resources.t("words.login")}
                            </div>
                        </div>
                    </a>
                    <a class="${s.case}" href="${Network.router.pages.getPanelUrl('signup')}"
                       target="_blank">
                        <div class="${s.start}">
                            <div></div>
                            <div class="${s.content}">
                                ${Resources.t("words.beginFree")}
                                <div class="${s.icon}"></div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>`;
    }

    onOpen() {
        let columns = this.els(s.column);
        let openedColumn;
        columns.forEach(column => {
            this.closeColumn(column);

            let casesContainer = column.el(s.cases);
            let cases = casesContainer.els(s.case);
            cases?.forEach(caseElem => {
                if (caseElem.getHTMLElement().parentElement && caseElem.getHTMLElement().parentElement['href'] == window.location.href) {
                    openedColumn = column;
                    this.openColumn(column);
                }
            });
        });

        if (!openedColumn) {
            this.openColumn(columns[0]);
        }
    }
}