export enum OfferProductTemplateType {
    HELP_CENTER_FREE = 1,
    HELP_CENTER_BASIC = 2,
    HELP_CENTER_AGENCY = 3,
    BLOG_FREE = 4,
    BLOG_BASIC = 5,
    BLOG_PRO = 6,
    INBOX = 7,
    PUBLISHER = 8,
    CONTACTS = 9,
    BLOG_ENTERPRISE = 10
}