import * as s from "./site-creator-create.scss";
import {
    StepsComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/steps/StepsComponent";
import {
    ButtonsSaveComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/buttons/save/ButtonsSaveComponent";
import {SiteService} from "../../../../../../../services/site/SiteService";
import {Network} from "../../../../../../../network/Network";
import {Resources} from "../../../../../../../resources/Resources";

export class SiteCreatorCreateComponent extends StepsComponent {
    private buttons: ButtonsSaveComponent;

    constructor() {
        super();
        this.buttons = new ButtonsSaveComponent();

        //language=HTML
        this.template = `
            <div class="${s.componentSiteCreatorCreate}">
                <input class="${s.name}" placeholder="${Resources.t("words.workspaceName")}"/>
                <div class="${s.buttons}">
                    ${this.draw(this.buttons)}
                </div>
            </div>
        `;


    }

    commit() {
        this.buttons.onSave = async () => {
            if (!this.buttons.saveLoading) {
                this.buttons.setLoading();
                let name = this.el(s.name).getValue();

                if (name.length < 3) {
                    return this.error(Resources.t("words.workspaceNameTooShort"));
                }

                let site = await SiteService.create(name, this);
                if (site) {
                    await SiteService.select(site.id, this);
                } else {
                    this.buttons.remLoading();
                }
            }
        }
        super.commit();
    }

    error(description: string) {
        Network.router.static.components.notifications.notify(Resources.t("words.unableToContinue"), description, 8000);
        this.buttons.remLoading();
    }

}