import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";

export class WebsiteVideoComponent extends Component {
    constructor(url: string) {
        super();
        //language=HTML
        this.template = `
            <div style="width: 100%; overflow: hidden;box-shadow: 0 0 73.91px var(--black-alpha-middle);border-radius: 18px;position: relative;">
                <div style="height: 6px;width: 100%;background: #ffffff;position: absolute;bottom: 0;"></div>
                <video class="video" autoplay loop muted playsinline
                       style=" height: auto;width: 0;margin-top: -1px;">
                    <source src="${url}" type="video/mp4">
                    Votre navigateur ne supporte pas les vidéos HTML5.
                </video>
            </div>
        `;
    }

    commit() {
        let video = this.el("video").getHTMLElement();
        video.addEventListener('canplay', () => {
            video.style.width = 'calc(100% + 1px)';
        });

        super.commit();
    }

}