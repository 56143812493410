import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-slider-left.scss";
import {Network} from "../../../../../../../network/Network";

export class WebsiteSliderLeftComponent extends Component {

    public entries: { icon: string, title: string, desc: string, image: string, width?: string, height?: string }[];
    public entriesComponents: Component[];

    public leftContainer: Component;
    public contentContainer: Component;

    constructor(title: string, entries: {
        icon: string,
        title: string,
        desc: string,
        image: string,
        width?: string,
        height?: string
    }[]) {
        super();
        this.entries = entries;
        this.entriesComponents = [];

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteSliderLeft}">
                <h3 class="${s.title}">
                    ${title}
                </h3>
                <div class="${s.slider}">
                    <div class="${s.left}"></div>
                    <div class="${s.content}"></div>
                </div>
            </div>
        `;
    }

    commit() {
        this.leftContainer = this.el(s.left);
        this.contentContainer = this.el(s.content);

        this.entries.forEach(value => {
            //language=HTML
            let entry = this.leftContainer.append(`
                <div class="${s.entry} ${s.selected}">
                    <div class="${s.titles}">
                        <div class="${s.icon} ${s[value.icon]}"></div>
                        <h4 class="${s.title}">
                            ${value.title}
                        </h4>
                    </div>
                    <p class="${s.description}">
                        ${value.desc}
                    </p>
                </div>
            `);
            entry.onClick(() => this.select(this.entries.indexOf(value)));
            this.entriesComponents.push(entry);
        });

        this.select(0);
        super.commit();
    }

    select(index: number) {
        this.contentContainer.clearAll();
        this.entriesComponents.forEach(value => value.removeClass(s.selected));

        let value = this.entries[index];
        let entry = this.entriesComponents[index];

        entry.addClass(s.selected);
        this.contentContainer.append(`<img src="${value.image}" class="${s.picture}" alt="${value.title}" height="${value.height}" width="${value.width}"/>`);
    }
}