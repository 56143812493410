import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {StatisticsCardSimpleComponent} from "../../statistics/cards/simple/StatisticsCardSimpleComponent";
import * as s from "./contact-preview.scss";
import {IContactModel} from "../../../../../../models/contact/IContactModel";
import {ContactBansTooltipComponent} from "../bans/tooltip/ContactBansTooltipComponent";
import {PermissionsService} from "../../../../../../services/permissions/PermissionsService";
import {ContactService} from "../../../../../../services/contact/ContactService";
import {Network} from "../../../../../../network/Network";
import {AvatarPromptComponent} from "../../avatar/prompt/AvatarPromptComponent";
import {Resources} from "../../../../../../resources/Resources";
import {IContactPreview} from "./types/IContactPreview";
import {ContactStateComponent} from "../state/ContactStateComponent";
import {ContactState} from "../../../../../../models/contact/ContactState";
import {
    txtIsValidMail
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/TxtIsValidMail";

export class ContactPreviewComponent extends Component {

    public contactSavedMail: string;

    public menuContainer: Component;
    public imageContainer: Component;
    public stateContainer: Component;

    public nameEdition: Component;
    public mailEdition: Component;

    public settings: IContactPreview;

    constructor(settings: IContactPreview) {
        super();
        this.settings = settings;
        this.contactSavedMail = this.settings.contact.email;

        //language=HTML
        this.template = `
            <div class="${s.componentContactPreview}">
                <div class="${s.profile}">
                    <div class="${s.profileImage}"></div>
                    <div class="${s.texts}">
                        <input class="${s.name}" spellcheck="false" value=""/>
                        <input class="${s.identifier}" spellcheck="false" value=""/>
                    </div>
                </div>
                <div class="${s.menu}"></div>
                <div class="${s.state}"></div>
            </div>
        `;
    }

    commit() {
        this.nameEdition = this.el(s.name);
        this.mailEdition = this.el(s.identifier);
        this.menuContainer = this.el(s.menu);
        this.imageContainer = this.el(s.profileImage);
        this.stateContainer = this.el(s.state);

        this.renderImage();
        this.renderName();
        this.renderMail();

        if (this.settings.menu) {
            this.renderMenu();
        }

        if (this.settings.state) {
            this.onReactiveObserve(() => {
                if (this.settings.contact.state != ContactState.CONFIRMED) {
                    this.renderState();
                } else {
                    this.removeState();
                }
            });
        }


        this.renderStats();

        super.commitTooltips();
        super.commit();
    }

    renderName() {
        this.onReactiveObserve(() => {
            if (!this.nameEdition.hasClass(s.active)) {
                this.nameEdition.setValue(this.settings.contact.name);
            }
        });
        if (!PermissionsService.contactEditData()) {
            this.nameEdition.setAttribute("disabled", "true");
        } else {
            this.initEdition(this.nameEdition, () => {
                this.settings.contact.name = this.nameEdition.getValue();
                return true;
            });
        }
    }

    renderMail() {
        this.onReactiveObserve(() => {
            if (!this.mailEdition.hasClass(s.active)) {
                this.mailEdition.setValue(this.settings.contact.email);
            }
        });

        if (!PermissionsService.contactEditData()) {
            this.mailEdition.setAttribute("disabled", "true");
        } else {
            this.initEdition(this.mailEdition, () => {
                if (this.mailEdition.getValue().length > 0) {
                    if (!txtIsValidMail(this.mailEdition.getValue())) {
                        Network.router.static.components.notifications.notify(
                            Resources.t('words.warning'),
                            Resources.t('words.validEmail'),
                            3000
                        );

                        this.mailEdition.setValue("");
                        return false;
                    }

                    this.settings.contact.email = this.mailEdition.getValue();
                }

                return true;
            });
        }
    }

    renderImage() {
        let promptComponent = new AvatarPromptComponent(this.settings.contact.avatar);

        this.imageContainer.render(promptComponent);
        promptComponent.classMini();
        //this.imageContainer.render(new AvatarComponent(this.contact.avatar).setActive(this.contact));
    }

    renderState() {
        this.stateContainer.clearAll();
        this.stateContainer.render(new ContactStateComponent(this.settings.contact));
        this.stateContainer.displayShow();
    }

    removeState() {
        this.stateContainer.clearAll();
        this.stateContainer.displayHide();
    }

    initEdition(editionComponent, onEdit: () => boolean) {
        editionComponent.addClass(s.editable);
        editionComponent.onClick(() => editionComponent.addClass(s.active));
        editionComponent.onOutsideClick(async () => {
            if (editionComponent.hasClass(s.active)) {
                editionComponent.removeClass(s.active);
                if (onEdit()) {
                    await this.update(this.settings.contact.name, this.settings.contact.email);
                }
            }
        });
        editionComponent.onHotKey("Enter", async () => {
            if (editionComponent.hasClass(s.active)) {
                editionComponent.removeClass(s.active);
                if (onEdit()) {
                    await this.update(this.settings.contact.name, this.settings.contact.email);
                }
                editionComponent.getHTMLElement().blur();
            }
        });
    }

    renderMenu() {
        //language=HTML
        this.menuContainer.append(`
            <div class="${s.buttons}">
                <div style="${PermissionsService.d(PermissionsService.contactBan())}"
                     class="${s.button} ${s.bans}">
                    <div class="${s.text}">${Resources.t('words.block')}</div>
                    <div class="${s.icon}"></div>
                </div>
                <div class="${s.button} ${s.maximize}">
                    <div class="${s.text}">${Resources.t('words.profile')}</div>
                    <div class="${s.icon}"></div>
                </div>
            </div>
        `);


        let bansButton = this.menuContainer.el(s.bans);
        bansButton.onClick(() => {
            let tooltip = new ContactBansTooltipComponent(bansButton, this.settings.contact);
            tooltip.screen(false);
            tooltip.create();
            tooltip.banComponent.requireDispose = () => tooltip.dispose();
        });

        let maximizeButton = this.menuContainer.el(s.maximize);
        maximizeButton.onClick(() => Network.router.pages.redirect(`/contacts/c/${this.settings.contact.id}`));
    }

    renderStats() {
        this.render(new StatisticsCardSimpleComponent({
            firstReactive: this.reactive(() => "" + this.settings.contact.conversationsCount),
            firstName: "conversations",
            secondName: "sessions",
            secondReactive: this.reactive(() => "" + this.settings.contact.sessionsCount)
        }));
    }

    async update(name: string, mail: string): Promise<IContactModel> {
        let contact = await ContactService.update(this.settings.contact.id, name, mail, this);
        if (contact) {
            this.contactSavedMail = contact.email;
        } else {
            this.mailEdition.setValue(this.contactSavedMail);
        }

        return contact;
    }
}