import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {ProductType} from "../../../models/product/ProductType";
import {IAccountLogModel} from "../../../models/account/log/IAccountLogModel";
import {AccountLogSearchOutgoing} from "../../../models/account/log/AccountLogSearchOutgoing";
import {HttpStatus} from "../../../network/status/HttpStatus";

export class AccountLogService {


    public static dispose(): void {

    }

    public static init(): void {

    }

    /**
     * rest
     */

    public static async searchLogs(search: AccountLogSearchOutgoing, component?: INetworkComponent): Promise<IAccountLogModel[]> {
        let request = await Network.postJson(ProductType.PANEL, `/account/logs`, search, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
    }
}