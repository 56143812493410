import {INetworkComponent} from "../../../../network/types/INetworkComponent";
import {Network} from "../../../../network/Network";
import {HttpStatus} from "../../../../network/status/HttpStatus";
import {
    StatisticsSplitType
} from "../../../../products/panel/components/components/statistics/types/StatisticsSplitType";
import {EntityService} from "../../../entity/EntityService";
import {
    IInboxConversationStatisticsCategory
} from "../../../../models/inbox/conversation/statistics/IInboxConversationStatisticsCategory";
import {CategoryService} from "../../../category/CategoryService";
import {
    IInboxConversationStatisticsHomeModel
} from "../../../../models/inbox/conversation/statistics/IInboxConversationStatisticsHomeModel";
import {IStatisticsValuesModel} from "../../../../models/statistics/IStatisticsValuesModel";
import {
    IInboxConversationStatisticsRatingModel
} from "../../../../models/inbox/conversation/statistics/IInboxConversationStatisticsRatingModel";
import {
    IInboxConversationStatisticsMembersModel
} from "../../../../models/inbox/conversation/statistics/members/IInboxConversationStatisticsMembersModel";
import {
    IInboxConversationStatisticsMemberModel
} from "../../../../models/inbox/conversation/statistics/members/IInboxConversationStatisticsMemberModel";
import {ProductType} from "../../../../models/product/ProductType";

export class InboxConversationStatisticsService {
    public static init(): void {

    }

    public static dispose(): void {

    }

    /**
     * rest
     */


    public static async findHome(startTime: number, endTime: number, oldStartTime: number, oldEndTime: number, component?: INetworkComponent): Promise<IInboxConversationStatisticsHomeModel> {
        let request = await Network.get(ProductType.PANEL, `/conversations/statistics/home/${startTime}/${endTime}/${oldStartTime}/${oldEndTime}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async findCount(startTime: number, endTime: number, oldStartTime: number, oldEndTime: number, splitType: StatisticsSplitType, filtersData?: string, component?: INetworkComponent): Promise<IStatisticsValuesModel> {
        let request = await Network.postJson(ProductType.PANEL, `/conversations/statistics/count/${startTime}/${endTime}/${oldStartTime}/${oldEndTime}/${splitType}`, JSON.parse(filtersData ? filtersData : "{}"), component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async findDuration(startTime: number, endTime: number, oldStartTime: number, oldEndTime: number, splitType: StatisticsSplitType, filtersData?: string, component?: INetworkComponent): Promise<IStatisticsValuesModel> {
        let request = await Network.postJson(ProductType.PANEL, `/conversations/statistics/duration/${startTime}/${endTime}/${oldStartTime}/${oldEndTime}/${splitType}`, JSON.parse(filtersData ? filtersData : "{}"), component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async findFirstResponseDelay(startTime: number, endTime: number, splitType: StatisticsSplitType, filtersData?: string, component?: INetworkComponent): Promise<IStatisticsValuesModel> {
        let request = await Network.postJson(ProductType.PANEL, `/conversations/statistics/firstResponseDelay/${startTime}/${endTime}/${splitType}`, JSON.parse(filtersData ? filtersData : "{}"), component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async findHours(startTime: number, endTime: number, splitType: StatisticsSplitType, filtersData?: string, component?: INetworkComponent): Promise<IStatisticsValuesModel> {
        let request = await Network.postJson(ProductType.PANEL, `/conversations/statistics/hours/${startTime}/${endTime}/${splitType}`, JSON.parse(filtersData ? filtersData : "{}"), component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async findRating(startTime: number, endTime: number, splitType: StatisticsSplitType, filtersData?: string, component?: INetworkComponent): Promise<IInboxConversationStatisticsRatingModel> {
        let request = await Network.postJson(ProductType.PANEL, `/conversations/statistics/rating/${startTime}/${endTime}/${splitType}`, JSON.parse(filtersData ? filtersData : "{}"), component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async findCategories(startTime: number, endTime: number, filtersData?: string, component?: INetworkComponent): Promise<IInboxConversationStatisticsCategory[]> {
        let request = await Network.postJson(ProductType.PANEL, `/conversations/statistics/categories/${startTime}/${endTime}`, JSON.parse(filtersData ? filtersData : "{}"), component);
        if (request.status == HttpStatus.OK) {
            let result: IInboxConversationStatisticsCategory[] = request.data as IInboxConversationStatisticsCategory[];
            result.forEach(value => {
                value.category = CategoryService.store(value.category);
            });

            return result;
        }


        return undefined;
    }

    public static async findMembers(startTime: number, endTime: number, filtersData?: string, component?: INetworkComponent): Promise<IInboxConversationStatisticsMembersModel> {
        let request = await Network.postJson(ProductType.PANEL, `/conversations/statistics/members/${startTime}/${endTime}`, JSON.parse(filtersData ? filtersData : "{}"), component);
        if (request.status == HttpStatus.OK) {
            request.data.entries.forEach(entry => {
                entry.entity = EntityService.store(entry.entity);
            });
            return request.data;
        }

        return undefined;
    }

    public static async findReceivedOffline(startTime: number, endTime: number, oldStartTime: number, oldEndTime: number, splitType: StatisticsSplitType, filtersData?: string, component?: INetworkComponent): Promise<IStatisticsValuesModel> {
        let request = await Network.postJson(ProductType.PANEL, `/conversations/statistics/receivedOffline/${startTime}/${endTime}/${oldStartTime}/${oldEndTime}/${splitType}`, JSON.parse(filtersData ? filtersData : "{}"), component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async findMember(accountId: string, startTime: number, endTime: number, oldStartTime: number, oldEndTime: number, filtersData?: string, component?: INetworkComponent): Promise<IInboxConversationStatisticsMemberModel> {
        let request = await Network.postJson(ProductType.PANEL, `/conversations/statistics/member/${accountId}/${startTime}/${endTime}/${oldStartTime}/${oldEndTime}`, JSON.parse(filtersData ? filtersData : "{}"), component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }
}