/**
 * Retourne le mois précedent selon l'année et le mois donné
 */
export function datePreviousMonth(year: number, month: number): object {
    if (month == 0) {
        year--;
        month = 11;
    } else {
        month--;
    }

    return {year: year, month: month};
}