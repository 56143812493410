import {DateCalendarPickerComponent} from "../calendar/picker/DateCalendarPickerComponent";
import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import * as s from "./date-picker.scss";
import {IDatePicker} from "./types/IDatePicker";
import {DateIntervalComponent} from "../interval/DateIntervalComponent";
import {dateDisplayMinutes} from "../../../sedestral-interface-component/utilities/DateDisplayMinutes";
import {dateDisplayHours} from "../../../sedestral-interface-component/utilities/DateDisplayHours";
import {dateInput} from "../../../sedestral-interface-component/utilities/DateInput";
import {dateValid} from "../../../sedestral-interface-component/utilities/DateValid";
import {dateToCompare} from "../../../sedestral-interface-component/utilities/DateToCompare";

export class DatePickerComponent extends Component {

    public settings: IDatePicker;
    public hours: boolean;

    public firstDate: Date;
    public secondDate: Date;

    public firstDateInput: Component;
    public secondDateInput: Component;

    public hoursInput: Component;
    public minutesInput: Component;

    public topContainer: Component;
    public calendarContainer: Component;
    public calendarComponent: DateCalendarPickerComponent;

    constructor(settings?: IDatePicker) {
        super();
        this.settings = settings;
        this.firstDate = settings == undefined || settings.firstDate == undefined ? new Date() : settings.firstDate;
        if (settings != undefined && settings.interval) {
            this.secondDate = settings.secondDate == undefined ? new Date() : settings.secondDate;
        }

        this.calendarComponent = new DateCalendarPickerComponent(this.secondDate, this.firstDate);
        //language=HTML
        this.template = `
            <div class="${s.globalDatePicker}">
                <div class="${s.top}"></div>
                <div class="${s.calendarContainer}">
                    ${this.draw(this.calendarComponent)}
                </div>
            </div>
        `;
    }

    commit() {
        this.topContainer = this.el(s.top);
        this.calendarContainer = this.el(s.calendarContainer);

        if (this.settings == undefined || !this.settings.interval) {
            this.renderSimpleDate();
        } else {
            this.renderIntervalDate();
        }

        if (this.settings == undefined || this.settings.hours) {
            this.renderHours();
        }

        super.commit();
    }

    renderSimpleDate() {
        //language=HTML
        this.firstDateInput = this.topContainer.append(`
            <input class="${s.input}"
                   value="${dateInput(this.firstDate, true)}"
                   type="date" required/>
        `);

        this.firstDateInput.unClickable();

        this.firstDateInput.onKeyUp(() => {
            let date = new Date(this.firstDateInput.getValue());
            if (dateValid(date)) {
                this.firstDate = this.parse(date);
                this.calendarComponent.firstDate = this.parse(this.firstDate);
                this.calendarComponent.currentDate = this.calendarComponent.firstDate;
                this.calendarComponent.update();

                this.executePick(date);
            } else {
                this.calendarComponent.renderEmpty();
            }
        }, 0);

        this.calendarComponent.onSelect = (firstDate) => {
            this.firstDate = this.parse(firstDate);
            this.firstDateInput.setValue(dateInput(this.firstDate, true));
            this.executePick(firstDate);
        };
    }

    renderIntervalDate() {
        //language=HTML
        let intervalComponent = new DateIntervalComponent({firstDate: this.firstDate, secondDate: this.secondDate});
        this.topContainer.render(intervalComponent);
        this.firstDateInput = intervalComponent.firstInput;
        this.secondDateInput = intervalComponent.secondInput;

        this.firstDateInput.unClickable();
        this.secondDateInput.unClickable();

        let onKey = () => {
            let firstDate = new Date(this.firstDateInput.getValue());
            let secondDate = new Date(this.secondDateInput.getValue());
            if (dateValid(firstDate) && dateValid(secondDate)) {
                if (firstDate < secondDate) {
                    this.calendarComponent.renderEmpty();
                } else {
                    this.firstDate = firstDate;
                    this.secondDate = secondDate;
                    this.calendarComponent.selectInterval(secondDate, firstDate);

                    this.executePick(firstDate, secondDate);
                }
            } else {
                this.calendarComponent.renderEmpty();
            }
        };

        this.firstDateInput.onKeyUp(() => onKey(), 0);
        this.secondDateInput.onKeyUp(() => onKey(), 0);

        this.firstDateInput.onFocus(() => {
            this.calendarComponent.focusOnDate(true)
        });
        this.secondDateInput.onFocus(() => {
            this.calendarComponent.focusOnDate(false)
        });

        this.calendarComponent.onSelect = (secondDate, firstDate) => {
            this.firstDate = firstDate;
            this.secondDate = secondDate;
            if (this.firstDate != undefined) {
                this.firstDateInput.setValue(dateInput(this.firstDate, true));
            }
            if (this.secondDate != undefined) {
                this.secondDateInput.setValue(dateInput(this.secondDate, true));
            }

            this.executePick(firstDate, secondDate);
        }
    }

    renderHours() {
        this.hours = true;
        //language=HTML
        let hours = this.topContainer.append(`
            <div class="${s.hours}">
                <input min="0" max="23" value="${dateDisplayHours(this.firstDate)}" type="number"
                       class="${s.left}"/>
                <div class="${s.dots}">:</div>
                <input min="0" max="59" value="${dateDisplayMinutes(this.firstDate)}" type="number"
                       class="${s.right}"/>
            </div>
        `);
        this.hoursInput = hours.el(s.left);
        this.minutesInput = hours.el(s.right);

        this.hoursInput.onInput(() => {
            let value = parseInt(this.hoursInput.getValue()) || 0;
            this.hoursInput.setValue(Math.max(0, Math.min(23, value)) + "");
            this.firstDate = this.parse(this.firstDate);
        });

        this.minutesInput.onInput(() => {
            let value = parseInt(this.minutesInput.getValue()) || 0;
            this.minutesInput.setValue(Math.max(0, Math.min(59, value)) + "");
            this.firstDate = this.parse(this.firstDate);
        });

        this.hoursInput.onKeyUp(() => this.firstDate = this.parse(this.firstDate), 0);
        this.minutesInput.onKeyUp(() => this.firstDate = this.parse(this.firstDate), 0);
    }

    parse(date): Date {
        if (this.hoursInput != undefined && this.minutesInput != undefined) {
            date.setMinutes(parseInt(this.minutesInput.getValue()));
            date.setHours(parseInt(this.hoursInput.getValue()));
        }

        return date;
    }

    executePick(firstDate: Date, secondDate?: Date) {
        if (!this.hours) {
            if (firstDate != undefined) {
                firstDate = dateToCompare(new Date(firstDate));
            }
            if (secondDate != undefined) {
                secondDate = dateToCompare(new Date(secondDate), true);
            }
        }

        this.onPick(firstDate, secondDate);
    }

    onPick(firstDate: Date, secondDate?: Date) {

    }
}