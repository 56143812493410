/**
 * durée d'une vidéo
 */
import {SedestralMachine} from "../machine/SedestralMachine";

export async function fileVideoData(url: string): Promise<{ duration: number, ratio: number }> {
    return SedestralMachine.promise((resolve) => {
        let video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);

            let w = video.videoWidth;
            let h = video.videoHeight;

            resolve({duration: video.duration, ratio: w > h ? w / h : h / w});
        }

        video.src = url;
    })
}