/**
 * files
 */

export function fileIsVideo(type: string): boolean {
    if (type != undefined) {
        type = type.toLowerCase();
    }
    switch (type) {
        case "avi":
        case "mpeg":
        case "mp4":
        case "3gp":
        case "3g2":
        case "video/mp4":
            return true;
    }
    return false;
}