import {PublisherAttachmentService} from "./attachments/PublisherAttachmentService";
import {PublisherTransmissionService} from "./transmissions/PublisherTransmissionService";
import {PublisherPostService} from "./posts/PublisherPostService";
import {Network} from "../../network/Network";
import {WebSocketPanelEventName} from "../../network/socket/names/WebSocketPanelEventName";
import {Services} from "../Services";
import {IAccountModel} from "../../models/account/IAccountModel";
import {observable} from "@nx-js/observer-util";
import {AccountService} from "../account/AccountService";
import {IPublisherModel} from "../../models/publisher/IPublisherModel";
import {ProductType} from "../../models/product/ProductType";
import {arrayRemove} from "../../sedestral-interface-modules/sedestral-interface-component/utilities/ArrayRemove";

export class PublisherService {
    public static activeAccounts: IAccountModel[] = observable([]);
    public static joined: boolean;

    public static dispose(): void {
        this.activeAccounts = observable([]);

        PublisherTransmissionService.dispose();
        PublisherAttachmentService.dispose();
        PublisherPostService.dispose();
    }

    public static init(): void {
        Services.beforeInit(this);

        PublisherTransmissionService.init();
        PublisherAttachmentService.init();
        PublisherPostService.init();

        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.PUBLISHER_JOIN, (data) => {
            let publisher: IPublisherModel = data;
            AccountService.storeAll(publisher.entities).forEach(value => this.activeAccounts.push(value));
        });
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.PUBLISHER_JOIN_ACCOUNT, (data) => {
            let account = AccountService.store(data);

            if (!this.activeAccounts.includes(account)) {
                this.activeAccounts.push(account);
            }
        });
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.PUBLISHER_LEAVE_ACCOUNT, (data) => {
            let account = AccountService.store(data);
            arrayRemove(this.activeAccounts, account);
        });
    }

    /**
     * websocket
     */
    public static async join(siteId: string) {
        if (siteId != null) {
            await Network.join(ProductType.PANEL, siteId, WebSocketPanelEventName.PUBLISHER_JOIN);
            this.joined = true;
        }
    }

    public static leave(id: string) {
        this.activeAccounts.splice(0, this.activeAccounts.length);
        Network.emit(ProductType.PANEL, WebSocketPanelEventName.PUBLISHER_LEAVE, id);
        this.joined = false;
    }
}