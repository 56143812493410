@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/outline";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/inputs";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";

.componentSiteCreatorJoin {
  .title {
    font-size: 14px;
    position: relative;
    @include texts.font("bold");
  }

  .input {
    @include inputs.white;
    margin-top: 12px;
    height: 40px;
    width: 100%;
    font-size: 14px;
    border-width: 2px;
  }

  .buttons {
    margin-top: 15px;

    div[btnc] {
      display: none;
    }
  }
}