.componentSiteAddress {

  .label {
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 8px;
  }

  .doubleInput {
    display: flex;
    gap: 15px;

    .inputContainer {
      width: 50%;
    }
  }

  .inputContainer {
    height: 40px;
    position: relative;
    margin-bottom: 10px;

    input {
      border: 1px solid var(--grey-sur) !important;
    }
  }

  div[data-selectbox] {
    border-width: 1px !important;
  }
}