.componentContactCreator {

  .description {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .inputContainer {
    position: relative;
    height: 50px;
    margin-bottom: 14px;
  }

  .buttonsContainer {
    margin-top: 30px;
  }

  .input {
    display: block;
  }
}