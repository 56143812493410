import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import * as s from "./date-interval.scss";
import {IDateInterval} from "./types/IDateInterval";
import {IDateIntervalPickerPurpose} from "./picker/types/IDateIntervalPickerPurpose";
import {DateIntervalPickerTooltipComponent} from "./picker/tooltip/DateIntervalPickerTooltipComponent";
import {DateIntervalPickerPurposes} from "./picker/types/DateIntervalPickerPurposes";
import {Resources} from "../../../../resources/Resources";
import {dateFormat} from "../../../sedestral-interface-component/utilities/DateFormat";
import {dateInput} from "../../../sedestral-interface-component/utilities/DateInput";
import {txtStrip} from "../../../sedestral-interface-component/utilities/TxtStrip";

export class DateIntervalComponent extends Component {

    public reduce: boolean;
    public picker: boolean;
    public restored: boolean;

    public firstDate: Date;
    public secondDate: Date;

    public emptyComparator: boolean;
    public comparator: DateIntervalComponent;
    public comparable: DateIntervalComponent;

    public firstInput: Component;
    public secondInput: Component;

    public purpose: IDateIntervalPickerPurpose;

    public tooltipComponent: DateIntervalPickerTooltipComponent;

    constructor(settings: IDateInterval) {
        super();
        this.reduce = settings.reduce;
        this.picker = settings.picker;

        this.firstDate = settings.firstDate;
        this.secondDate = settings.secondDate;

        if (settings.data == undefined && (this.firstDate == undefined || this.secondDate == undefined)) {
            this.purpose = DateIntervalPickerPurposes.basePurpose('week');
            let purposes = DateIntervalPickerPurposes.baseCalc(this.purpose.value);
            this.firstDate = purposes[0];
            this.secondDate = purposes[1];
        }

        if (settings.data != undefined) {
            this.restored = true;
            settings.data = JSON.parse(settings.data);
            this.emptyComparator = settings.data['emptyComparator'];
            this.purpose = settings.data['comparator'] ? DateIntervalPickerPurposes.comparatorPurpose(settings.data['purposeValue']) : DateIntervalPickerPurposes.basePurpose(settings.data['purposeValue']);
            if (settings.data['purposeValue'] == undefined) {
                this.firstDate = new Date(parseInt(settings.data['firstDate']));
                this.secondDate = new Date(parseInt(settings.data['secondDate']));
            } else if (!settings.data['comparator']) {
                let purposes = DateIntervalPickerPurposes.baseCalc(this.purpose.value);
                this.firstDate = purposes[0];
                this.secondDate = purposes[1];
            }
        }

        //language=HTML
        this.template = `
            <div class="${s.componentDateInterval}"></div>
        `;
    }

    commit() {
        this.init();
        super.commit();
    }

    init() {
        this.clearAll();
        this.firstInput = undefined;
        this.secondInput = undefined;
        let text;

        if (this.emptyComparator) {
            text = Resources.t("words.noComparison");
        } else if (this.reduce) {
            text = this.getName();
        }

        if (text != undefined) {
            this.append(`<span>${text}</span>`);
        } else {
            this.renderDates();
        }


        if (this.picker) {
            this.initPicker();
        }
    }

    initPicker() {
        this.onClick(() => {
            this.tooltipComponent = this.comparator != undefined ? new DateIntervalPickerTooltipComponent(this, this.firstDate, this.secondDate, this.comparator.firstDate, this.comparator.secondDate) : new DateIntervalPickerTooltipComponent(this, this.firstDate, this.secondDate);
            this.tooltipComponent.screen(false);
            this.tooltipComponent.calcTop = () => this.tooltipComponent.component.getOffsets().top + this.tooltipComponent.component.getHeight() + 10;
            this.tooltipComponent.create();
            this.tooltipComponent.picker.onPick = (firstDate, secondDate, purpose) => {
                this.purpose = purpose;
                this.setDates(firstDate, secondDate);

                if (this.comparable != undefined && this.comparable.purpose != undefined) {
                    let comparatorPurposes = DateIntervalPickerPurposes.comparatorCalc(this.comparable.purpose.value, this.firstDate, this.secondDate);
                    this.comparable.setDates(comparatorPurposes[0], comparatorPurposes[1]);
                }

                this.onPick();
                this.tooltipComponent.dispose();
            };
            this.tooltipComponent.onDispose = () => {
                this.tooltipComponent = undefined;
            }
        });
    }

    renderDates() {
        //language=HTML
        this.append(`
            <input class="${s.input} ${s.Second}"
                   value="${dateInput(this.firstDate, true)}"
                   type="date" required/>
            <div class="${s.arrowIcon}"></div>
            <input class="${s.input} ${s.First}"
                   value="${dateInput(this.secondDate, true)}"
                   type="date" required/>
        `);

        this.firstInput = this.el(s.First);
        this.secondInput = this.el(s.Second);
    }

    getName() {
        if (this.purpose != undefined) {
            return this.purpose.name;
        }

        return undefined;
    }

    /**
     * set datas
     */

    setDates(firstDate: Date, secondDate: Date) {
        this.emptyComparator = false;
        if (firstDate == undefined && secondDate == undefined) {
            this.emptyComparator = true;
            firstDate = new Date();
            secondDate = new Date();
        }

        this.firstDate = firstDate;
        this.secondDate = secondDate;
        this.init();
    }

    setComparator(comparator: DateIntervalComponent) {
        this.comparator = comparator;
        let comparatorPurposes;
        if (this.restored) {
            if (this.purpose != undefined) {
                comparatorPurposes = DateIntervalPickerPurposes.comparatorCalc(this.purpose.value, comparator.firstDate, comparator.secondDate);
            }
        } else {
            this.purpose = DateIntervalPickerPurposes.comparatorPurpose('lastPeriod');
            comparatorPurposes = DateIntervalPickerPurposes.comparatorCalc(this.purpose.value, this.firstDate, this.secondDate);
        }

        if (comparatorPurposes != undefined) {
            this.firstDate = comparatorPurposes[0];
            this.secondDate = comparatorPurposes[1];
        }
    }

    setComparable(comparable: DateIntervalComponent) {
        this.comparable = comparable;
    }

    /**
     * content
     */


    content(): string {
        if (this.firstInput == undefined && this.secondInput == undefined) {
            return txtStrip(this.getHtml());
        }

        return dateFormat(this.firstDate) + ` - ` + dateFormat(this.secondDate);
    }

    /**
     * export
     */

    export(): string {
        return JSON.stringify({
            purposeValue: this.purpose != undefined ? this.purpose.value : undefined,
            firstDate: this.firstDate != undefined ? this.firstDate.getTime() : 0,
            secondDate: this.secondDate != undefined ? this.secondDate.getTime() : 0,
            comparator: this.comparator != undefined,
            emptyComparator: this.emptyComparator
        });
    }

    /**
     * over
     */

    onPick() {

    }

}