import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-issues.scss";
import {Resources} from "../../../../../../resources/Resources";

export class WebsiteIssuesComponent extends Component {

    public questions: { question: string, response: string }[];
    public questionsContainer: Component;

    constructor(p: { theme?: string, questions: { question: string, response: string }[] }) {
        super();

        this.questions = p.questions;

        if (this.questions) {
            //language=HTML
            this.template = `
                <div itemscope itemtype="https://schema.org/FAQPage"
                     class="${s.componentWebsiteIssues} ${p.theme ? s[p.theme] : ``}">
                    <h2 itemprop="name" class="${s.title}">
                        ${Resources.t("words.faq")}
                    </h2>
                    <div class="${s.issues}">

                    </div>
                </div>
            `;
        }
    }

    commit() {

        if (this.questions) {

            this.questionsContainer = this.el(s.issues);
            this.questions.forEach(questions => {

                //language=HTML
                let questionComponent = this.questionsContainer.append(`
                    <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question" class="${s.issue}">
                        <div class="${s.top}">
                            <h3 itemprop="name" class="${s.name}">
                                ${questions.question}
                            </h3>
                            <div class="${s.more}">
                                <div class="${s.icon} ${s.close}"></div>
                            </div>
                        </div>
                        <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer"
                             class="${s.bottom} ${s.hidden}">
                            <p itemprop="text" class="${s.description}">
                                ${questions.response}
                            </p>
                        </div>
                    </div>
                `);

                questionComponent.el(s.top).onClick(() => {
                    this.hideResponse(questionComponent.el(s.bottom), questionComponent.el(s.icon));
                });
            });
        }

        super.commit();
    }

    hideResponse(questions: Component, icon: Component) {
        if (questions.hasClass(s.hidden)) {
            questions.removeClass(s.hidden);
            icon.removeClass(s.close);
            icon.addClass(s.open);
        } else {
            questions.addClass(s.hidden);
            icon.removeClass(s.open);
            icon.addClass(s.close);
        }
    }
}