import {dateCountDaysBetweenDates} from "./DateCountDaysBetweenDates";

import {dateToCompare} from "./DateToCompare";

/**
 * Retourne tous les dimanches entre deux dates
 */
export function dateSundays(startTime: number, endTime: number): Date[] {
    let from = dateToCompare(new Date(startTime));
    let to = dateToCompare(new Date(endTime), true);
    let daysCount = dateCountDaysBetweenDates(from, to);
    let result = [];
    for (let i = 0; i < daysCount; i++) {
        if (from.getDay() == 1) {
            result.push(new Date(dateToCompare(from)));
        }
        from.setDate(from.getDate() + 1);
    }

    return result;
}