@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentWebsiteFooter {
  width: calc(100% - 40px);
  max-width: 1150px;
  padding: 0 20px;

  .brand {
    .caseLogo {
      width: fit-content;
      border-radius: 18px;
      display: flex;
      flex-direction: row;
      z-index: 10;
      gap: 10.45px;
      cursor: pointer;
      height: 38px;
      align-items: center;
      padding: 0 8px;
      margin-left: -8px;

      &:hover {
        background: var(--white-sur);
      }

      .logoContainer {
        width: 28.5px;
        height: 28.5px;

        .logo {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        margin-top: 1px;
        font-size: 21px;

        @include texts.font("semi-bold");
      }
    }
  }

  .columns {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 25px;
    flex-wrap: wrap;
    justify-content: space-between;

    .column {
      width: fit-content;

      nav:nth-of-type(2) {
        margin-top: 25px;
      }


      .title {
        font-size: 26px;

        @include texts.font("semi-bold");


        .entries {
          margin-top: 15px;
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 0;

          .entry {
            font-size: 16px;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .language {
          position: relative;
          height: 40px;
          width: 130px;
          margin-top: 10px;

          div[data-selectbox] {
            height: 36px;
            border-color: transparent !important;
            background: var(--white-sur);
            color: white;

            &:hover {
              background: var(--white-sur-hover);
            }

            div[data-selectIcon] {
              filter: var(--icon-white);
            }
          }
        }
      }
    }
  }

  .copyright {
    margin-top: 50px;
    font-size: 14px;
    padding-bottom: 40px;

    @include texts.font("medium");
  }

  @media (max-width: 480px) {
    .columns {
      gap: 25px 105px;

      .column {
        .title {
          font-size: 25px;

          .entries {
            gap: 12px;
          }
        }
      }
    }

    .copyright {
      text-align: center;
    }
  }
}