import {
    VisualHoverTooltipWhiteComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/tooltip/VisualHoverTooltipWhiteComponent";
import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {
    LoaderLightComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import {ContactService} from "../../../../../../services/contact/ContactService";
import {ContactPreviewComponent} from "../preview/ContactPreviewComponent";
import {
    EmptyBasicComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/empty/EmptyBasicComponent";
import {Resources} from "../../../../../../resources/Resources";

export class ContactTooltipComponent extends VisualHoverTooltipWhiteComponent {

    private contactId: string;

    constructor(component: Component, contactId: string) {
        super(component, 120, 246);
        this.mobileStyle(true);
        this.contactId = contactId;
    }

    commit() {
        this.render(new LoaderLightComponent());
        this.init();

        super.commit();
    }

    async init() {
        let contact = await ContactService.findById(this.contactId);
        this.clear();
        if (contact == undefined) {
            this.render(new EmptyBasicComponent("👽", Resources.t('words.contactNotFoundOrNoPermission')));
        } else {
            this.render(new ContactPreviewComponent({contact: contact, menu: true, state: true}));
        }

        this.swapable(() => this.dispose());
    }
}