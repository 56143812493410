@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/boxs";

.componentWebsiteHead {
  height: 70px;
  align-items: center;
  position: relative;
  display: flex;

  @include positioning.webResponsive;

  &.mobileOpened {
    position: fixed;
    top: 0;
  }

  &.blog, &.schedule {
    visibility: visible;
  }

  .caseLogo {
    border-radius: 18px;
    display: flex;
    flex-direction: row;
    z-index: 10;
    gap: 10.45px;
    cursor: pointer;
    height: 38px;
    align-items: center;
    padding: 0 8px;
    margin-left: -8px;

    &:hover {
      background: var(--white-sur);
    }

    .logoContainer {
      width: 28.5px;
      height: 28.5px;

      .logo {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      margin-top: 1px;
      font-size: 21px;

      @include texts.font("semi-bold");
    }
  }

  .cases {
    margin-left: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;

    .case {
      display: flex;
      flex-direction: row;
      gap: 10px;
      cursor: pointer;
      font-size: 15px;
      justify-content: center;
      background: transparent;
      align-items: center;
      color: var(--white);
      height: 38px;
      padding: 0 14px;
      border-radius: 26px;
      position: relative;

      @include texts.font("semi-bold");

      &.products, .use, .pricing {
        visibility: visible;
      }

      &:hover {
        background: var(--white-sur);
      }

      &.account {
        color: var(--white);
        height: fit-content;
        position: relative;
        white-space: nowrap;
        border-radius: 25px;
        margin-left: auto;
        padding: 12px 12px;

        &.signup {
          border-top: 2px solid var(--web-border-blue);
          background: var(--web-grad-blue);
          color: var(--white);
          border-radius: 12px;
          padding: 10px 16px;
          margin-left: 15px;
          height: fit-content;
        }
      }

      .icon {
        margin-top: 2px;
        width: 14px;
        height: 14px;
        filter: var(--icon-white);

        @include icons.ico("black-bottom");
      }

      .subMenu {
        position: absolute;
        top: 62px;
        border-radius: 15px;
        z-index: 9;
        display: none;
        background: var(--white);
        width: 620px;
        padding: 10px;
        box-shadow: 0 15px 3.75rem #00000047;
        left: 0;

        .separator {
          position: absolute;
          height: 50px;
          top: -25px;
          width: 100%;
          left: 0;
        }
      }
    }
  }

  .casesMobile {
    display: none;

    .caseMobile {
      margin-left: auto;
      z-index: 4;
      display: flex;
      cursor: pointer;
      height: 42px;
      min-width: 42px;
      align-items: center;
      justify-content: center;
      padding: 0px 20px;
      background: var(--web-white-alpha);
      border-radius: 14px;

      @include texts.font("semi-bold");

      &:hover {
        background: var(--grey-sur);
      }

      &.open {
        .icon {
          width: 16px;
          height: 16px;

          @include icons.ico("black-close-bold");
        }
      }

      &.bars {
        padding: 0;
      }

      .icon {
        width: 20px;
        height: 20px;
        filter: var(--icon-white);

        @include icons.ico("black-mobile-menu");
      }
    }
  }

  .mobile {
    max-height: 0%;
    transition: 0.5s;
    position: fixed;
    top: 0px;
    width: calc(100% - 12px);
    left: 0;
    display: none;
    border-radius: 0px 0px 14px 14px;
    padding: 6px;
    z-index: 1;
    height: calc(100% - 140px);
    box-shadow: 0 15px 3.75rem #000000a6;
    background: var(--white);
    border-top: 72px solid var(--black);
    overflow: auto;
  }

  @media (max-width: 930px) {
    justify-content: space-between;

    .caseLogo {
      gap: 15px;

      .logoContainer {
        width: 30px;
        height: 30px;
      }

      .title {
        margin-top: 3px;
        font-size: 20px;
      }
    }

    .cases {
      display: none;
    }

    .caseAccount {
      display: none;
    }

    .casesMobile {
      display: flex;
    }
  }
}