import {WebsitePageComponent} from "../components/pages/WebsitePageComponent";
import {WebsiteContentBlogWritingComponent} from "../components/content/pages/blog/WebsiteContentBlogWritingComponent";
import {
    Component
} from "../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {
    WebsiteContentBlogAutopilotComponent
} from "../components/content/pages/blog/WebsiteContentBlogAutopilotComponent";

export class WebsiteBlogAutopilotPage extends WebsitePageComponent {

    constructor() {
        super();
        this.updateMetas("blogAutopilot");
        this.status.online = undefined;
    }

    contentComponent(): Component {
        return new WebsiteContentBlogAutopilotComponent();
    }

    onHash(): void {

    }
}