import {
    VisualClickTooltipWhiteComponent
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {ContactBanCreatorComponent} from "../ContactBanCreatorComponent";
import {
    Component
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {IContactModel} from "../../../../../../../../models/contact/IContactModel";

export class ContactBanCreatorTooltipComponent extends VisualClickTooltipWhiteComponent {
    public creator: ContactBanCreatorComponent;

    constructor(component: Component, contact: IContactModel) {
        super(component, 260, 250);
        this.mobileStyle(true);

        this.creator = new ContactBanCreatorComponent(contact);
        this.template = this.draw(this.creator);
    }

    commit() {
        this.creator.onClose = () => this.dispose();
        this.swapable(() => this.dispose());
        super.commit();
    }
}