import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./contact-state.scss";
import {IContactModel} from "../../../../../../models/contact/IContactModel";
import {Resources} from "../../../../../../resources/Resources";
import {ContactCreatorModalComponent} from "../creator/modal/ContactCreatorModalComponent";
import {ContactState} from "../../../../../../models/contact/ContactState";
import {
    LoaderLightComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import {CapsuleComponent} from "../../capsule/CapsuleComponent";
import {ProductType} from "../../../../../../models/product/ProductType";
import {ContactService} from "../../../../../../services/contact/ContactService";

export class ContactStateComponent extends Component {

    public contact: IContactModel;

    public button: Component;
    public text: Component;
    public icon: Component;

    constructor(contact: IContactModel) {
        super();
        this.contact = contact;

        //language=HTML
        this.template = `
            <div class="${s.componentContactState}">
                <div class="${s.names}">
                    <div class="${s.icon}"></div>
                    <div class="${s.text}"></div>
                </div>
                <div class="${s.button}"></div>
            </div>
        `;
    }

    commit() {
        this.button = this.el(s.button);
        this.text = this.el(s.text);
        this.icon = this.el(s.icon);

        this.onReactiveObserve(() => this.renderAll());
        super.commit();
    }

    renderAll() {
        if (!this.isNull()) {
            this.renderText();
            this.renderButton();
            this.renderIcon();
        }
    }

    renderText() {
        this.text.setHtml(this.contact.state != ContactState.CONFIRMED ? Resources.t("words.contactNotConfirmed") : Resources.t("words.contactConfirmed"));
    }

    renderButton() {
        this.button.clearAll();
        this.button.removeClass(s.loading);

        if (this.contact.state == ContactState.CONFIRMED) {
            this.button.setHtml(Resources.t("words.remove"));
            this.button.onClick(async () => {
                if (!this.hasClass(s.loading)) {
                    this.button.render(new LoaderLightComponent());
                    this.button.addClass(s.loading);

                    let contact = await ContactService.state(this.contact.id, ContactState.UNLISTED, this);
                    console.log(contact)
                }
            });
        } else {
            this.button.setHtml(Resources.t("words.contactAddToList"));
            this.button.onClick(() => {
                let modal = new ContactCreatorModalComponent(this.contact);
                modal.screenRender();
            });
        }
    }

    renderIcon() {
        this.icon.clearAll();
        if (this.contact.state == ContactState.CONFIRMED) {
            this.icon.append(`<div class="${s.unlisted}"></div>`);
        } else {
            this.icon.render(new CapsuleComponent(ProductType.CONTACTS));
        }
    }

}