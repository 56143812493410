@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/effects";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentSite {
  margin-bottom: 10px;
  border-radius: 14px;
  padding: 8px 4px;
  width: calc(100% - 8px);
  transition: 0.1s;
  position: relative;
  cursor: pointer;

  @include effects.hover-alpha;
  @include positioning.clear;

  .avatarContainer {
    height: 34px;
    width: 34px;
    float: left;
    position: relative;

    .notifs {
      height: 14px;
      width: 14px;
      font-size: 10px;
      border: var(--white) 2px solid;
      color: var(--white);
      top: -4px;
      right: -4px;
      border-radius: 100%;
      background: var(--red);
      position: absolute;

      @include positioning.flex-globally;
      @include texts.font("semi-bold");
    }
  }

  .content {
    height: 40px;
    margin-top: -4px;
    margin-left: 15px;
    float: left;

    .name {
      position: relative;

      @include texts.font("bold");
      @include positioning.clear;

      .text {
        float: left;
        width: 154px;
        overflow: hidden !important;
        overflow-wrap: normal;
        text-overflow: ellipsis;
        white-space: nowrap !important;
      }
    }

    .statistics {
      margin-top: 7px;
      position: relative;

      .statistic {
        height: 13px;
        margin-right: 10px;
        border-right: var(--grey-sur) 1px solid;
        padding-right: 14px;
        float: left;
        position: relative;

        .icon {
          height: 12px;
          width: 12px;
          float: left;

          &.users {
            @include icons.ico("black-user");
          }

          &.members {
            @include icons.ico("black-members");
          }
        }

        .value {
          font-size: 12px;
          margin-left: 5px;
          top: -2px;
          position: relative;
          float: left;

          @include texts.font("semi-bold");
        }
      }
    }
  }

  .checked {
    height: 14px;
    width: 14px;
    top: 19px;
    right: 10px;
    position: absolute;

    @include icons.ico("black-checked");
  }
}

