@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsiteColumnsDouble {

  .head {
    width: 100%;
    text-align: center;

    .title {
      padding: 0 156px;
      font-size: 44px;
      color: var(--white);
      @include texts.font("bold");

      &.black {
        background: var(--grad-black);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &.red {
        background: var(--grad-red);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &.multi {
        background: var(--grad-multi);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .columns {
    margin-top: 75px;
    display: flex;
    flex-direction: row;
    gap: 53px;

    .column {
      width: 50%;
      border-radius: 20.5px;
      box-shadow: 0 0 32.85px var(--black-alpha-middle);
      text-align: center;
      padding-bottom: 76px;
      border: 1px solid var(--web-border-white);
      backdrop-filter: blur(30px);
      background: linear-gradient(311deg, #8c62fe1a, #000000);

      .icon {
        width: 118px;
        height: 118px;
        margin-left: calc(50% - 59px);
        margin-top: 63px;

        &.seo {
          @include icons.ico("3d-seo");
        }

        &.handshake {
          @include icons.ico("3d-handshake");
        }

        &.customerService {
          @include icons.ico("illus-customer-service");
        }

        &.transaction {
          @include icons.ico("illus-transaction");
        }

        &.error {
          @include icons.ico("3d-error");
        }

        &.mailSpam {
          @include icons.ico("3d-spam");
        }

        &.letter {
          @include icons.ico("illus-letter");
        }

        &.articleAi {
          @include icons.ico("3d-article-ia");
        }
      }

      .name {
        margin-top: 30px;
        font-size: 32px;
        width: 90%;
        margin-left: 5%;

        @include texts.font("bold");
      }

      .text {
        margin-top: 15px;
        padding: 0 47px;
        font-size: 16px;

        @include texts.font("semi-bold");
      }
    }
  }

  @media (max-width: 750px) {
    .head {
      .title {
        padding: 0;
        font-size: 30px;
      }
    }

    .columns {
      gap: 20px;
    }
  }

  @media (max-width: 480px) {

    .head {
      .title {
        font-size: 25px;
      }
    }

    .columns {
      margin-top: 30px;
      flex-direction: column;

      .column {
        width: 100%;
        padding-bottom: 30px;

        .icon {
          width: 70px;
          height: 70px;
          margin-left: calc(50% - 35px);
          margin-top: 30px;
        }

        .name {
          margin-top: 20px;
          font-size: 30px;
        }

        .text {
          margin-top: 20px;
          padding: 0 30px;
          font-size: 16px;
        }
      }
    }
  }
}