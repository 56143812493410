@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentOfferContent {
  .title {
    font-size: 16px;

    @include texts.font("semi-bold");
  }

  .offers {
    margin: 8px -5px;
    font-size: 15px;
    padding-inline-start: 20px;
    width: calc(100% - 20px);

    li {
      list-style-type: disc;
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #00000012;

      &.from {
        padding-bottom: 10px;
        margin-bottom: 10px;

        @include texts.font("semi-bold");
      }
    }
  }
}