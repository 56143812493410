import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./channel-avatar.scss";
import {ChannelIconComponent} from "../icon/ChannelIconComponent";
import {IChannelModel} from "../../../../../../models/channel/IChannelModel";

export class ChannelAvatarComponent extends Component {
    constructor(channel?: IChannelModel) {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentChannelAvatar}">
                <div class="${s.channelIcon}">
                    ${this.draw(new ChannelIconComponent(channel.type))}
                </div>
            </div>
        `;
    }
}