import {IOfferDetailsModel} from "../../../../../../models/offer/IOfferDetailsModel";
import * as s from "./offer-quote.scss";
import {ProductType} from "../../../../../../models/product/ProductType";
import {OfferQuoteComponent} from "./OfferQuoteComponent";
import {
    dateFormat
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/DateFormat";
import {Resources} from "../../../../../../resources/Resources";
import {OfferPeriodType} from "../../../../../../models/offer/product/OfferPeriodType";
import {
    numberPrice
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/NumberPrice";
import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {OfferPaymentActionType} from "../../../../../../models/offer/payment/OfferPaymentActionType";
import {offerAdvantage} from "../types/OfferAdvantage";
import {IOfferPaymentSecretsModel} from "../../../../../../models/offer/payment/IOfferPaymentSecretsModel";
import {OfferStripeComponent} from "../stripe/OfferStripeComponent";

export class OfferQuoteConfirmedComponent extends OfferQuoteComponent {
    constructor(productType: ProductType, quote: IOfferDetailsModel) {
        super(productType, quote);

        //language=HTML
        this.template = `
            <div class="${s.componentOfferQuote}"></div>`;
    }

    renderAll() {
        this.renderBack(Resources.t("words.offerBackContent"));
        this.renderOfferContent();

        this.renderPriceDetailsCurrent();
        this.renderPriceDetailsNext();

        if (this.quote.payment.actionType !== OfferPaymentActionType.NONE) {
            this.renderStripe(this.quote.payment.secrets, this.quote.payment.actionType);
        }

        this.renderPrice(this.quote.payment.currentPriceTotal, Resources.t("words.offerPay"));

        this.scheduleExpiredQuote();
    }

    renderPriceDetailsNext() {
        if (!this.quote.offer.active) {
            let advantages = offerAdvantage(this.quote, this.product);
            this.renderPriceDetails(
                `${Resources.t("words.offerSince")} ${dateFormat(new Date(this.quote.offer.nextBilling))}`,
                Resources.t(this.quote.offer.periodType == OfferPeriodType.MONTHLY ? "words.offerPaidMonth" : "words.offerPaidYear"),
                this.quote.payment.nextPriceExcludingTaxEstimate,
                this.quote.payment.nextPriceIncludingTaxEstimate,
                advantages?.advantagePercentage > 0 ? `${advantages.advantagePercentage}% ${Resources.r("words.offerAdvantageMonth", {advantageMonths: advantages.advantageMonths})}` : ``,
                advantages?.advantageMonths > 0 ? this.quote.payment.nextAdvantageEndPriceIncludingTax : undefined,
                advantages?.advantageMonths > 0 ? this.quote.payment.nextAdvantageEndPriceExcludingTax : undefined
            );
        }
    }

    renderPriceDetailsCurrent() {
        let advantages = offerAdvantage(this.quote, this.product);
        this.renderPriceDetails(
            `${Resources.t("words.offerCurrentPeriod")} <span>(${Resources.t("words.from")} ${dateFormat(new Date(this.quote.payment.currentPeriodStartDate))} ${Resources.t("words.to")} ${dateFormat(new Date(this.quote.payment.currentPeriodEndDate))})</span>`,
            Resources.t("words.offerCurrentPeriodPaid"),
            this.quote.payment.currentPriceExcludingTax,
            this.quote.payment.currentPriceTotal,
            advantages?.advantageText ?? "",
            undefined,
            undefined,
            this.quote.payment.currentInvoiceUrl
        );
    }

    renderPriceDetails(title: string, description: string, priceVAT: number, price?: number, advantageText?: string, advantageEndVATPrice?: number, advantageEndTotalPrice?: number, invoiceUrl?: string) {
        //language=HTML
        let details = this.append(`
            <div class="${s.priceDetails}">
                <div class="${s.title}">
                    ${title}
                    <div class="${s.description}">
                        ${description}
                    </div>
                </div>
                ${advantageText ? `<div class="${s.priceVAT} ${s.advantage}">${advantageText}</div>` : ``}
                ${price ? `<div class="${s.priceVAT}">${numberPrice(priceVAT, false)}</div>`: ''}
                <div class="${s.price}">
                    ${numberPrice(price ?? priceVAT, Boolean(price))}
                </div>
            </div>
        `);

        if (advantageEndVATPrice) {
            //language=HTML
            details.append(`
                <div style="margin-top: 15px;">
                    <div class="${s.priceVAT} ${s.advantage}">${Resources.t("words.offerAtAdvantageEnd")}</div>
                    <div class="${s.priceVAT}">
                        ${numberPrice(advantageEndVATPrice, false)}
                    </div>
                    <div class="${s.price}">
                        ${numberPrice(advantageEndTotalPrice, true)}
                    </div>
                </div>
            `);
        }

        if (invoiceUrl) {
            //language=HTML
            details.append(`
                <a href="${invoiceUrl}" class="${s.invoice}" download>
                    ${Resources.t("words.offerDownloadInvoice")}
                    <div class="${s.icon}"></div>
                </a>
            `);
        }
    }

    async renderStripe(secrets: IOfferPaymentSecretsModel, actionType: OfferPaymentActionType) {
        if (secrets) {
            let stripeComponent = new OfferStripeComponent(secrets, actionType);
            stripeComponent.onPaid = () => this.startStripePayCheck(this.quote.offer);
            stripeComponent.onError = (error: any) => {
                if (error) {
                    if (errorContainer) {
                        errorContainer.remove();
                    }
                    errorContainer = stripeContainer.prepend(`<div class="${s.error}">${error}</div>`);
                }
                this.priceComponent.remLoading();
            }

            //language=HTML
            let stripeContainer = this.append(`
                <div class="${s.stripe}"></div>`);
            stripeContainer.render(stripeComponent);

            let errorContainer: Component;

            this.getStarted = async () => {
                this.priceComponent.setLoading();
                await stripeComponent.payment();
            }

        } else {
            this.getStarted = async () => {}
            this.priceComponent.clear();
        }
    }
}