import {
    SedestralInterface
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/SedestralInterface";
import {CustomerPopupComponent} from "../CustomerPopupComponent";
import {
    SedestralStorage
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/memory/SedestralStorage";
import {
    SedestralMachine
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/machine/SedestralMachine";
import {Network} from "../../../../../../../network/Network";
import {Resources} from "../../../../../../../resources/Resources";
import {
    SedestralSsr
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/ssr/SedestralSsr";

export class CustomerPopup {

    public static blogRendered = false;

    public static blog() {
        SedestralMachine.setTimeout(() => {
            //if (SedestralStorage.getItem("CblogClosed") == undefined) {
            if (!this.blogRendered) {
                if (!SedestralSsr.hasSsr()) {
                    let popup = new CustomerPopupComponent({
                        title: Resources.t("words.threeFreeArticles"),
                        content: Resources.t("words.createAccountSeoArticle"),
                        buttonText: Resources.t("words.beginFree"),
                        buttonUrl: Network.router.pages.getPanelUrl('signup')
                    });

                    popup.onClose = () => SedestralStorage.setItem("CblogClosed", "open");

                    SedestralInterface.body.render(popup);
                }

                this.blogRendered = true;
            }

            //}
        }, 15000);
    }

    public static blogWriteFinished() {
        let popup = new CustomerPopupComponent({
            title: Resources.t("words.congratulationsFirstArticle"),
            content: Resources.t("words.scheduleDemoSeoBoost"),
            buttonText: Resources.t("words.scheduleDemo"),
            buttonUrl: Network.router.pages.getWebsiteUrl('schedule'),
            large: true,
            cases: [
                {
                    title: Resources.t("words.instructions"),
                    icon: "ai",
                    text: Resources.t("words.optimizeAiInstructions")
                },
                {
                    title: Resources.t("words.context"),
                    icon: "context",
                    text: Resources.t("words.explainBusinessActivity")
                },
                {
                    title: Resources.t("words.styleCloning"),
                    icon: "style",
                    text: Resources.t("words.keepWritingStyle")
                }
            ]
        });

        popup.onClose = () => SedestralStorage.setItem("CblogClosed", "open");

        SedestralInterface.body.render(popup);
    }
}