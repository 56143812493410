@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsiteBlackCases {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .title {
    font-size: 23px;
    color: var(--web-blue);

    @include texts.font("bold");
  }


  .cases {
    display: flex;
    gap: 20px;

    .case {
      padding: 25px;
      display: flex;
      width: 33.33%;
      flex-direction: column;
      gap: 15px;
      position: relative;
      border-radius: 22px;
      border: 1px solid rgba(255, 255, 255, 0.18);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.06) 100%);

      .name {
        font-size: 20px;

        @include texts.font("bold");
      }

      .description {
        font-size: 14px;
      }

      .iconContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: auto;

        .icon {
          height: 130px;
          width: 130px;

          &.page {
            @include icons.ico("illus-black-page");
          }

          &.target {
            height: 120px;
            width: 120px;

            @include icons.ico("illus-black-target");
          }

          &.box {
            height: 140px;
            width: 140px;

            @include icons.ico("illus-black-box");
          }
        }
      }

      .shadow {
        width: 120px;
        height: 58px;
        background: #5082FF;
        filter: blur(85px);
        position: absolute;
        bottom: 0;
        left: calc(50% - 60px);
      }
    }
  }

  @media screen and (max-width: 930px) {
    .cases {
      flex-wrap: wrap;

      .case {
        width: 100%;
      }
    }
  }
}