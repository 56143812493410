import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-columns-steps.scss";
import {Network} from "../../../../../../../network/Network";
import {Resources} from "../../../../../../../resources/Resources";

export class WebsiteColumnsStepsComponent extends Component {

    public entries: { name: string, description: string, icon: string }[];

    public contentContainer: Component;

    constructor(p: { title: string, grid: { name: string, description: string, icon } [] }) {
        super();

        this.entries = p.grid;

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteColumnsSteps}">
                <h3 class="${s.title}">
                    ${p.title}
                </h3>
                <div class="${s.columns}">
                    
                </div>
            </div>
        `;
    }

    commit() {
        this.contentContainer = this.el(s.columns);

        let lineUrl = `${Network.vendor}images/separator-wave.webp`;
        var number: number = 1;

        this.entries.forEach(value => {
            //language=HTML
            let entry = this.contentContainer.append(`
                <div class="${s.column}">
                    <div class="${s.details}">
                        <div class="${s.icon} ${value.icon}"></div>
                        <h4 class="${s.name}">
                            ${value.name}
                        </h4>
                        <p class="${s.description}">
                            ${value.description}
                        </p>
                    </div>
                    <img src="${lineUrl}" class="${s.banner}" alt="${Resources.t("words.presentationLine")}"
                         onmousedown="return false" onmousemove="return false"/>
                    <div class="${s.number}">
                        <p class="${s.caracter}">${number}</p>
                    </div>
                </div>
            `);
            number++;
        });

        super.commit();
    }
}